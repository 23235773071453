import React, { useMemo } from "react";
import { Grid, Button, Typography, Stack, Tooltip, Box } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import useTranslation from "../../../localization/useTranslation";
import { shallowEqual, useSelector } from "react-redux";
import { userGrantSelector } from "../../../redux/selectors";

const buttonStyles = {
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: 700,
  minWidth: "186px",
};

export const TopControls = ({
  openCreateCustomFieldModal,
  setOpenBitLyFieldModal,
  isLoading,
  isExistBitlyFields,
}) => {
  const { labelStrings } = useTranslation();
  const { campaigns_bitly_fields, campaigns_allow_bitly_field } = useSelector(
    userGrantSelector,
    shallowEqual,
  );

  const isDisabledCreateBitlyFieldBtn = useMemo(
    () => isExistBitlyFields?.length > campaigns_bitly_fields,
    [isExistBitlyFields, campaigns_bitly_fields]
  );

  return (
    <Grid
      container
      sx={{
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Grid item md={9.5}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "28px",
          }}
        >
          {labelStrings.customFields}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "22px",
            color: "#637381",
            whiteSpace: "pre-line",
          }}
        >
          {labelStrings.customFieldPageTitle}
        </Typography>
      </Grid>
      <Stack spacing={1}>
        <Button
          disabled={isLoading}
          startIcon={<AddRoundedIcon />}
          variant="outlined"
          sx={buttonStyles}
          onClick={() => openCreateCustomFieldModal(true)}
        >
          {labelStrings.newCustomFields}
        </Button>

        <Tooltip
          title={
            isDisabledCreateBitlyFieldBtn
              ? labelStrings.bitlyDisableBtnText
              : !campaigns_allow_bitly_field
              ? labelStrings.createBitlyFieldTip
              : campaigns_bitly_fields < 1
              ? labelStrings.createBitlyGrantText
              : ""
          }
        >
          <Box>
            <Button
              disabled={
                isLoading ||
                isDisabledCreateBitlyFieldBtn ||
                !campaigns_allow_bitly_field ||
                campaigns_bitly_fields < 1
              }
              startIcon={<AddRoundedIcon />}
              variant="outlined"
              sx={buttonStyles}
              onClick={() => setOpenBitLyFieldModal(true)}
            >
              {labelStrings.createBitLyField}
            </Button>
          </Box>
        </Tooltip>
      </Stack>
    </Grid>
  );
};
