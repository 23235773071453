import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

const WithScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const pageContainer = document.querySelector(`#main-page-container`);

    if (pageContainer) {  
      pageContainer.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
      
    }
  }, [pathname]);

  return children || null; 
};

WithScrollToTop.propTypes = {
  children: PropTypes.node
};

export default WithScrollToTop;
