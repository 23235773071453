import React, { useEffect, useRef, useState } from "react";
import { Stack, TextField, Button, Box, Typography } from "@mui/material";
import useTranslation from "../../../../localization/useTranslation";
import { activeBtnStyle } from "./AddCreators";

export const AddLabel = (props) => {
  const { onConfirmLastStep } = props || {};
  const { labelStrings } = useTranslation();

  const inputRef = useRef(null);

  const [labelValue, setLabelValue] = useState("");

  const onHandleChangeLabel = ({ target }) => {
    setLabelValue(target.value);
  };

  useEffect(() => {
    setLabelValue("");
    inputRef?.current?.focus();
  }, []);

  const btnStyle = labelValue?.length ? {...activeBtnStyle} : {}

  return (
    <Stack sx={{ width: "100%" }} alignItems='center'>
      <TextField
        maxWidth
        size='small'
        inputRef={inputRef}
        autoFocus
        sx={{
          maxWidth: "100%",
          "& .MuiInputLabel-root": {
            color: "#C79C9C",
            "&:focused, &:active": {
              color: "#C79C9C",
            },
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#C79C9C'
          },
          "& .MuiOutlinedInput-root": {
            outline: "unset",
            "& fieldset": {
              borderColor: '#BC9797',
            }
          }
        }}
        value={labelValue}
        onChange={onHandleChangeLabel}
        placeholder={labelStrings.typeLabelName}
      />
      <Box
        sx={{
          width: "100%",
          mb: "16px !important",
          mt: '8px'
        }}
      >
        <Stack direction='column' alignItems='center' justifyContent='center'>
          <Typography
            textAlign='center'
            sx={{
              maxWidth: '319px',
              whiteSpace: "pre-line",
              fontSize: "13px",
              textAlign: "center",
              fontFamily: 'Inter',
              color: '#C79C9C',
              lineHeight: '14.3px'
            }}
          >
            {labelStrings?.buildLookalike?.willTake5Minutes}
          </Typography>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <Button
              sx={{
                mt: 3,
                ...btnStyle
              }}
              disabled={!labelValue?.length}
              variant='contained'
              onClick={() => onConfirmLastStep({ label: labelValue })}
            >
              {labelStrings?.confirm}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
