import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Popup from 'reactjs-popup'
import useTranslation from '../../../../localization/useTranslation'
import AudienceReportsTokensStat from '../../../../components/tokens/stats/AudienceReportsTokensStat'
import CampaignsSeatsTokensStat from '../../../../components/tokens/stats/CampaignsSeatsTokensStat'
import { NavbarProfileIcon } from '../navbarIcons'
import { Button, Box, Divider } from '@mui/material'

const Wrapper = styled.div`
  position: relative;

  & * {
    font-family: ${props => props.theme.fontFamilyInter};
  }
  .profile-popup-nav-content {
    padding: 0 !important;
    border-radius: 16px !important;
    width: 237px !important;
    z-index: 1000 !important;
    left: -205px !important;
    border: none !important;
    margin-top: 10px;
  }

  .profile-popup-nav-content,
  .email-popup-content {
    z-index: 400;
    background: ${props => props.theme.color.white} !important;
    box-shadow: 0px 6px 20px #8436302b !important;

    & .profile-popup-nav-arrow,
    & .email-popup-arrow {
      z-index: 300;
      left: 184px !important;
      background: ${props =>
        props.showTokensStats ? props.theme.color.kabul : props.theme.color.white} !important;
      box-shadow: 0px 0px 6px transparent !important;
    }
  }
`

const ProfileNavMenu = ({
  onLogoutClick,
  goToSettings,
  showTokensStats,
  showCampaignsTrackStats,
  openMenuManually,
  isTrial,
}) => {
  const { labelStrings } = useTranslation()
  const location = useLocation()

  const [isHovered, setIsHovered] = useState(false)

  const isActive = location?.pathname?.includes('settings')

  return (
    <Wrapper showTokensStats={showTokensStats && showCampaignsTrackStats}>
      <Popup
        trigger={
          <div>
            <Button
              sx={{
                minWidth: '40px',
                '&:hover': { backgroundColor: 'transparent' },
              }}
              onClick={goToSettings}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <NavbarProfileIcon isActive={isActive || isHovered} />
            </Button>
          </div>
        }
        className={'profile-popup-nav'}
        on="hover"
        closeOnDocumentClick
        arrow={false}
        position="bottom right"
        open={openMenuManually}
      >
        {close => (
          <Box sx={{ border: '1px solid #E3C5C5', borderRadius: '16px' }}>
            {showTokensStats && (
              <Box
                sx={{
                  overflow: 'hidden',
                  position: 'relative',
                  top: '-1px',
                  borderTopLeftRadius: '16px',
                  borderTopRightRadius: '16px',
                }}
              >
                <AudienceReportsTokensStat isTrial={isTrial} />
                {showCampaignsTrackStats && (
                  <>
                    <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />
                    <CampaignsSeatsTokensStat />
                  </>
                )}
              </Box>
            )}
            <Box>
              <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />
              <Box sx={{ p: '5px', px: 1 }}>
                <Button
                  disableRipple
                  variant="text"
                  onClick={goToSettings}
                  sx={{
                    color: '#442424',
                    fontWeight: 700,
                    fontFamily: 'Inter',
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: 'unset',
                      textDecoration: 'underline #6B4545',
                    },
                  }}
                >
                  {labelStrings.editYourAccount}
                </Button>
              </Box>
              <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />
              <Box sx={{ p: '5px', px: 1 }}>
                <Button
                  disableRipple
                  variant="text"
                  onClick={onLogoutClick}
                  sx={{
                    color: '#442424',
                    fontWeight: 700,
                    fontFamily: 'Inter',
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: 'unset',
                      textDecoration: 'underline #6B4545',
                    },
                  }}
                >
                  {labelStrings.logout}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Popup>
    </Wrapper>
  )
}

export default ProfileNavMenu
