import React from "react";

export const SuggestionsIcon = ({ width = 30, height = 24, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={30}
      height={24}
      fill='none'
      viewBox='0 0 30 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M3.725 1.055 5.88 2.492c.494.36.629 1.033.314 1.528-.36.494-1.033.628-1.527.27L2.512 2.851a1.031 1.031 0 0 1-.27-1.483c.315-.494.988-.629 1.483-.314Zm23.718 1.797-2.156 1.437a1.031 1.031 0 0 1-1.482-.27c-.36-.494-.225-1.167.27-1.527l2.155-1.437c.495-.315 1.168-.18 1.528.314.314.494.18 1.168-.315 1.483Zm-25.74 5.21h2.875c.584 0 1.078.495 1.078 1.079 0 .629-.494 1.078-1.078 1.078H1.703c-.629 0-1.078-.45-1.078-1.078 0-.584.45-1.079 1.078-1.079Zm23.719 0h2.875c.584 0 1.078.495 1.078 1.079 0 .629-.494 1.078-1.078 1.078h-2.875c-.629 0-1.078-.45-1.078-1.078 0-.584.449-1.079 1.078-1.079ZM5.88 15.79l-2.156 1.438a1.031 1.031 0 0 1-1.483-.27c-.36-.494-.224-1.168.27-1.527l2.156-1.438c.494-.314 1.168-.18 1.527.315.315.494.18 1.168-.314 1.482Zm19.406-1.752 2.156 1.438c.494.314.63.988.315 1.482-.36.494-1.033.629-1.527.314l-2.157-1.437c-.494-.36-.629-1.033-.27-1.527a1.031 1.031 0 0 1 1.483-.27Zm-4.492.225c-.899 1.213-1.797 2.47-2.201 3.863h-7.188c-.449-1.393-1.347-2.65-2.246-3.863l-.674-.944c-.898-1.302-1.392-2.83-1.392-4.537A7.89 7.89 0 0 1 15 .875c4.357 0 7.906 3.549 7.906 7.906 0 1.707-.539 3.235-1.437 4.537l-.674.944Zm-2.201 6.02A3.576 3.576 0 0 1 15 23.874c-2.021 0-3.594-1.572-3.594-3.594v-.718h7.188v.718ZM15 5.186c.36 0 .719-.314.719-.718 0-.36-.36-.719-.719-.719-2.785 0-5.031 2.291-5.031 5.031a.71.71 0 0 0 .719.719c.359 0 .718-.314.718-.719A3.576 3.576 0 0 1 15 5.187Z'
      />
    </svg>
  );
};

export const RecentLookalikeIcon = ({ width = 24, height = 24, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M23.5 10.938V21a2.866 2.866 0 0 1-2.875 2.875H3.375A2.838 2.838 0 0 1 .5 21V10.937a2.866 2.866 0 0 1 2.875-2.874h17.25a2.895 2.895 0 0 1 2.875 2.874Zm-3.234-6.47c.584 0 1.078.495 1.078 1.079 0 .629-.494 1.078-1.078 1.078H3.734c-.629 0-1.078-.45-1.078-1.078 0-.584.45-1.078 1.078-1.078h16.532ZM18.109.876c.584 0 1.078.494 1.078 1.078 0 .629-.494 1.078-1.078 1.078H5.891c-.63 0-1.079-.449-1.079-1.078 0-.584.45-1.078 1.079-1.078h12.218Z'
      />
    </svg>
  );
};

export const CreateLookalikeListByAI = ({
  width = 15,
  height = 16,
  disabled = false,
  ...props
}) => {
  return disabled ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 15 16'
      fill='none'
      {...props}
    >
      <path
        fill='currentColor'
        d='M5.156 1.578V2.75h1.64V1.578c0-.38.294-.703.704-.703.38 0 .703.322.703.703V2.75h1.64V1.578c0-.38.294-.703.704-.703.38 0 .703.322.703.703V2.75c1.025 0 1.875.85 1.875 1.875h1.172c.38 0 .703.322.703.703 0 .41-.322.703-.703.703h-1.172v1.64h1.172c.38 0 .703.323.703.704 0 .41-.322.703-.703.703h-1.172v1.64h1.172c.38 0 .703.323.703.704 0 .41-.322.703-.703.703h-1.172A1.87 1.87 0 0 1 11.25 14v1.172c0 .41-.322.703-.703.703a.677.677 0 0 1-.703-.703V14h-1.64v1.172c0 .41-.323.703-.704.703a.677.677 0 0 1-.703-.703V14h-1.64v1.172c0 .41-.323.703-.704.703a.677.677 0 0 1-.703-.703V14a1.851 1.851 0 0 1-1.875-1.875H.703A.677.677 0 0 1 0 11.422c0-.381.293-.703.703-.703h1.172v-1.64H.703A.677.677 0 0 1 0 8.374c0-.38.293-.703.703-.703h1.172v-1.64H.703A.677.677 0 0 1 0 5.327c0-.38.293-.703.703-.703h1.172A1.87 1.87 0 0 1 3.75 2.75V1.578c0-.38.293-.703.703-.703.381 0 .703.322.703.703Zm.938 4.453a.45.45 0 0 0-.44.293l-1.23 2.813-.41.937c-.118.235 0 .498.234.616a.45.45 0 0 0 .615-.235l.293-.674h1.846l.293.674c.117.235.38.352.615.235.235-.088.352-.381.235-.616l-.41-.937-1.231-2.813a.44.44 0 0 0-.41-.293Zm.498 2.813H5.566l.528-1.172.498 1.172ZM9.844 6.03a.482.482 0 0 0-.469.469v3.75c0 .264.205.469.469.469a.482.482 0 0 0 .469-.469V6.5a.504.504 0 0 0-.47-.469Z'
      />
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 15 16'
      fill='none'
      {...props}
    >
      <path
        fill='url(#a)'
        d='M5.156 1.578V2.75h1.64V1.578c0-.38.294-.703.704-.703.38 0 .703.322.703.703V2.75h1.64V1.578c0-.38.294-.703.704-.703.38 0 .703.322.703.703V2.75c1.025 0 1.875.85 1.875 1.875h1.172c.38 0 .703.322.703.703 0 .41-.322.703-.703.703h-1.172v1.64h1.172c.38 0 .703.323.703.704 0 .41-.322.703-.703.703h-1.172v1.64h1.172c.38 0 .703.323.703.704 0 .41-.322.703-.703.703h-1.172A1.87 1.87 0 0 1 11.25 14v1.172c0 .41-.322.703-.703.703a.677.677 0 0 1-.703-.703V14h-1.64v1.172c0 .41-.323.703-.704.703a.677.677 0 0 1-.703-.703V14h-1.64v1.172c0 .41-.323.703-.704.703a.677.677 0 0 1-.703-.703V14a1.851 1.851 0 0 1-1.875-1.875H.703A.677.677 0 0 1 0 11.422c0-.381.293-.703.703-.703h1.172v-1.64H.703A.677.677 0 0 1 0 8.374c0-.38.293-.703.703-.703h1.172v-1.64H.703A.677.677 0 0 1 0 5.327c0-.38.293-.703.703-.703h1.172A1.87 1.87 0 0 1 3.75 2.75V1.578c0-.38.293-.703.703-.703.381 0 .703.322.703.703Zm.938 4.453a.45.45 0 0 0-.44.293l-1.23 2.813-.41.937c-.118.235 0 .498.234.616a.45.45 0 0 0 .615-.235l.293-.674h1.846l.293.674c.117.235.38.352.615.235.235-.088.352-.381.235-.616l-.41-.937-1.231-2.813a.44.44 0 0 0-.41-.293Zm.498 2.813H5.566l.528-1.172.498 1.172ZM9.844 6.03a.482.482 0 0 0-.469.469v3.75c0 .264.205.469.469.469a.482.482 0 0 0 .469-.469V6.5a.504.504 0 0 0-.47-.469Z'
      />
      <defs>
        <linearGradient
          id='a'
          x1={-2}
          x2={17.545}
          y1={11.547}
          y2={10.256}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFB904' />
          <stop offset={0.294} stopColor='#FF6B00' />
          <stop offset={0.566} stopColor='#FF403B' />
          <stop offset={1} stopColor='#FF3B99' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const LookalikeModalIcon = ({ width = 61, height = 61, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox='0 0 61 61'
      {...props}
    >
      <path
        fill="url(#a)"
        d="M21.125 3.313V8h6.563V3.312C27.688 1.79 28.858.5 30.5.5c1.523 0 2.813 1.29 2.813 2.813V8h6.562V3.312C39.875 1.79 41.047.5 42.688.5 44.21.5 45.5 1.79 45.5 3.313V8c4.102 0 7.5 3.398 7.5 7.5h4.688c1.523 0 2.812 1.29 2.812 2.813 0 1.64-1.29 2.812-2.813 2.812H53v6.563h4.688c1.523 0 2.812 1.289 2.812 2.812 0 1.64-1.29 2.813-2.813 2.813H53v6.562h4.688c1.523 0 2.812 1.29 2.812 2.813 0 1.64-1.29 2.812-2.813 2.812H53c0 4.219-3.398 7.5-7.5 7.5v4.688c0 1.64-1.29 2.812-2.813 2.812-1.64 0-2.812-1.172-2.812-2.813V53h-6.563v4.688c0 1.64-1.289 2.812-2.812 2.812-1.64 0-2.813-1.172-2.813-2.813V53h-6.562v4.688c0 1.64-1.29 2.812-2.813 2.812-1.64 0-2.812-1.172-2.812-2.813V53C11.281 53 8 49.719 8 45.5H3.312C1.673 45.5.5 44.328.5 42.687c0-1.523 1.172-2.812 2.813-2.812H8v-6.563H3.312C1.673 33.313.5 32.142.5 30.5c0-1.523 1.172-2.813 2.813-2.813H8v-6.562H3.312C1.673 21.125.5 19.953.5 18.312.5 16.79 1.672 15.5 3.313 15.5H8C8 11.398 11.281 8 15.5 8V3.312C15.5 1.79 16.672.5 18.313.5c1.523 0 2.812 1.29 2.812 2.813Zm3.75 17.812c-.82 0-1.523.469-1.758 1.172l-4.922 11.25-1.64 3.75c-.47.937 0 1.992.937 2.46.938.47 2.11 0 2.461-.937l1.172-2.695h7.383l1.172 2.695c.468.938 1.523 1.407 2.46.938.938-.352 1.407-1.524.938-2.461l-1.64-3.75-4.922-11.25c-.235-.703-.938-1.172-1.641-1.172Zm1.992 11.25h-4.101l2.109-4.688 1.992 4.688Zm13.008-11.25C38.82 21.125 38 22.063 38 23v15c0 1.055.82 1.875 1.875 1.875.938 0 1.875-.82 1.875-1.875V23c0-.938-.938-1.875-1.875-1.875Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={0}
          x2={62.741}
          y1={40.109}
          y2={35.891}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB904" />
          <stop offset={0.294} stopColor="#FF6B00" />
          <stop offset={0.566} stopColor="#FF403B" />
          <stop offset={1} stopColor="#FF3B99" />
        </linearGradient>
      </defs>
    </svg>
  )
}