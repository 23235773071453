import React from "react";
import { Box, Button } from "@mui/material";
import useTranslation from "../../../../localization/useTranslation";
import WithTip from "../../../../components/common/popups/WithTip";
import { CreateLookalikeListByAI } from "../icons";

export const BuildYourListAI = (props) => {
  const { build_lookalikes, onOpenModalLookalikeAi, userIndex } = props || {};
  const { labelStrings } = useTranslation();

  const ButtonComponent = (
    <Button
      size='small'
      disabled={!build_lookalikes}
      variant='outlined'
      startIcon={<CreateLookalikeListByAI disabled={!build_lookalikes} />}
      onClick={onOpenModalLookalikeAi}
      sx={{
        width: '250px',
        height: '34px',
        borderRadius: "23px",
        border: "1.5px solid #FF5500",
        fontSize: "12px",
        fontFamily: "Inter",
        fontWeight: 700,
        ...(build_lookalikes
          ? {
              background:
                "linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)",
              backgroundClip: `text`,
              textFillColor: "transparent",
            }
          : { color: "#A8AFBA" }),

        "&:hover": {
          opacity: "0.7",
        },
      }}
    >
      {labelStrings?.buildLookalike?.btnText}
    </Button>
  );

  return (
    <Box sx={{ px: 2, py: 1 }}>
      {build_lookalikes ? (
        ButtonComponent
      ) : (
        <WithTip
          tipText={labelStrings.disabledFunctionInYourPlanTip}
          userindex={userIndex}
          position='right top'
        >
          <Box sx={{ width: "fit-content" }}>{ButtonComponent}</Box>
        </WithTip>
      )}
    </Box>
  );
};
