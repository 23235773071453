import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import html2pdf from 'html2pdf.js'
import {
  PDF_PAGE_FORMATS,
  PDF_PAGE_ORIENTATIONS,
  PDF_PAGE_UNITS,
} from '../../../../constants/exportPdfSettings'
import DashboardPdfDocument from './DashboardPdfDocument'
import { currentOpenedCampaignSelector } from '../../../../redux/selectors'
import { Portal } from '@mui/material'

export const DashboardPdfRender = ({
  dashboardName,
  setIsExportPdfProgress,
  pdfExportState,
  setPdfExportState,
  defaultPdfExportState,
  changePdfExportState,
  currentDashboard,
}) => {
  const currentCampaign = useSelector(currentOpenedCampaignSelector)
  const pdfContentRef = useRef(null)

  const { isExportInit, isExportInProgress, dateOfReport, isMarkupReady } = pdfExportState

  useEffect(() => {
    if (isExportInit && isExportInProgress) {
      setMarkupReady()
    }

    if (isExportInit && isExportInProgress && isMarkupReady) {
      setTimeout(() => triggerExportPdf(), 5000)
    }
  }, [isMarkupReady, isExportInProgress, isExportInit])

  const setMarkupReady = () => changePdfExportState({ isMarkupReady: true })

  const triggerExportPdf = async () => {
    // with delay to make sure all markup was done (due to transitions)
    const pdfContentElement = pdfContentRef && pdfContentRef.current

    if (pdfContentElement) {
      await createAndSavePdf(pdfContentElement)
    } else {
      // setError(ERROR_MSG.failExportPdf)
    }

    setPdfExportState(defaultPdfExportState)
  }

  const createPdfFileName = () => {
    return `${dashboardName}_${dateOfReport}.pdf`
  }

  const createAndSavePdf = async targetElement => {
    const pdfOptions = {
      margin: 0,
      filename: createPdfFileName(),
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2,
        imageTimeout: 8000,
        useCORS: true,
        logging: process.env.REACT_APP_NODE_ENV !== 'prod',
      },
      jsPDF: {
        unit: PDF_PAGE_UNITS.mm,
        format: PDF_PAGE_FORMATS.a4,
        orientation: PDF_PAGE_ORIENTATIONS.portrait,
        putOnlyUsedFonts: true,
      },
      pageBreak: { mode: 'css', after: '.html2pdf__page-break' },
    }

    try {
      const pdf = await html2pdf()
        .set(pdfOptions)
        .from(targetElement)
        .toPdf()
        .get('pdf')

      const totalPages = pdf.internal.getNumberOfPages()

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i)
        pdf.setFontSize(13)
        pdf.setTextColor('#637381')
        pdf.text(
          pdf.internal.pageSize.getWidth() / 2.15,
          pdf.internal.pageSize.getHeight() - 4,
          'PAGE ' + i + ' OUT OF ' + totalPages
        )
      }

      pdf.save(createPdfFileName())
      setIsExportPdfProgress(false)
    } catch (err) {
      console.error(err)
      // clean up pdfs overlays
      const overlay = document.querySelector('.html2pdf__overlay')
      const container = document.querySelector('.html2canvas-container')
      overlay && document.body.removeChild(overlay)
      container && document.body.removeChild(container)
      // setError(ERROR_MSG.failExportPdf)
    }
  }

  return (
    <>
      {isExportInit && (
        <Portal>
          <DashboardPdfDocument
            ref={pdfContentRef}
            currentOpenedDashboard={currentDashboard}
            currentCampaign={currentCampaign}
          />
        </Portal>
      )}
    </>
  )
}
