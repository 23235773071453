import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Table, TableBody, TableContainer } from '@mui/material'
import { FieldTableRow } from './FieldTableRow'
import Scrollbar from '../../../../../../new-ui/components/scrollbar/Scrollbar'
import useTranslation from '../../../../../../localization/useTranslation'
import { TableRowsLoader } from '../../../../../../new-ui/components/table/TableRowSkeleton'
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from '../../../../../../new-ui/components/table'
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage'
import { applyFilter, getCSVData } from '../utils'

export const CreatorsList = props => {
  const { data, isLoading, isForPdf, isBrandPerformancePdf } = props

  const { labelStrings } = useTranslation()

  const tableRef = useRef(null)

  const [currencyFromStorage] = useLocalStorage('currency')
  const currencySymbol = currencyFromStorage === "usd" ? "$" : currencyFromStorage === "eur" ? "€" : '£'


  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable(
    {
      defaultRowsPerPage: isForPdf ? (isBrandPerformancePdf ? 43 : 13) : 15,
    }
  )

  const dataFiltered = applyFilter({
    inputData: data,
    comparator: getComparator(order, orderBy),
  })

  const isShowTypeField = !!data[0]?.creatorType

  const TABLE_HEAD = [
    ...(!isBrandPerformancePdf ? [{ id: '' }] : []),
    { id: 'username', label: labelStrings.username, align: 'left', verticalAlign: 'middle' },
    { id: 'displayName', label: labelStrings.name, align: 'left', verticalAlign: 'middle' },
    {
      id: 'followerCount',
      label: labelStrings.followers,
      align: 'left',
      maxWidth: '100px',
      verticalAlign: 'middle',
    },
    {
      id: 'country',
      label: labelStrings.country,
      align: 'left',
      verticalAlign: 'middle',
      maxWidth: isBrandPerformancePdf ? '75px' : '100px',
    },
    {
      id: 'contentCount',
      label: isBrandPerformancePdf
        ? `# Content PCS.`
        : `# ${labelStrings.campaignsMetricsTable.cpm.publishedContent}`,
      align: 'center',
      sx: {
        mb: isForPdf ? '10px' : 'inherit',
      },
      maxWidth: isBrandPerformancePdf ? '75px' : '100px',
      verticalAlign: 'middle',
    },
    {
      id: 'reach',
      label: labelStrings.reachTotal,
      align: 'left',
      sx: {
        mb: isForPdf ? '10px' : 'inherit',
      },
      maxWidth: isBrandPerformancePdf ? '75px' : '100px',
      verticalAlign: 'middle',
    },
    {
      id: 'mediaValue',
      label: isBrandPerformancePdf
        ? `EMV (${currencySymbol})`
        : labelStrings.emvInCurrency(currencySymbol),
      align: 'left',
      sx: {
        mb: isForPdf ? '10px' : 'inherit',
      },
      verticalAlign: 'middle',
    },
    ...(isShowTypeField
      ? [{ id: 'creatorType', label: labelStrings.type, align: 'left', verticalAlign: 'middle' }]
      : []),
    ...(!isForPdf || isBrandPerformancePdf
      ? [
          {
            id: 'durationInDays',
            label: labelStrings.duration,
            align: 'left',
            verticalAlign: 'middle',
          },
        ]
      : []),
    // Removed since not accurate
    // ...(!isForPdf
    //   ? [{ id: 'isActive', label: labelStrings.active, align: 'left', verticalAlign: 'middle' }]
    //   : []),
    ...(!isForPdf ? [{ id: '' }] : []),
  ]

  const denseHeight = 52
  const isNotFound = !dataFiltered?.length && !isLoading

  const csvReport = {
    headers: TABLE_HEAD?.filter(obj => obj.id !== '')?.map(obj => ({
      key: obj.id,
      label: obj.label,
    })),
    data: getCSVData(dataFiltered, TABLE_HEAD),
    filename: 'creators-table.csv',
  }

  return (
    <Grid container ref={tableRef}>
      <Grid item xs={12} md={12}>
        <Card>
          <TableContainer
            sx={{
              position: 'relative',
              overflow: 'unset',
              minHeight: 'auto',
            }}
          >
            <Scrollbar>
              <Table size={'small'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data?.length}
                  onSort={onSort}
                  isForPdf={isForPdf}
                  isBrandPerformancePdf={isBrandPerformancePdf}
                />

                <TableBody>
                  {isLoading ? (
                    <TableRowsLoader rowsNum={10} thNum={10} />
                  ) : (
                    !isLoading &&
                    dataFiltered
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(row => (
                        <FieldTableRow
                          key={row.profileId}
                          row={row}
                          isShowTypeField={isShowTypeField}
                          isForPdf={isForPdf}
                          isBrandPerformancePdf={isBrandPerformancePdf}
                        />
                      ))
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, data?.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          {!isForPdf && (
            <TablePaginationCustom
              count={data?.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              sx={{ '& p': { m: 0 } }}
              csvReport={csvReport}
            />
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

CreatorsList.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  isForPdf: PropTypes.bool,
  isBrandPerformancePdf: PropTypes.bool
}
