import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  // openedCollectionSelector,
  userCollectionsAllDataSelector,
} from "../../../../redux/selectors";
import useTranslation from "../../../../localization/useTranslation";
import { getCollectionFromExplorePage } from "../../../../redux/ducks/collectionsDucks";
// import { USER_INDEXES } from "../../../../constants/appSettings";

export const ImportFromCollection = ({
  setCreators,
  setIsEnabledImportFromCollection,
}) => {
  const { labelStrings } = useTranslation();

  const dispatch = useDispatch();

  const { collectionsList, isLoading: isLoadingCollection } = useSelector(
    userCollectionsAllDataSelector,
    shallowEqual,
  );
  const filteredCollections = collectionsList?.filter(
    (collection) => collection.count !== 0,
  );

  // const { users: usersFromSelectedCollection } = useSelector(
  //   openedCollectionSelector,
  //   shallowEqual,
  // );

  const [selectedCollection, setSelectedCollection] = useState("");
  const [isNotExport, setIsNotExport] = useState(false);

  // const getCreatorsIdsFromCollection =
  //   usersFromSelectedCollection?.userdata?.filter(
  //     (creator) => creator._index === USER_INDEXES.instagram,
  //   );

  const callbackAddCreatorsFromCollection = (data) => {
    if (data?.length) {
      setIsEnabledImportFromCollection(false);
      setCreators(data?.slice(0, 20));
    } else {
      setIsNotExport(true);
    }
  };

  const handleChangeCollection = (event) => {
    if (!isNotExport) {
      setIsNotExport(false);
    }

    const value = event.target.value;
    setSelectedCollection(value);
    dispatch(
      // @ts-ignore
      getCollectionFromExplorePage({
        collectionId: value,
        callback: callbackAddCreatorsFromCollection,
      }),
    );
  };

  return (
    <Stack alignItems='center' sx={{ width: "100%", pb: 2, color: "#C79C9C" }}>
      {isLoadingCollection && selectedCollection ? (
        <Stack>
          <Skeleton
            variant='rounded'
            animation='wave'
            width={350}
            height={40}
          />
        </Stack>
      ) : (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{ maxWidth: "350px", width: "100%",  }}
        >
          <FormControl fullWidth>
            <InputLabel
              sx={{
                color: "#C79C9C",
                "&.Mui-focused": { color: "#C79C9C" },
              }}
            >
              {labelStrings.selectYourCollection}
            </InputLabel>
            <Select
              label={labelStrings.selectYourCollection}
              onChange={handleChangeCollection}
              value={selectedCollection}
              // size="small"
              autoWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    width: "100%",
                    maxWidth: "360px",
                    maxHeight: "318px",
                    marginTop: "4px",
                    color: '#442424'
                  },
                },
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "#784E4E"
                },
                "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#BC9797"
                },
                }
              }}
            >
              {filteredCollections?.map((collection) => {
                return (
                  <MenuItem
                    key={collection.collectionId}
                    value={collection.collectionId}
                  >
                    {collection.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
      )}
      {isNotExport && (
        <Typography
          textAlign='center'
          sx={{ fontWeight: 600, pt: 2, fontSize: "14px", color: "#637381" }}
        >
          {labelStrings.noMatchingCreators}
        </Typography>
      )}
    </Stack>
  );
};
