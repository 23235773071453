import React from "react";
import {
  Stack,
  Typography,
  List,
  ListItemButton,
  IconButton,
  Divider
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { LookalikeHistoryIcon } from "./icons";
import useTranslation from "../../../../localization/useTranslation";
import { fShortenNumber } from "../../../utils/formatNumber";

export const RecentSearch = (props) => {
  const { data, onApplySearch } = props || {};
  const { labelStrings } = useTranslation();

  return (
    <List disablePadding sx={{ width: "100%" }}>
      {data?.map((el) => {
        return (
          <ListItemButton
            size='small'
            key={el.date}
            onClick={() => onApplySearch(el)}
            sx={{
              borderRadius: "10px",
              px: 1,
              "&:hover": {
                backgroundColor: "#FFF5F5",
                "& hr": {
                  border: "1px solid transparent !important",
                },
              },
              "&:last-child": { "& hr": { border: "unset !important" } },
            }}
          >
            <Stack sx={{ width: "100%" }}>
              <Stack direction='row' alignItems='center' sx={{ width: "100%" }}>
                <IconButton>
                  <LookalikeHistoryIcon height={20} width={18}/>
                </IconButton>
                <Stack sx={{ width: "100%" }}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Typography
                      noWrap
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        color: "#C79C9C",
                        fontWeight: 400,
                      }}
                    >
                      {`${labelStrings.explorePage.searchedOn} ${format(
                        parseISO(el?.date),
                        "MMMM dd",
                      )}`}
                    </Typography>
                    <Typography
                      noWrap
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        color: "#C79C9C",
                        fontWeight: 400,
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      {`${el.platform}, ${fShortenNumber(el.resultsCount)} Results`}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      noWrap
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        color: "#442424",
                        fontWeight: 700,
                        maxWidth: '700px'
                      }}
                    >
                      {el.formattedString}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Divider sx={{ width: '100%', border: '1px solid #FFF1F1', mt: '4px' }} />
            </Stack>
          </ListItemButton>
        );
      })}
    </List>
  );
};
