import React, { useEffect, useState } from "react"
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
} from "@mui/material"
import PickerDateRange from "../../components/pickers/PickerDateRange"
import { BtnWrapper } from "./styles.js"
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded"
import ShareRoundedIcon from "@mui/icons-material/ShareRounded"
import { MultipleSelect } from "../../components/common/multiple-select/index"
import { SharedPopover } from "./SharedPopover"
import { compareAsc, format } from "date-fns"
import { dateFormatMaskYYYY_MM_dd } from "./utils"

export const TopControls = ({
  openEditModal,
  setOpenEditModal,
  dashboardsData,
  creatorsData,
  editDashboard,
  dashboardIdFromQueryURL,
  campaignId,
  currentDashboard,
  //
  dashboardSelectValue,
  setDashboardSelectValue,
  creatorsSelectValue,
  setCreatorsSelectValue,
  datesValue,
  setDatesValue,

  onChangeDashboard,
  currentCampaign,
  onChangeSelectedDates,
}) => {
  const selectedCreators = currentDashboard?.selectedCreators
  const selectedDates = currentDashboard?.selectedDates

  const [openCreatorsSelect, setOpenCreatorsSelect] = useState(false)

  const [openPopover, setOpenPopover] = useState(null)
  const [accessSelect, setAccessSelect] = useState("")

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  const platforms = new Set(creatorsData?.map((el) => el.platform))

  const creatorsOptionsData = creatorsData
    ?.filter((x) => !x.isDeleted && !x.isOnboarding)
    .map((el) => {
      let platformAbbr = ""
      if (platforms.size > 1) {
        platformAbbr = el.platform.includes("insta")
          ? " (IG)"
          : el.platform.includes("tiktok")
            ? " (TT)"
            : " (YT)"
      }
      return {
        label: `${el.username}${platformAbbr}`,
        value: el.creatorId,
      }
    })

  const getAllOptionsValues = creatorsOptionsData?.map((option) => option.value)

  useEffect(() => {
    if (dashboardIdFromQueryURL) {
      setDashboardSelectValue(dashboardIdFromQueryURL)
    }
  }, [dashboardIdFromQueryURL, campaignId])

  useEffect(() => {
    if (getAllOptionsValues?.length && selectedCreators?.length) {
      const value = getAllOptionsValues?.filter((x) =>  selectedCreators?.includes(x))
      setCreatorsSelectValue(value)
    }

    if (selectedDates?.from || selectedDates?.to) {
      const fromDate = selectedDates?.from
        ? format(
            new Date(selectedDates.from?.split("T")[0]),
            dateFormatMaskYYYY_MM_dd,
          )
        : null
      const toDate = selectedDates?.to
        ? format(
            new Date(selectedDates.to?.split("T")[0]),
            dateFormatMaskYYYY_MM_dd,
          )
        : null
      setDatesValue([new Date(fromDate), new Date(toDate)])
    }
  }, [campaignId, getAllOptionsValues?.length, selectedDates, dashboardSelectValue])

  const handleChangeDashboards = (event) => {
    const currDashboardValue = event.target.value
    setDashboardSelectValue(currDashboardValue)

    if (dashboardSelectValue !== currDashboardValue) {
      onChangeDashboard({ dashboardId: currDashboardValue })
    }
  }

  const handleChangeCreators = (value) => {
    setCreatorsSelectValue(value)
  }

  const onChangeOpenEditModel = () => {
    setOpenEditModal(!openEditModal)
  }

  const onConfirmChangeCreators = () => {
    editDashboard()
    setOpenCreatorsSelect(false)
  }

  const onAccessDates = (newDates) => {
    const oldDateFrom = datesValue[0]
      ? format(datesValue[0], dateFormatMaskYYYY_MM_dd)
      : null
    const oldDateTo = datesValue[1]
      ? format(datesValue[1], dateFormatMaskYYYY_MM_dd)
      : null
    const startingDate = newDates[0]
      ? format(newDates[0], dateFormatMaskYYYY_MM_dd)
      : null
    const endingDate = newDates[1]
      ? format(newDates[1], dateFormatMaskYYYY_MM_dd)
      : null

    if (
      !!compareAsc(new Date(startingDate), new Date(oldDateFrom)) ||
      !!compareAsc(new Date(endingDate), new Date(oldDateTo))
    ) {
      onChangeSelectedDates({ startingDate, endingDate })
      setDatesValue([
        startingDate !== null ? new Date(startingDate) : null,
        endingDate != null ? new Date(endingDate) : null,
      ])
    }
  }

  return (
    <Grid container spacing={2} sx={{ height: "40px" }}>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth sx={{ maxWidth: "285px" }}>
          <InputLabel size='small'>{`Dashboard`}</InputLabel>
          <Select
            value={dashboardSelectValue}
            required
            label={`Dashboard`}
            size='small'
            onChange={handleChangeDashboards}
            disabled={!dashboardsData?.length}
          >
            {dashboardsData?.map((el) => (
              <MenuItem key={el.dashboardId} value={el.dashboardId}>
                {el.dashboardName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <FormControl fullWidth sx={{ maxWidth: "128px", marginRight: "24px" }}>
          <MultipleSelect
            size='small'
            open={openCreatorsSelect}
            onOpen={() => setOpenCreatorsSelect(true)}
            onClose={() => setOpenCreatorsSelect(false)}
            options={creatorsOptionsData || []}
            label={`Creators`}
            setSelected={handleChangeCreators}
            selected={creatorsSelectValue}
            withConfirmAction={onConfirmChangeCreators}
          />
        </FormControl>

        <PickerDateRange
          datesValue={datesValue}
          setDatesValue={() => {}}
          minDate={currentCampaign.startingDate}
          onAccess={onAccessDates}
        />
      </Grid>

      <BtnWrapper item xs={12} md={3}>
        <IconButton
          sx={{
            ml: 1,
            border: "1px solid #FF5D5A",
            borderRadius: 1,
            maxHeight: "40px",
            maxWidth: "40px",
          }}
          onClick={onChangeOpenEditModel}
        >
          <ModeEditRoundedIcon sx={{ color: "#FF5D5A" }} />
        </IconButton>

        <IconButton
          sx={{
            ml: 1,
            border: "1px solid #FF5D5A",
            borderRadius: 1,
            maxHeight: "40px",
            maxWidth: "40px",
          }}
          onClick={handleOpenPopover}
        >
          <ShareRoundedIcon sx={{ color: "#FF5D5A" }} />
        </IconButton>
      </BtnWrapper>
      <SharedPopover
        openPopover={openPopover}
        handleClosePopover={handleClosePopover}
        accessSelect={accessSelect}
        setAccessSelect={setAccessSelect}
        currentDashboard={currentDashboard}
        campaignId={campaignId}
        editDashboard={editDashboard}
      />
    </Grid>
  )
}
