import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import useTranslation from "../../../../../localization/useTranslation";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export const CityFilter = (props) => {
  const {
    query,
    handlersMap,
    permissions,
    availableCountriesCities,
    isModalOpen,
    closeModal,
  } = props || {};

  const { labelStrings } = useTranslation();

  const citiesFromQuery = query?.city;

  const [open, setOpen] = useState(false);

  const countryCitiesOptions = useMemo(() => {
    if (!query.country || !availableCountriesCities[query.country]) return [];
    return availableCountriesCities[query.country].map((city) => ({
      value: city,
      label: city,
    }));
  }, [query.country, availableCountriesCities]);

  const onChange = (event) => {
    const {
      target: { value },
    } = event || {};
    handlersMap?.city(value);
  };

  const onDelete = (deleteValue) => {
    const payload = citiesFromQuery
      ?.split(",")
      ?.filter((el) => el !== deleteValue);
    if (payload?.length) {
      handlersMap?.city(payload);
    } else {
      handlersMap?.city([]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    closeModal && closeModal(null);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if(isModalOpen) {
        setOpen(true);
      } else {
        setOpen(false)
      }
    }, 200);

    return () => clearTimeout(timer);
  }, [isModalOpen]);

  return (
    <Box>
      <FormControl
        sx={{
          width: 250,
          "& .MuiOutlinedInput-root": {
            outline: "unset",
            "& fieldset": {
              borderColor: "#BC9797",
            },
            "&:hover fieldset": {
              borderColor: "#784E4E",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#784E4E",
            },
          },
        }}
      >
        <InputLabel
          sx={{
            color: "#784E4E",
            fontWeight: "bold",
            "&.Mui-focused": {
              color: "#784E4E",
            },
          }}
          size='small'
        >
          {labelStrings.selectMultiCities}
        </InputLabel>
        <Select
          disabled={!permissions.allowCitiesSearch}
          size='small'
          multiple
          open={open}
          onClose={handleClose}
          onOpen={() => setOpen(true)}
          value={query.city ? query.city?.split(",") : []}
          onChange={onChange}
          input={<OutlinedInput label={labelStrings.selectMultiCities} />}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
                color: "#442424",
              },
            },
          }}
          renderValue={(selected) => {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    size='small'
                    key={value}
                    label={value}
                    variant='contained'
                    onMouseDown={(event) => event.stopPropagation()}
                    onDelete={() => onDelete(value)}
                    deleteIcon={<CloseRoundedIcon sx={{ fill: "#442424" }} />}
                    sx={{
                      color: "#442424",
                      fontWeight: "bold",
                      backgroundColor: "#F6DBDB",
                    }}
                  />
                ))}
              </Box>
            );
          }}
        >
          {!!countryCitiesOptions?.length && (
            <MenuItem
              key='clear'
              value=''
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                fontFamily: "Inter",
              }}
            >
              {labelStrings.all}
            </MenuItem>
          )}
          {countryCitiesOptions?.map((city) => (
            <MenuItem
              key={city?.value}
              value={city?.value}
              sx={{ fontSize: "12px", fontWeight: 700, fontFamily: "Inter" }}
            >
              {city?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
