// import React from 'react'
import ComparedValueGrowthDynamics from '../../../../common/dynamicsLabels/ComparedValueGrowthDynamics'

import {
  bubbledTextComponentFactory,
  qualitySubScoreComponentFactory,
  lockedAudienceFactory,
  audienceSectionFactory,
} from './comparedComponentsFactories'

import { SOCIAL_PLATFORMS_NAMES } from '../../../../../constants/appSettings'
import {
  COMPARE_INFLUENCERS_SCORES_ARRAY,
  COMPARE_INFLUENCERS_SCORES_ARRAY_YT,
} from '../../../../../constants/profile'
import { AUDIENCE_ANALYSIS_SECTIONS_NAMES } from '../../../../../constants/audienceAnalysis'
import { convertValueToPercentage } from '../../../../../utils'

// inner helper factories
const _comparedCountryCityLabelFactory = (country, city) => {
  if (!country && !city) return '-'
  if (country && city) return `${country} / ${city}`
  if (country && !city) return country
  if (!country && city) return city
  return ''
}

const _overallScoreFactory = profile => {
  return profile?.profileScore?.overallScore || 0
}

const _instaDetaledScoreFactory = (profile = {}, scorePropName = '') => {
  const scoreObj = profile.profileScore && profile.profileScore[scorePropName]
  const { score: scoreValue } = scoreObj || {}
  if (!scoreObj || !scoreValue) return 0
  return scoreValue || 0
}

const _detaledScoresBatchFactory = ({
  profile,
  isNavColumn,
  scoreStrings,
  socialPlatformIndex,
}) => {
  const isInstagram = socialPlatformIndex === SOCIAL_PLATFORMS_NAMES.instagram.index
  const isTikTok = socialPlatformIndex === SOCIAL_PLATFORMS_NAMES.tiktok.index

  if (isTikTok && !isNavColumn) return [] // tiktok is not implemented yet

  const compareScoresArray = isInstagram
    ? COMPARE_INFLUENCERS_SCORES_ARRAY
    : COMPARE_INFLUENCERS_SCORES_ARRAY_YT

  return compareScoresArray?.map(score => ({
    value: isNavColumn ? ' ' : _instaDetaledScoreFactory(profile, score.porfileObjScoreName),
    withBG: !isNavColumn,
    ...(!isNavColumn && {
      RenderComponent: qualitySubScoreComponentFactory({
        scoreLabel: scoreStrings[score.name]?.title,
      }),
    }),
  }))
}

const _audienceSectionFactory = ({
  profile,
  isNavColumn,
  labelStrings,
  allComparedAudiencesBlocked,
  comparedRanks,
  socialPlatformIndex,
}) => {
  const isAudienceAnalysisPresent = !!profile.audienceAnalysis

  const isYtPlatform = socialPlatformIndex === SOCIAL_PLATFORMS_NAMES.youtube.index

  if (allComparedAudiencesBlocked) {
    return [
      {
        startSectionLabel: labelStrings.audience,
        withStartSection: true,
        withBG: !isNavColumn,
        height: 100,
        value: ' ',
        ...(!isNavColumn && { RenderComponent: lockedAudienceFactory(profile) }),
      },
    ]
  }

  const commonProps = {
    withBG: !isNavColumn,
    height: 150,
    noMargin: true,
  }
  const useValue = !isNavColumn && isAudienceAnalysisPresent
  const _createNoLabelValue = label => {
    if (isNavColumn && !allComparedAudiencesBlocked) return label
    return isAudienceAnalysisPresent ? label : ' '
  }

  const rowDefaultArray = [
    { l: labelStrings.city, v: AUDIENCE_ANALYSIS_SECTIONS_NAMES.city, bg: false },
    { l: labelStrings.language, v: AUDIENCE_ANALYSIS_SECTIONS_NAMES.lang, bg: true },
    { l: labelStrings.gender, v: AUDIENCE_ANALYSIS_SECTIONS_NAMES.gender, bg: false, height: 60 },
    {
      l: labelStrings.ageGroups,
      v: AUDIENCE_ANALYSIS_SECTIONS_NAMES.age,
      bg: true,
      withMedian: false,
    },
    {
      l: labelStrings.mentions,
      v: AUDIENCE_ANALYSIS_SECTIONS_NAMES.mentions,
      bg: false,
      height: 320,
    },
    {
      l: labelStrings.audienceType,
      v: AUDIENCE_ANALYSIS_SECTIONS_NAMES.audienceType,
      bg: true,
      height: 100,
    },
  ]

  const rowYouTubeArray = [
    {
      startSectionLabel: labelStrings.audience,
      withStartSection: true,
      ...commonProps,
      height: 225,
      value: !useValue
        ? _createNoLabelValue(labelStrings.language)
        : profile.audienceAnalysis[AUDIENCE_ANALYSIS_SECTIONS_NAMES.lang],
      ...(!isNavColumn && {
        nameKey: AUDIENCE_ANALYSIS_SECTIONS_NAMES.lang,
        RenderComponent: isAudienceAnalysisPresent
          ? audienceSectionFactory()
          : lockedAudienceFactory(profile),
      }),
    },
    {
      ...commonProps,
      height: 80,
      value: !useValue
        ? _createNoLabelValue(labelStrings.gender)
        : profile.audienceAnalysis[AUDIENCE_ANALYSIS_SECTIONS_NAMES.genderObject],
      ...(!isNavColumn && {
        nameKey: AUDIENCE_ANALYSIS_SECTIONS_NAMES.gender,
        RenderComponent: isAudienceAnalysisPresent
          ? audienceSectionFactory()
          : lockedAudienceFactory(profile),
      }),
    },
    {
      ...commonProps,
      height: 100,
      value: !useValue
        ? _createNoLabelValue(labelStrings.audienceType)
        : profile.audienceAnalysis[AUDIENCE_ANALYSIS_SECTIONS_NAMES.sentimentAnalysis],
      ...(!isNavColumn && {
        nameKey: AUDIENCE_ANALYSIS_SECTIONS_NAMES.audienceType,
        RenderComponent: isAudienceAnalysisPresent
          ? audienceSectionFactory()
          : lockedAudienceFactory(profile),
      }),
    },
  ]

  const rowTikTokArray = rowDefaultArray?.slice(0, rowDefaultArray?.length - 2)

  const rowArray = {
    [SOCIAL_PLATFORMS_NAMES.instagram.index]: rowDefaultArray,
    [SOCIAL_PLATFORMS_NAMES.tiktok.index]: rowTikTokArray,
  }

  return isYtPlatform
    ? [...rowYouTubeArray]
    : [
        {
          startSectionLabel: labelStrings.audience,
          withStartSection: true,
          ...commonProps,
          height: 225,
          value: !useValue
            ? _createNoLabelValue(labelStrings.country)
            : profile.audienceAnalysis[AUDIENCE_ANALYSIS_SECTIONS_NAMES.country],
          ...(!isNavColumn && {
            nameKey: AUDIENCE_ANALYSIS_SECTIONS_NAMES.country,
            RenderComponent: isAudienceAnalysisPresent
              ? audienceSectionFactory()
              : lockedAudienceFactory(profile),
          }),
        },
        ...[
          // add custom heights here, if needed
          ...rowArray[socialPlatformIndex],
        ]?.map(item => {
          return {
            ...commonProps,
            nameKey: item.v,
            height: item.height || 150,
            withMedian: item.withMedian,
            value: !useValue ? _createNoLabelValue(item.l) : profile?.audienceAnalysis[item.v],
            profileId: profile._id,
            ...(useValue && {
              comparedRanks,
              RenderComponent: audienceSectionFactory(),
              withBG: item.bg,
            }),
          }
        }),
      ]
}

// -----------------

export const rowsListFactory = ({
  labelStrings = {},
  profile = {},
  isNavColumn,
  scoreStrings = {},
  allComparedAudiencesBlocked,
  comparedRanks,
  socialPlatformIndex,
}) => {
  return [
    //  Basic Info section. first element in Nav will be with title
    {
      value: isNavColumn ? labelStrings.name : profile.displayName,
      isBold: !isNavColumn,
      startSectionLabel: labelStrings.basicInfo,
      withStartSection: true,
    },
    {
      value: isNavColumn
        ? `${labelStrings.country} / ${labelStrings.city}`
        : _comparedCountryCityLabelFactory(profile.country, profile.city),
    },
    {
      value: isNavColumn ? labelStrings.description : profile.description,
      height: 48,
    },
    {
      value: isNavColumn ? labelStrings.website : profile.website,
      isLink: !!profile.website,
    },
    {
      value: isNavColumn ? labelStrings.followers : profile.followers,
      ...(!isNavColumn && {
        rank: comparedRanks.followers[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn ? labelStrings.following : profile.following,
    },
    {
      value: isNavColumn ? labelStrings.posts : profile.posts,
    },
    // Scores section
    {
      value: isNavColumn
        ? `${labelStrings.views}/${labelStrings.post}`
        : profile.medianViewsPerPost,
      startSectionLabel: labelStrings.statistics,
      withStartSection: true,
      ...(!isNavColumn && {
        rank: comparedRanks.viewsPerPost[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn
        ? `${labelStrings.post}/${labelStrings.week}`
        : Math.round(profile?.profileScore?.postFrequencyObject?.actualValue * 100) / 100,
      ...(!isNavColumn && {
        rank: comparedRanks.postWeek[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true, noPretty: true }),
      }),
    },
    {
      value: isNavColumn
        ? `${labelStrings.engagement} (${labelStrings.post})`
        : profile.engagementMean,
      ...(!isNavColumn && {
        rank: comparedRanks.engagement[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn
        ? labelStrings.growthRate
        : profile.monthlyGrowthObject && profile.monthlyGrowthObject.monthlyChange,
      noPretty: true,
      isPercent: true,
      ...(!isNavColumn && {
        rank: comparedRanks.monthlyGrowthChange[profile._id],
        RenderComponent: ComparedValueGrowthDynamics,
      }),
    },
    {
      value: isNavColumn
        ? labelStrings.monthGrowthFollowers
        : profile.monthlyGrowthObject && profile.monthlyGrowthObject.monthlyDifference,
      ...(!isNavColumn && {
        rank: comparedRanks.monthlyGrowthDiff[profile._id],
        RenderComponent: ComparedValueGrowthDynamics,
      }),
    },
    {
      value: isNavColumn ? labelStrings.averageLikes : profile.avgLikesPerPost,
      ...(!isNavColumn && {
        rank: comparedRanks.avgLikesPerPost[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn ? labelStrings.averageComments : profile.avgCommentsPerPost,
      ...(!isNavColumn && {
        rank: comparedRanks.avgCommentsPerPost[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },

    {
      value: isNavColumn
        ? `${labelStrings.stories}/${labelStrings.day}`
        : profile?.profileScore?.storyFrequencyObject?.actualValue,
      ...(!isNavColumn && {
        rank: comparedRanks.storiesDay[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn
        ? `${labelStrings.reels}/${labelStrings.week}`
        : profile?.profileScore?.reelFrequencyObject?.actualValue,
      ...(!isNavColumn && {
        rank: comparedRanks.reelsWeek[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn
        ? `${labelStrings.engagement} (${labelStrings.reels})`
        :  profile?.engagementReels,
      ...(!isNavColumn && {
        rank: comparedRanks.engagementReels[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn
        ? labelStrings.viewsPerStory
        : (profile.medianViewsPerStoryObject?.from + profile.medianViewsPerStoryObject?.to) / 2,
      ...(!isNavColumn && {
        rank: comparedRanks.viewsPerStory[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn ? labelStrings.playsPerReel : profile.medianPlaysPerReel,
      ...(!isNavColumn && {
        rank: comparedRanks.playsPerReel[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true, isPlaysPerReel: true }),
      }),
    },

    // Quality section
    {
      value: isNavColumn ? labelStrings.qualityScore : _overallScoreFactory(profile),
      startSectionLabel: labelStrings.quality,
      withStartSection: true,
      withSubSectionDevider: !isNavColumn,
      withBG: !isNavColumn,
      ...(!isNavColumn && {
        RenderComponent: bubbledTextComponentFactory({
          withMinWidth: true,
          noPretty: true,
          withAbsRankValue: true,
        }),
      }),
    },

    ..._detaledScoresBatchFactory({ profile, isNavColumn, scoreStrings, socialPlatformIndex }),

    // Audience section
    ..._audienceSectionFactory({
      profile,
      isNavColumn,
      labelStrings,
      allComparedAudiencesBlocked,
      comparedRanks,
      socialPlatformIndex,
    }),
  ]
}

export const rowsListFactoryTikTok = ({
  labelStrings = {},
  profile = {},
  isNavColumn,
  socialPlatformIndex,
  allComparedAudiencesBlocked,
  comparedRanks,
}) => {
  return [
    //  Basic Info section. first element in Nav will be with title
    {
      value: isNavColumn ? labelStrings.name : profile.displayName,
      isBold: !isNavColumn,
      startSectionLabel: labelStrings.basicInfo,
      withStartSection: true,
    },
    {
      value: isNavColumn
        ? `${labelStrings.country} / ${labelStrings.city}`
        : _comparedCountryCityLabelFactory(profile.country, profile.city),
    },
    {
      value: isNavColumn ? labelStrings.description : profile.description,
      height: 48,
    },
    {
      value: isNavColumn ? labelStrings.website : profile.website,
      isLink: !!profile.website,
    },
    {
      value: isNavColumn ? labelStrings.followers : profile.followers,
      ...(!isNavColumn && {
        rank: comparedRanks.followers[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn ? labelStrings.following : profile.following,
    },
    {
      value: isNavColumn ? labelStrings.posts : profile.posts,
    },
    // Scores section
    {
      value: isNavColumn ? `${labelStrings.totalPlays}` : profile.totalViews,
      startSectionLabel: labelStrings.statistics,
      withStartSection: true,
      ...(!isNavColumn && {
        rank: comparedRanks.totalViews[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn ? labelStrings.profileScore : profile.profileScore?.overallScore,
      ...(!isNavColumn && {
        rank: comparedRanks.profileScore[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn ? labelStrings.postsPerWeek : profile?.postsPerWeek,
      ...(!isNavColumn && {
        rank: comparedRanks.postsPerWeek[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true, noPretty: true }),
      }),
    },
    {
      value: isNavColumn
        ? `${labelStrings.engagement} (${labelStrings.post})`
        : profile.engagementMean,
      ...(!isNavColumn && {
        rank: comparedRanks.engagement[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn
        ? labelStrings.growthRate
        : profile.monthlyGrowthObject && profile.monthlyGrowthObject.monthlyChange,
      noPretty: true,
      isPercent: true,
      ...(!isNavColumn && {
        rank: comparedRanks.monthlyGrowthChange[profile._id],
        RenderComponent: ComparedValueGrowthDynamics,
      }),
    },
    {
      value: isNavColumn
        ? labelStrings.monthGrowthFollowers
        : profile.monthlyGrowthObject && profile.monthlyGrowthObject.monthlyDifference,
      ...(!isNavColumn && {
        rank: comparedRanks.monthlyGrowthDiff[profile._id],
        RenderComponent: ComparedValueGrowthDynamics,
      }),
    },
    {
      value: isNavColumn
        ? labelStrings.viralPosts
        : !!profile.viralCountArray?.length &&
          profile.viralCountArray[0] / (profile.viralCountArray[1] / 100),
      isPercent: true,
      ...(!isNavColumn && {
        rank: comparedRanks.viralPosts[profile._id],
        RenderComponent: ComparedValueGrowthDynamics,
      }),
    },
    {
      value: isNavColumn ? labelStrings.expectedAudiencePerPosts : profile.impressions,
      ...(!isNavColumn && {
        rank: comparedRanks.expectedAudiencePerPost[profile._id],
        RenderComponent: ComparedValueGrowthDynamics,
      }),
    },
    {
      value: isNavColumn ? labelStrings.oneHitWonder : `${profile.oneHitWonder}`,
    },

    // Audience section
    ..._audienceSectionFactory({
      profile,
      isNavColumn,
      labelStrings,
      allComparedAudiencesBlocked,
      comparedRanks,
      socialPlatformIndex,
    }),
  ]
}

export const rowsListFactoryYouTube = ({
  labelStrings = {},
  profile = {},
  scoreStrings = {},
  isNavColumn,
  socialPlatformIndex,
  allComparedAudiencesBlocked,
  comparedRanks,
}) => {
  return [
    {
      value: isNavColumn
        ? `${labelStrings.country} / ${labelStrings.city}`
        : _comparedCountryCityLabelFactory(profile.country, profile.city),
    },
    {
      value: isNavColumn ? labelStrings.description : profile.description,
      height: 48,
    },
    {
      value: isNavColumn ? labelStrings.website : profile.website,
      isLink: !!profile.website,
    },
    {
      value: isNavColumn ? labelStrings.subscribers : profile.followers,
      ...(!isNavColumn && {
        rank: comparedRanks.followers[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    // {
    //   value: isNavColumn ? labelStrings.videos : profile.posts,
    // },

    // Scores section
    {
      value: isNavColumn
        ? `${labelStrings.engagement} (${labelStrings.videos})`
        : profile.engagementRatePosts
        ? convertValueToPercentage(profile.engagementRatePosts)
        : null,
      startSectionLabel: labelStrings.statistics,
      withStartSection: true,
      isPercent: true,
      noPretty: true,
      ...(!isNavColumn && {
        rank: comparedRanks.engagementRatePosts[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true, noPretty: true }),
      }),
    },
    {
      value: isNavColumn
        ? `${labelStrings.viewsMedian} (${labelStrings.videos})`
        : profile.medianViewCountPosts,
      ...(!isNavColumn && {
        rank: comparedRanks.medianViewCountPosts[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn
        ? `${labelStrings.engagement} (${labelStrings.shorts})`
        : convertValueToPercentage(profile.engagementRateShorts),
      isPercent: true,
      noPretty: true,
      ...(!isNavColumn && {
        rank: comparedRanks.engagementRateShorts[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true, noPretty: true }),
      }),
    },
    {
      value: isNavColumn
        ? `${labelStrings.viewsMedian} (${labelStrings.shorts})`
        : profile.medianViewCountShorts,
      ...(!isNavColumn && {
        rank: comparedRanks.medianViewCountShorts[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true }),
      }),
    },
    {
      value: isNavColumn
        ? labelStrings.growthRate
        : profile.monthlyGrowthFollowers && profile?.monthlyGrowthFollowers[1],
      noPretty: true,
      isPercent: true,
      ...(!isNavColumn && {
        rank: comparedRanks.growthRateYT[profile._id],
        RenderComponent: !profile.monthlyGrowthFollowers
          ? bubbledTextComponentFactory({ withMinWidth: true })
          : ComparedValueGrowthDynamics,
      }),
    },
    {
      value: isNavColumn
        ? labelStrings.monthGrowthFollowers
        : profile.monthlyGrowthFollowers && profile?.monthlyGrowthFollowers[0],
      ...(!isNavColumn && {
        rank: comparedRanks.growthRateFollowersYT[profile._id],
        RenderComponent: !profile.monthlyGrowthFollowers
          ? bubbledTextComponentFactory({ withMinWidth: true })
          : ComparedValueGrowthDynamics,
      }),
    },

    {
      value: isNavColumn
        ? labelStrings.averageViewsGrowthVideos
        : profile.monthlyGrowthObject && profile.monthlyGrowthObject?.postViews?.monthlyChange,
      noPretty: true,
      isPercent: true,
      ...(!isNavColumn && {
        rank: comparedRanks.averageViewsGrowthVideos[profile._id],
        RenderComponent: !profile.monthlyGrowthObject?.postViews?.monthlyChange
          ? bubbledTextComponentFactory({ withMinWidth: true })
          : ComparedValueGrowthDynamics,
      }),
    },
    {
      value: isNavColumn
        ? labelStrings.growthByAverageViews30dVideos
        : profile.monthlyGrowthObject && profile.monthlyGrowthObject?.postViews?.monthlyDifference,
      ...(!isNavColumn && {
        rank: comparedRanks.growthByAverageViews30dVideos[profile._id],
        RenderComponent: !profile.monthlyGrowthObject?.postViews?.monthlyDifference
          ? bubbledTextComponentFactory({ withMinWidth: true })
          : ComparedValueGrowthDynamics,
      }),
    },
    {
      value: isNavColumn
        ? labelStrings.averageViewsGrowthShorts
        : profile.monthlyGrowthObject && profile?.monthlyGrowthObject?.shortsViews?.monthlyChange,
      noPretty: true,
      isPercent: true,
      ...(!isNavColumn &&
        profile?.monthlyGrowthObject?.shortsViews?.monthlyChange && {
          rank: comparedRanks.averageViewsGrowthShorts[profile._id],
          RenderComponent: ComparedValueGrowthDynamics,
        }),
    },
    {
      value: isNavColumn
        ? labelStrings.growthByAverageViews30dShorts
        : profile.monthlyGrowthObject &&
          profile?.monthlyGrowthObject?.shortsViews?.monthlyDifference,
      ...(!isNavColumn &&
        profile?.monthlyGrowthObject?.shortsViews?.monthlyDifference && {
          rank: comparedRanks.growthByAverageViews30dShorts[profile._id],
          RenderComponent: ComparedValueGrowthDynamics,
        }),
    },

    // Quality section
    {
      value: isNavColumn ? labelStrings.qualityScore : _overallScoreFactory(profile),
      startSectionLabel: labelStrings.quality,
      withStartSection: true,
      withSubSectionDevider: !isNavColumn,
      withBG: !isNavColumn,
      ...(!isNavColumn && {
        rank: comparedRanks.profileScoreYT[profile._id],
        RenderComponent: bubbledTextComponentFactory({ withMinWidth: true, noPretty: true }),
      }),
    },

    ..._detaledScoresBatchFactory({ profile, isNavColumn, scoreStrings }),

    // Audience section
    ..._audienceSectionFactory({
      profile,
      isNavColumn,
      labelStrings,
      allComparedAudiencesBlocked,
      comparedRanks,
      socialPlatformIndex,
    }),
  ]
}
