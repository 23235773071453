import React from 'react'
import { Avatar, Box, Button, Typography } from '@mui/material'
import MenuPopover from '../../../components/menu-popover'
import { PlusIcon } from '../../../assets/icons/navbar'
import { StyledBoxPopoverContent } from './styles'

const _MOCK_BTN_DATA = new Array(
  {
    id: 1,
    platform: 'TikTok',
    avatarURL: 'https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg',
    username: 'username1',
  },
  {
    id: 2,
    platform: 'YouTube',
    avatarURL: 'https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg',
    username: 'username2',
  },
  {
    id: 3,
    platform: 'TikTok',
    avatarURL: 'https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg',
    username: 'username3',
  },
  {
    id: 4,
    platform: 'Instagram',
    avatarURL: 'https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg',
    username: 'username4',
  }
)

export const PopoverItemMenu = props => {
  const { openPopover, handleClosePopover } = props

  return (
    <>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="left-top"
        sx={{ maxWidth: '239px', p: '14px' }}
      >
        <Box>
          <Box sx={{ py: 1 }}>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '22px', paddingBottom: '5px' }}
            >
              Please select the account that you want to merge.
            </Typography>
            <Typography sx={{ fontSize: '12px', lineHeight: '18px' }}>
              Note that you need to add the creator’s account to your campaign list.
            </Typography>
          </Box>
          <StyledBoxPopoverContent>
            {_MOCK_BTN_DATA?.map(el => (
              <PopoverItem
                key={el.id}
                username={el.username}
                avatarURL={el.avatarURL}
                platform={el.platform}
              />
            ))}
          </StyledBoxPopoverContent>
        </Box>
      </MenuPopover>
    </>
  )
}

export const PopoverItem = ({ username, avatarURL, platform }) => {
  return (
    <Button
      variant="text"
      sx={{ width: '100%' }}
      startIcon={<PlusIcon />}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar sx={{ height: '42px', width: '42px' }} alt="" src={avatarURL} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: '5px',
            minWidth: '110px'
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '22px', color: '#212B36' }}
          >
            @{username}
          </Typography>
          <Typography
            sx={{ fontWeight: 400, fontSize: '12px', lineHeight: '18px', color: '#212B36' }}
          >
            Platform: {platform}
          </Typography>
        </Box>
      </Box>
    </Button>
  )
}
