import { USER_MIN_SEATS, USER_ROLES } from '../../constants/user'
import { FAV_COLLECTION_NAME, UNLOCKED_COLLECTION_NAME } from '../../constants/collections'
import { COMPARED_LOADING } from '../../constants/comparedProfiles'
import { pagesBeforeProfileRedirect } from '../../constants/pagesBeforeProfileRedirect'
import { store } from '../init'
// import { all } from 'country-codes-list'

// app settings selectors
export const selectedPlatformSelector = state => state.appSettings.selectedPlatform
export const isSignUpOpenedSelector = state => state.appSettings.isSignUpOpened
export const isSignInOpenedSelector = state => state.appSettings.isSignInOpened
export const isAccountAlreadyExistsSelector = state => state.appSettings.accountAlreadyExists
export const isTriggeredToContactsSelector = state => state.appSettings.triggerToContacts
export const limitsSelector = state => state.appSettings.limits
export const limitsExistSelector = state => Object.values(state.appSettings.limits).some(l => l)
export const availableCountriesCitiesSelector = state => state.appSettings.availableCountriesCities
export const isSignUpSuccessModalOpenedSelector = state => state.appSettings.isSignUpSuccessOpened
export const lastPageBeforeProfileRedirectSelector = state =>
  state.appSettings.lastPageBeforeProfileRedirect || pagesBeforeProfileRedirect.OTHER
export const newsModalContentObjectSelector = state => state.appSettings.newsModalContentObject

// user selectors
export const userCredsSelector = state => state.userCredentials
export const usernameSelector = state => state.userCredentials.username
export const userIdSelector = state => state.userCredentials.id
export const userRoleSelector = state => state.userCredentials.role
export const userSubSelector = state => state.userCredentials.userSub
export const userStartupDataLoadingSelector = state =>
  state.userCredentials.isUserStartupDataLoading
export const userGrantSelector = state => state.userCredentials.grant || {}
export const userCredsLoadProgressSelector = state => state.userCredentials.isUserCredsLoading
export const isUserAuthenticatedSelector = state =>
  userRoleSelector(state) === USER_ROLES.authenticated
export const isProcessSignupSelector = state => state.userCredentials.isProcessSignup
export const isUserLoggedInSelector = state => state.userCredentials.isUserLoggedIn
export const refreshGrantSelector = state => state.userCredentials.refreshGrant
export const isUserStartupDataPresentSelector = state =>
  state.userCredentials.isUserStartupDataPresent
export const isUserInTrialSelector = state => userGrantSelector(state).in_trial
export const userDetailsSelector = state => state.userCredentials.details.data
export const isUserDetailsPresentSelector = state =>
  !!Object.keys(state.userCredentials.details.data).length
export const isUserDetailsLoadingSelector = state => state.userCredentials.details.isLoading
export const isUserDetailsErrorSelector = state => state.userCredentials.details.isError
export const currentAudienceTokensSelector = state => state.userCredentials.audienceTokens
export const userAudienceTokensSelector = state => {
  const { audience_tokens_monthly } = state.userCredentials.grant || {}
  const {
    audienceTokensUsed,
    tokensResetDate,
    audienceTokensTotal,
  } = currentAudienceTokensSelector(state)
  return {
    audienceTokensMonthly: audience_tokens_monthly || 0,
    audienceTokensUsed: audienceTokensUsed || 0,
    audienceTokensTotal: audienceTokensTotal || 0,
    tokensResetDate,
  }
}
export const areAvailableAudienceTokensSelector = state => {
  const { audienceTokensTotal, audienceTokensUsed } = userAudienceTokensSelector(state)
  const oneTimeTokens = userOneTimeTokensSelector(state)
  const oneTimeTokensCount = oneTimeTokens
    ? oneTimeTokens.reduce((acc, item) => acc + item.extraTokens, 0)
    : 0
  return audienceTokensUsed < audienceTokensTotal
}
export const userMaxSeatsSelector = state => userGrantSelector(state).user_seats
export const userSeatsSelector = state => state.userCredentials.seats
export const userUsedSeatsSelector = state => state.userCredentials.seats.seatsUsed
export const userUsedSeatsCountSelector = state => state.userCredentials.seats.seatsUsed.length
export const isUserSeatsChangeProgressSelector = state =>
  state.userCredentials.seats.isChangeInProgress
export const userAvaliableSeatsSelector = state => {
  const maxSeats = userMaxSeatsSelector(state)
  const usedSeats = userUsedSeatsCountSelector(state)
  const diff = maxSeats - usedSeats
  return diff > 0 ? diff : 0
}
export const canUserAddMoreSeatsSelector = state => {
  const maxSeats = userMaxSeatsSelector(state)
  if (maxSeats < USER_MIN_SEATS) return false
  const usedSeats = userUsedSeatsCountSelector(state)
  return usedSeats < maxSeats
}
export const isSubUserSelector = state => !!userGrantSelector(state).belongs_to
export const subUserMainRefSelector = state => userGrantSelector(state).belongs_to
export const userOneTimeTokensSelector = state => {
  const { oneTimeTokens } = userGrantSelector(state)
  return oneTimeTokens || []
}
export const salesManagerSelector = state => state.userCredentials.salesManager || {}
export const userEmailFromLandingSelector = state => state.userCredentials.userEmailFromLanding
export const googleAnalyticsDataSelector = state => state.userCredentials.googleAnalyticsData
export const userAuthErrorSelector = state => state.userCredentials.authError
export const userTrackSelector = state => state.userCredentials.userTrack

// search selectors
export const searchDataSelector = state => state.search
export const searchResultsSelector = state => state.search.results
export const searchResultsUsersSelector = state => state.search.results.users
export const searchResultsPresentSelector = state => !!state.search.results.users.length
export const currentSearchQuerySelector = state => state.search.currentSearchQuery
export const currentSearchQuerySkipCountSelector = state =>
  state.search.currentSearchQuery.skipCount
export const currentSearchQuerySortingSelector = state => state.search.currentSearchQuery.sorting
export const currentSearchQueryKeywordsSelector = state => state.search.currentSearchQuery.keywords
export const currentSearchQueryCategoriesSelector = state =>
  state.search.currentSearchQuery.categories
export const searchLoadingStatusSelector = state => state.search.isSearchLoading
export const searchTypeSelector = state => state.search.searchType
export const searchContentActiveMapMarker = state => state.search.searchContentActiveMapMarker
export const searchSuggestionsSelector = state => state.search.suggestions
export const searchSuggestionsReceiveSurveyResultSelector = state => state.search.suggestionsReceiveSurveyResult

// opened profile selectors
export const selectedProfileSelector = state => state.profileSelected
export const selectedProfileDetailsSelector = state => state.profileSelected.profile
export const similarAccountsSelector = state => state.profileSelected.similarAccounts
export const isProfileLoadingSelector = state => state.profileSelected.isLoading
export const isProfileNotFoundSelector = state => state.profileSelected.isProfileNotFound
export const isGetOpenedProfileIdSelector = state => state.profileSelected.openedProfileId
export const profileBrandCollaborationsData = state => state.profileSelected.brandCollaborations

// error selectors
export const errorSelector = state => state.error
export const isErrorSelector = state => state.error.isError
export const errorMsgNameSelector = state => state.error.toastMsgName

// collections selectors
export const userCollectionsAllDataSelector = state => state.userCollections
export const collectionsSelector = state => state.userCollections.collectionsList
export const openedCollectionFromListDynamicSelector = _state => collectionId => {
  const latestStore = store.getState()
  return (
    latestStore.userCollections.collectionsList.find(col => col.collectionId === collectionId) || {}
  )
}
export const openedCollectionSelector = state => state.userCollections.openedCollection
export const openedCollectionUsersSelector = state =>
  state.userCollections.openedCollection.users.userdata
export const openedCollectionUsersCountSelector = state =>
  state.userCollections.openedCollection.users.length
export const openedCollectionSortingSelector = state =>
  state.userCollections.openedCollection.sorting
export const isLoadingCollectionSelector = state => state.userCollections.isLoading
export const showCollectionAccessSelector = state => {
  const { belongs_to } = userGrantSelector(state)
  const usedSeatsList = userUsedSeatsSelector(state)
  if (!belongs_to) {
    return !!usedSeatsList.length // for main account - only if pro and has at least one sub account
  }
  return true
}

// campaigns selectors
export const allCampaignsSelector = state => state.userCampaigns.campaignData
export const isLoadingCampaignsSelector = state => state.userCampaigns.isAllCampaignsLoading
export const isSingleCampaignLoadingSelector = state => state.userCampaigns.isSingleCamapignLoading
export const campaignsListSelector = state => state.userCampaigns.campaignData.campaignArray
export const currentOpenedCampaignSelector = state => state.userCampaigns.currentOpenedCampaign
export const currentOpenedCampaignUsersSelector = state =>
  state.userCampaigns.currentOpenedCampaign.users
export const currentCampaignSortingSelector = state =>
  state.userCampaigns.currentOpenedCampaign.sorting
export const currentCampaignFiltersSelector = state =>
  state.userCampaigns.currentOpenedCampaign.filters
export const currentCampaignShowOnlyHitsFilterSelector = state =>
  state.userCampaigns.currentOpenedCampaign.filters.showOnlyHits
export const currentCampaignAirTableDataSelector = state =>
  state.userCampaigns.currentOpenedCampaign.airtableData
export const campaignDashboard = state => state.userCampaigns.dashboard
export const getAllCampaignsSelector = state => state.userCampaigns.newCampaignData.campaignArray
export const contentListSelector = state => state.userCampaigns.contentList
export const creatorListSelector = state => state.userCampaigns.creatorList
export const calendarDataSelector = state => state.userCampaigns.calendar
export const goalsSelector = state => state.userCampaigns.goals
export const isLoadingNewCampaignSelector = state => state.userCampaigns.isLoading
export const trackedMetricsSelector = state => state.userCampaigns.trackedMetrics
export const customFieldsSelector = state => state.userCampaigns.customFields
export const pipelineSelector = state => state.userCampaigns.pipeline
export const isExceedingCampaignLimit = state => !!(state.userCampaigns.campaignData?.trackNow >= state.userCampaigns.campaignData?.trackMax)
export const createCampaignSelector = state => state.userCampaigns.createCampaign
// compare profiles selectors
export const compareProfilesSelector = state => state.compareProfiles.profiles
export const isCompareProfilesExistSelector = state =>
  !!Object.keys(state.compareProfiles.profiles).length
export const comparedProfilesIdsSelector = state => Object.keys(state.compareProfiles.profiles)
export const comparedProfilesCountSelector = state =>
  Object.keys(state.compareProfiles.profiles).length
export const comparedProfilesPlatformSelector = state =>
  state.compareProfiles.comparedProfilesPlatform
export const comparedProfilesSortingSelector = state => state.compareProfiles.sorting
export const comparedProfilesAudienceAllLockedSelector = state => {
  const { profiles } = state.compareProfiles
  const profilesList = Object.values(profiles)
  if (!profilesList.length) return true
  const allAudiencedBlocked = profilesList.every(profile => !profile.audienceAnalysis)
  return allAudiencedBlocked
}
export const comparedProfilesRanksSelector = state => state.compareProfiles.ranks
export const comparedProfilesAnyLoadingSelector = state =>
  Object.values(state.compareProfiles.profiles).some(p => !!p[COMPARED_LOADING])

// additional fields selectors
export const userAdditionalFieldsSelector = state => state.userAdditionalFields.additionalFieldsList
export const isAdditionalFieldsLoadingSelector = state => state.userAdditionalFields.isLoading
export const isAdditionalFieldCreatingSelector = state => state.userAdditionalFields.isCreating
export const isAdditionalFieldChangingSelector = state => state.userAdditionalFields.isChanging

// explore selectors
export const suggestedCreatorsArraySelector = state => state.explore.suggestedCreatorsArray
export const recentSearchArraySelector = state => state.explore.recentSearchArray
export const currentExploreRecentSearchQuery = state => state.explore.recentSearchQuery
export const currentExploreRecentSearchQuerySkipCountSelector = state =>
  state.explore.recentSearchQuery.skipCount
export const currentRecentSearchQuerySortingSelector = state => state.explore.recentSearchQuery.sorting
export const currentRecentSearchQueryKeywordsSelector = state => state.explore.recentSearchQuery.keywords
export const currentRecentSearchQueryCategoriesSelector = state =>
    state.explore.recentSearchQuery.categories
export const currentSuggestionUsersDataSelector = state => state.explore.suggestionSelectedData
export const currentSuggestionsAdditionalDataSelector = state => state.explore.additionalData
export const currentExploreListDataSelector = state => state.explore.currentExploreListData

//AUDIENCE REPORTS selectors
export const audienceReportsSelector = state => state.audienceReports

//
//
// DYNAMIC SELECTORS
//
//

export const noRerenderEqFn = state => true // avoids multiple selectors outputs

// polymorphic selector to check if user can be added to collection, can check for items count and for user presence in collection
export const canAddToCollectionDynamicSelector = state => ({
  collectionId,
  checkPresenceUser = null,
  withReasonsObj = false,
}) => {
  // This selector must be used with equality function noRerenderEqFn to avoid multiple rerenders in components
  const collectionsArr = collectionsSelector(store.getState()) // use store obj here to avoid duplicating re-renders
  //const { max_collection_entries } = userGrantSelector(store.getState())
  const max_collection_entries = 800
  const { count = 0 } = collectionsArr.find(col => col.collectionId === collectionId) || {}
  const maxCountNotExceed = count < max_collection_entries
  if (collectionId === UNLOCKED_COLLECTION_NAME) {
    return withReasonsObj ? { allowed: false } : false
  }
  if (!checkPresenceUser)
    return withReasonsObj
      ? { allowed: maxCountNotExceed, countExceed: !maxCountNotExceed }
      : maxCountNotExceed
  // when we have to check if user is already in collection
  if (!maxCountNotExceed) return withReasonsObj ? { allowed: false, countExceed: true } : false
  const { collectionArray = [], isFav = false } = checkPresenceUser
  if (
    (collectionId === FAV_COLLECTION_NAME && isFav) ||
    collectionArray.some(cId => cId === collectionId)
  ) {
    return withReasonsObj ? { allowed: false, isInCollection: true } : false
  }
  return withReasonsObj ? { allowed: true } : true
}

export const canCreateCollectionsSelector = state => {
  const collectionsArr = collectionsSelector(state)
  const { max_collections, enable_collections } = userGrantSelector(state)
  if (!enable_collections) return false
  const currentCollectionsCount = collectionsArr.filter(c => c.collectionId !== FAV_COLLECTION_NAME)
    .length
  return max_collections ? currentCollectionsCount < max_collections : enable_collections
}

export const canCreateCampaignsSelector = state => {
  const campaignsArr = campaignsListSelector(state)
  const { enable_tracking, max_campaigns } = userGrantSelector(state)
  if (!enable_tracking || !max_campaigns || !campaignsArr) return false
  return campaignsArr.length < max_campaigns
}

export const collectionUsersCountDiffSelector = state => {
  const collectionsArr = collectionsSelector(state)
  const getTargetCollectionPrev = collectionId =>
    collectionsArr.find(c => c.collectionId === collectionId)
  return collectionId => {
    const latestCollectionsArr = collectionsSelector(store.getState()) // take latest version of state
    const prevCollection = getTargetCollectionPrev(collectionId)
    const latestCollection = latestCollectionsArr.find(c => c.collectionId === collectionId)
    if (!prevCollection || !latestCollection) return null
    const { count: prevCount } = prevCollection
    const { count: latestCount } = latestCollection
    return {
      prevCount,
      latestCount,
      diff: latestCount - prevCount,
    }
  }
}

export const isProfileAddedToCompareDynamicSelector = state => profileId => {
  const compareProfilesIds = comparedProfilesIdsSelector(state)
  if (!Object.keys(compareProfilesIds).length) return false
  return compareProfilesIds.includes(profileId)
}
