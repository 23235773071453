import React, { useState } from "react"
import { Box } from "@mui/material"
import AddCreatorsTo from "../addBrandMentions"
import { useDispatch } from "react-redux"
import { changeTempBrandMentionsData } from "../../../../../redux/ducks/searchDucks"

export const BrandMentionFilter = (props) => {
  const { selectedPlatform, query, handlersMap, permissions, isBrandType } =
    props || {}

  const dispatch = useDispatch()

  const valuesFromQuery = isBrandType ? query.creatorCollaborations : query.brandMentions

  const brandMentionsValuesToArray = valuesFromQuery
    ? valuesFromQuery?.split(",")
    : []

  const [brandMentions, setBrandMentions] = useState([])
  const brandMentionsIds = brandMentions?.map((creator) => creator._id)

  const addBrandMentions = (creator) => {
    const updatedValue = [...new Set([...brandMentions, creator])].filter(
      (el) => el !== undefined
    )
    setBrandMentions(updatedValue)
    const payloadMentions = updatedValue
      .map((el) => el.username)
      .filter((username) => username !== undefined)
    const mergedPayloadData = [
      ...new Set([...brandMentionsValuesToArray, ...payloadMentions]),
    ].filter((el) => el !== undefined).join()

    isBrandType ? handlersMap?.creatorCollaborations(mergedPayloadData) : handlersMap?.brandMentions(mergedPayloadData)
    dispatch(changeTempBrandMentionsData(updatedValue))
  }
  
  const removeBrandMentions = ({ username }) => {
    const updatedValueToQuery = brandMentionsValuesToArray.filter(item => item !== username)
    const payload = [...new Set([...updatedValueToQuery])]
    isBrandType ? handlersMap?.creatorCollaborations(payload?.join()) : handlersMap?.brandMentions(payload?.join())
    const filteredBrandMentions = brandMentions?.length ? brandMentions?.filter(el => payload.includes(el.username)) : []
    setBrandMentions(filteredBrandMentions)

    const filtered = brandMentions?.filter(el => el.username !== username)
    dispatch(changeTempBrandMentionsData(filtered))
  }

  const onRemoveChip = (username) => {
    const payload = brandMentionsValuesToArray
      ?.filter((el) => el !== username)
      ?.join()
    isBrandType ? handlersMap?.creatorCollaborations(payload) :  handlersMap?.brandMentions(payload)
    
    const filtered = brandMentions?.filter(el => el.username !== username)
    dispatch(changeTempBrandMentionsData(filtered))
  }

  return (
    <Box
      sx={{
        minWidth: "250px",
        height: "auto",
        position: 'relative',
        "& .MuiOutlinedInput-root": {
          color: "#6B4545",
          outline: "unset",
          "& fieldset": {
            borderColor: "#BC9797",
          },
          "&:hover fieldset": {
            borderColor: "#784E4E",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#784E4E",
          },
        },
        "& .MuiInputLabel-root": {
          color: "#6B4545",
          "&.Mui-focused": {
            color: "#6B4545",
          },
        },
      }}
    >
      <AddCreatorsTo
        isAllowedToAddCreators={true}
        brandMentionsIdsFromState={brandMentionsIds}
        addBrandMentions={addBrandMentions}
        removeBrandMentions={removeBrandMentions}
        isSetCreatorId={false}
        isDisabledInput={brandMentionsValuesToArray?.length > 10}
        creatorType={""}
        changeCreatorsData={setBrandMentions}
        brandMentionsValuesToArray={brandMentionsValuesToArray}
        onRemoveChip={onRemoveChip}
      />
    </Box>
  )
}
