import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { StyledRootScrollbar, StyledScrollbar } from './styles'

const Scrollbar = ({ children, sx = {} , ...other }) => {
  const { pathname } = other || {}
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    )
  }

  useEffect(() => {
    const scrollElement = document?.querySelector('.simplebar-content-wrapper')

    if (scrollElement) {
      scrollElement.scrollTo(0, 0)
    }
  }, [pathname])

  return (
    <StyledRootScrollbar>
      <StyledScrollbar timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  )
}

Scrollbar.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default memo(Scrollbar)
