export const SOCIAL_PLATFORMS_NAMES = {
  instagram: { name: 'INSTAGRAM', prettyName: 'Instagram', index: 'instagram' },
  tiktok: { name: 'TIKTOK', prettyName: 'TikTok', index: 'tiktok' },
  youtube: { name: 'YOUTUBE', prettyName: 'YouTube', index: 'youtube' },
}

export const USER_INDEXES = {
  tiktok: 'tiktok',
  instagram: 'instagram',
  youtube: 'youtube',
}

export const SOCIAL_LINKS_PATHS = {
  [USER_INDEXES.instagram]: username => `https://www.instagram.com/${username}`,
  [USER_INDEXES.tiktok]: username => `https://www.tiktok.com/@${username}`,
  [USER_INDEXES.youtube]: chanel_id => `https://www.youtube.com/channel/${chanel_id}`,
  ytVideoLink: id => `https://www.youtube.com/watch?v=${id}`,
  postsInstagram: id => `https://www.instagram.com/p/${id}`,
  reelsInstagram: id => `https://www.instagram.com/reel/${id}`,
  storiesInstagram: (username, id) => `https://www.instagram.com/stories/${username}/${id}`,
  postsTiktok: (username, id) => `https://www.tiktok.com/@${username}/video/${id}`,
}

export const NAVBAR_HEIGHT = 64
export const FOOTER_HEIGHT = 55
export const NAVBAR_FOOTER_SUM_HEIGHT = NAVBAR_HEIGHT + FOOTER_HEIGHT
export const SIDEBAR_WIDTH = 350
export const SIDEBAR_SEARCH_WIDTH = 220
export const SIDEBAR_MINIMAL_WIDTH = 100
export const CAMPAIGN_SIDEBAR_WIDTH = 298

export const MAX_NAME_LIMIT = 60
export const MAX_DESCRIPTION_LIMIT = 1000

export const TWENTY_FOUR_HOURS_MS = 1000 * 60 * 60 * 24 // 24h in milliseconds -> 1s * 1min * 1h * 24h

export const START_MH_SHOW_COUNT = 3
export const MENTION_PREFIX = '@'
export const HASHTAG_PREFIX = '#'
export const MENTIONS = 'mentions'
export const HASHTAGS = 'hashtags'

export const _LOOKALIKE_TYPE = "LOOKALIKE_TYPE"
export const _CREATOR_BASE_TYPE = "CREATOR_BASE_TYPE"

export const SINGLE_MH_NAMES = {
  [MENTIONS]: 'mention',
  [HASHTAGS]: 'hashtag',
}

export const CAMPAIGN_USER_DEFAULT_POST_COUNT = 20
export const CAMPAIGN_USER_POST_LIST_ROW_HEIGHT = 70

export const INFLUDATA_NAME = 'influData'
export const INFLUDATA_HOSTS = {
  infludata: 'https://www.infludata.com',
  infludataApp: 'https://app.infludata.com',
  fileServer: 'https://img.infludata.com',
  faq: 'https://faq.infludata.com',
}

export const FILE_SERVER_HOST = 'https://img.infludata.com'

export const API_STATUS_CODES = {
  notModified: 304,
  forbidden: 403,
  tooManyRequests: 429,
  upgradeRequired: 426,
  notFound: 404,
  notAuthorized: 401,
  badRequest: 400,
  created: 201,
  alreadyExists: 409,
}

export const TRIAL_LIMIT_REASONS = {
  forbiddenFeature: 'forbidden feature',
  requestsExceed: 'requests limit exceed',
  audienceTokensTrialLimit: 'audience tokens trial limit',
}

export const PROTOCOLS = {
  http: 'http://',
  https: 'https://',
}

export const ADD_USER_TO_INFLUDATA_NAME_LENGTH = {
  min: 4,
  max: 35,
}

export const ADD_USER_FOLLOWERS_LIMIT = {
  [USER_INDEXES.instagram]: 2000,
  [USER_INDEXES.tiktok]: 2000,
}

export const ADD_USER_RESPONSE_CODES_MAP = {
  [API_STATUS_CODES.created]: 'created',
  [API_STATUS_CODES.alreadyExists]: 'alreadyExists',
  [API_STATUS_CODES.badRequest]: 'invalid',
}

export const ADD_USER_RESPONSE_STATUS_MAP = Object.values(ADD_USER_RESPONSE_CODES_MAP).reduce(
  (obj, status) => ({ ...obj, [status]: status }),
  {}
)

export const DRAG_EVENT_TYPES = {
  addToCollection: 'drag.addToCollection',
  // add more in future. drag service can handle different types of events
}

export const CLICK_EVENT_MAX_TIME_MS = 200

export const PRICING_PLANS_ELEMENT_ID = 'infludata_pricing'

export const EXPLORE_VIEW_TYPE = {
  suggestions: 'suggestions',
  recentSearches: 'recent_searches',
}

export const TRIAL_COUNTRY_FOR_SIGNUP =  []/*['France', 'Spain', 'Netherlands']*/