import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import ReactLoading from 'react-loading'
import { toast } from 'react-toastify'
import useTranslation from '../../../localization/useTranslation'

import httpService from '../../../services/httpService'
import { MailIcon, CopyIcon } from '../../common/icons'
import { EmailContainer } from '../../common/styledWrappers'
import WithTip from '../../common/popups/WithTip'
import { API_STATUS_CODES } from '../../../constants/appSettings'

const MailWrapper = styled.div`
  ${props => (!props.userHasEmail) && props.theme.disableBlock};
  cursor: ${props => (props.userHasEmail ? 'pointer' : 'not-allowed')};
`

const Wrapper = styled.div`
  position: relative;
  ${props =>
    props.isEmail &&
    `
  .popup-content,
  .email-popup-content {
    width: auto !important;
    left: calc(-100% - 57px) !important;
  }

  .popup-overlay,
  .email-popup-overlay {
    display: none;
  }
`}
`

const ProfileEmailTool = ({
  userHasEmail = false,
  userId = '',
  userindex = '',
  limitAccess,
}) => {
  const [publicEmail, changePublicEmail] = useState('')
  const [isEmailOpen, toggleIsEmailOpen] = useState(false)
  const [forceUpdateKey, changeForceUpdateKey] = useState(true) // for forcing rerenders just toggle bool and use it as component key prop
  const inputRef = useRef()
  const { labelStrings, errors } = useTranslation()
  const onInputChange = ({ target }) => changePublicEmail(target.value)

  const copyToClipboard = text => {
    const dummy = document.createElement('input')
    document.body.appendChild(dummy)
    dummy.setAttribute('value', text)
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
  }

  const onOpenProfileEmail = async e => {
    toggleIsEmailOpen(!isEmailOpen)
    if (!publicEmail && userHasEmail) {
      try {
        const { publicEmail } = await httpService.fetchUserEmail(userId)
        changePublicEmail(publicEmail)
        copyToClipboard(publicEmail)
      } catch (err) {
        changeForceUpdateKey(!forceUpdateKey)
        if (err.response && err.response.status) {
          if (err.response.status === API_STATUS_CODES.tooManyRequests)
            return toast.error(errors.exceedRequestsLimit)
          if (err.response.status === API_STATUS_CODES.upgradeRequired) return limitAccess()
        }
        toast.error(labelStrings.failCopyToClipboard)
      }
    }
  }

  const onCopyProfileEmail = close => e => {
    inputRef.current.select()
    document.execCommand('copy')
    // Select the email link anchor text
    const range = document.createRange()
    range.selectNode(inputRef.current)
    window.getSelection().addRange(range)
    try {
      // Now that we've selected the anchor text, execute the copy command
      const successful = document.execCommand('copy')
      toast.success(labelStrings.copiedToClipboard)
    } catch (err) {
      toast.error(labelStrings.failCopyToClipboard)
    }
    // Remove the selections - NOTE: Should use
    // removeRange(range) when it is supported
    window.getSelection().removeAllRanges()
    toggleIsEmailOpen(!isEmailOpen)
    close()
  }

  const MailIconComponent = (
    <MailWrapper userHasEmail={userHasEmail}>
      <MailIcon userindex={userindex} isActiveIcon={false} />
    </MailWrapper>
  )

  const createTipText = () => {
    if (!userHasEmail) return labelStrings.userHasNoEmail
  }

  return (
    <Wrapper isEmail={isEmailOpen && !!publicEmail} key={forceUpdateKey}>
      {!userHasEmail ? (
        <WithTip tipText={createTipText()} userindex={userindex} position="top center">
          {MailIconComponent}
        </WithTip>
      ) : (
        <Popup
          position="top center"
          disabled={!userHasEmail}
          trigger={
            <div
              data-tip={userHasEmail ? labelStrings.copyEmail : labelStrings.noPublicEmail}
              style={{
                cursor: userHasEmail ? 'auto' : 'not-allowed',
              }}
            >
              {!isEmailOpen && userHasEmail ? (
                <WithTip
                  tipText={labelStrings.copyEmail}
                  userindex={userindex}
                  position="top center"
                >
                  {MailIconComponent}
                </WithTip>
              ) : (
                MailIconComponent
              )}
            </div>
          }
          className={'email-popup'}
          closeOnDocumentClick
          onOpen={onOpenProfileEmail}
          onClose={() => toggleIsEmailOpen(!isEmailOpen)}
        >
          {close =>
            publicEmail ? (
              <EmailContainer>
                <input ref={inputRef} type="text" value={publicEmail} onChange={onInputChange} />
                <span onClick={onCopyProfileEmail(close)}>
                  <CopyIcon />
                </span>
              </EmailContainer>
            ) : (
              <ReactLoading type={'bars'} color={'#eeeeee'} height={36} width={36} />
            )
          }
        </Popup>
      )}
    </Wrapper>
  )
}

ProfileEmailTool.propTypes = {
  userHasEmail: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  userindex: PropTypes.string,
  limitAccess: PropTypes.func,
}

export default ProfileEmailTool
