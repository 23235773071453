import React, { useEffect } from 'react'
import { ModalComponent } from '../../components/modal/ModalComponent'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fields } from './Form/Fields'
import { CustomFieldSchema } from './Form/FormSchema.js'
import useTranslation from '../../../localization/useTranslation'

export const EditField = props => {
  const {
    openEditFieldModal,
    setOpenEditFieldModal,
    editField,
    setEditField,
    onEdit,
    onCreate,
  } = props

  const { labelStrings } = useTranslation()

  const methods = useForm({
    resolver: yupResolver(CustomFieldSchema),
    defaultValues: {
      dataFormat: '',
      customFieldName: '',
      metricName: '',
      applyLevel: '',
      applyContentArray: '',
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods

  const handleClose = () => {
    reset({
      dataFormat: '',
      customFieldName: '',
      metricName: '',
      applyLevel: '',
      applyContentArray: '',
    })
    setOpenEditFieldModal(false)
    setEditField(null)
  }

  const onSubmit = async data => {
    const action = editField?.customFieldId ? onEdit : onCreate
    try {
      await new Promise(resolve => setTimeout(resolve, 500))
      action({
        ...data,
        customFieldId: editField?.customFieldId,
      })
      handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    reset(editField)
  }, [editField])

  return (
    <ModalComponent
      isOpenModal={openEditFieldModal}
      closeModal={handleClose}
      title={`${editField?.customFieldId ? labelStrings.edit : labelStrings.createBtnText} ${
        labelStrings.aCustomField
      }`}
      contentWidth="789px"
    >
      <Fields
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        methods={methods}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
      />
    </ModalComponent>
  )
}
