import React, { useEffect, useState } from "react";
import useTranslation from "../../../../../localization/useTranslation";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";

export const GenderFilter = (props) => {
  const { query, handlersMap, isModalOpen, closeModal } = props || {};

  const { labelStrings, genderOptions } = useTranslation();

  const [open, setOpen] = useState(false);

  const onChange = (event) => {
    const {
      target: { value },
    } = event || {};
    handlersMap?.gender(value);
  };

  const handleClose = () => {
    setOpen(false);
    closeModal && closeModal(null);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isModalOpen) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, 200);

    return () => clearTimeout(timer);
  }, [isModalOpen]);

  return (
    <Box>
      <FormControl
        sx={{
          width: 250,
          "& .MuiOutlinedInput-root": {
            outline: "unset",
            "& fieldset": {
              borderColor: "#BC9797",
            },
            "&:hover fieldset": {
              borderColor: "#784E4E",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#784E4E",
            },
          },
        }}
      >
        <InputLabel
          sx={{
            color: "#784E4E",
            fontWeight: "bold",
            "&.Mui-focused": {
              color: "#784E4E",
            },
          }}
          size='small'
        >
          {labelStrings.selectGender}
        </InputLabel>
        <Select
          size='small'
          open={open}
          onClose={handleClose}
          onOpen={() => setOpen(true)}
          value={query.gender ? query.gender : ""}
          onChange={onChange}
          input={<OutlinedInput label={labelStrings.selectGender} />}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
                color: "#442424",
              },
            },
          }}
        >
          {genderOptions?.map((el) => (
            <MenuItem
              key={el?.value}
              value={el?.value}
              sx={{ fontSize: "12px", fontWeight: 700, fontFamily: "Inter" }}
            >
              {el?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
