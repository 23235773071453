import React from "react";
import { Chip, Typography, Stack } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import useTranslation from "../../../../localization/useTranslation";

export const BuildLookalikeBasedOnBrandAccount = (props) => {
  const { currentQuery, setOpenModalLookalikeAi } =
    props || {};
  const { labelStrings } = useTranslation();

  const brandAccountValue = currentQuery["brandMentions"];
  const toArrayBrandAccountValues = brandAccountValue?.split(",")?.slice(0, 1);
  const mappedBrandAccountValues = toArrayBrandAccountValues?.join();

  const onAddLookalikesMentions = () => {
    setOpenModalLookalikeAi(true);
  };

  return (
    <Chip
      size='small'
      sx={{
        backgroundColor: "#FFFFFF",
        width: "fit-content",
      }}
      label={
        <Stack
          direction='row'
          alignItems='center'
          spacing={0.5}
          sx={{ p: "2px" }}
        >
          <Typography
            sx={{
              color: "#442424",
              fontWeight: 700,
              fontSize: "13px",
              lineHeight: "13px",
              fontFamily: "Inter",
            }}
          >
            {`${labelStrings.buildLookalikesBasedOnBrandAccount}: @${mappedBrandAccountValues}`}
          </Typography>
          <AddRoundedIcon
            sx={{
              fill: "#442424",
              width: "15px",
              height: "15px",
              ".MuiChip-deleteIcon": {
                margin: "0 8px 0 -8px",
              },
            }}
          />
        </Stack>
      }
      onClick={onAddLookalikesMentions}
    />
  );
};
