// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  // const isLight = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#F6DBDB',
          color: '#442424',
          textAlign: 'center',
          fontFamily: 'Inter',
          boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)`
        },
        arrow: {
          color: '#F6DBDB',
        },
      },
    },
  };
}
