import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, useTheme } from '@mui/material'
import { PublishedContentCarousel } from '../../components/carousel/published-content'
import { pretifyBigNum } from '../../../utils'
import { DownloadContentModal } from '../content-overview/DownloadContentModal'

export const LatestPublishedContentAndReach = ({ title = '', subheader = '', data = [], currency = '' }) => {
  const theme = useTheme()

  const [currentCreatorsData, setCurrentCreatorsData] = useState({})

  const listData = data?.array?.map(el => {
    return {
      id: el.contentId,
      name: el.username,
      avatar: el.profilePicUrl,
      dateTimeAt: el.uploaded,
      likes: el.likes,
      comments: el.comments,
      commentSummary: el.commentSummary,
      estReach: el.reach,
      value: `${pretifyBigNum(el.valueFrom)} - ${pretifyBigNum(el.valueTo)} ${currency}`,
      reachedTargetGroup: '-',
      found: el.found,
      contentImg: el.imageUrl,
      contentVideo: el.videoUrl,
      platform: el.platform,
      contentVideoPreview: el.imageUrl,
      contentType: el.contentType,
      contentLink: el.platformLink,
      commentPositivityRate: el.commentPositivityRate,
      controversialCommentRate: el.controversialCommentRate,
      videoUrls: el.videoUrls,
      imageUrls: el.imageUrls,
      engagementRate: el.engagementRate,
      label: el.label,
      shares: el.shares,
      saves: el.saves,
      platformLink: el.platformLink,
      ad_media: el.ad_media,
      errorBubbles: el.errorBubbles
    }
  })

  const carouselSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <PublishedContentCarousel
            sx={{ py: 3 }}
            title={title}
            subheader={subheader}
            list={listData}
            carouselSettings={carouselSettings}
            setCurrentCreatorsData={setCurrentCreatorsData}
          />
        </Grid>
      </Grid>
      <DownloadContentModal
        isOpened={!!currentCreatorsData?.id}
        setOpenedDownloadModal={setCurrentCreatorsData}
        currentCreatorsData={currentCreatorsData}
      />
    </>
  )
}

LatestPublishedContentAndReach.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
}