import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  isSignUpOpenedSelector,
  isSignInOpenedSelector,
  userCredsLoadProgressSelector,
  limitsExistSelector,
  isSignUpSuccessModalOpenedSelector,
  campaignDashboard,
  userTrackSelector,
  userCredsSelector,
  isUserAuthenticatedSelector,
  userGrantSelector,
} from '../redux/selectors'
import withPageMeta from '../hocs/withPageMeta'

import SignUpContainer from '../components/signup/SignUpContainer'
import SignUpSuccess from '../components/signup/SignUpSuccess'

import Footer from '../components/Footer'
import { PageContentWrapper } from '../components/common/styledWrappers'
import LoadingSpinner from '../components/common/spinners/LoadingSpinner'

import LoginContainer from '../components/signin/LoginContainer'
import LimitsContainer from '../components/limits/LimitsContainer'

import { ModalContentNews } from '../new-ui/components/modal-content-news'
import useWindowResize from '../hooks/useWindowResize'
import NavbarContainer from '../new-ui/components/navbar/NavbarContainer'
import { TRIAL_COUNTRY_FOR_SIGNUP } from '../constants/appSettings'
import SignUpTrialContainer from '../components/signup/SignUpTrialContainer'
import { NoPlanModal } from '../new-ui/components/no-plan-modal/NoPlanModal'
import { PlanInactiveModal } from '../new-ui/components/no-plan-modal/PlanInactiveModal'
import Scrollbar from '../new-ui/components/scrollbar/Scrollbar'

const PageContainer = ({ isNavbar = true, isFooter = true, children }) => {
  const { pathname } = useLocation()
  const isPageLoading = useSelector(userCredsLoadProgressSelector)
  const isSignupOpened = useSelector(isSignUpOpenedSelector)
  const isSignInOpened = useSelector(isSignInOpenedSelector)
  const isSignUpSuccessModalOpened = useSelector(isSignUpSuccessModalOpenedSelector)
  const isSomeLimitActivated = useSelector(limitsExistSelector)
  const isUserTrack = useSelector(userTrackSelector)
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const { showNoPlanModal } = useSelector(userCredsSelector) || {}
  const { is_self_service } = useSelector(userGrantSelector, shallowEqual)

  const { isMobile } = useWindowResize()

  const currentCountryFromIP = localStorage.getItem('currentCountryFromIP')
  const isSevenDaysTrial = TRIAL_COUNTRY_FOR_SIGNUP?.includes(currentCountryFromIP)

  const isPreviewDashboardPage = pathname === '/dashboardPreview'

  const { data: dashboardStartupData, isLoading: isLoadingDashboard } = useSelector(
    campaignDashboard
  )

  const planModal = is_self_service ? <NoPlanModal /> : <PlanInactiveModal />

  return (
    <>
      {isPageLoading && <LoadingSpinner isTransparent showOnFullPage isOuterPageLoad />}
      {isPreviewDashboardPage ? (
        <PageContentWrapper isLoading={isPageLoading} id='main-page-container'>{children}</PageContentWrapper>
      ) : (
        <PageContentWrapper isLoading={isPageLoading} id='main-page-container'>
          {isNavbar && <NavbarContainer />}
          {isSignupOpened && (isSevenDaysTrial ? <SignUpTrialContainer isSevenDaysTrial={isSevenDaysTrial} /> : <SignUpContainer />)}
          {isSignInOpened && <LoginContainer />}
          {isSomeLimitActivated && <LimitsContainer />}
          {(showNoPlanModal && isUserAuthenticated) && planModal}
          {isUserTrack
            ? isSignUpSuccessModalOpened &&
              dashboardStartupData?.length &&
              !isLoadingDashboard && <SignUpSuccess />
            : isSignUpSuccessModalOpened && <SignUpSuccess />}
          {pathname === '/settings' ? (
            <>{children}</>
          ) : pathname === '/new-campaigns/' ? (
            <>
              {children}
              {isFooter && <Footer />}
            </>
          ) : (
            <Scrollbar pathname={pathname} sx={{ maxHeight: `calc(100vh - ${64}px)` }}>
              {children}
              {isFooter && <Footer />}
            </Scrollbar>
          )}
        </PageContentWrapper>
      )}
      {!isMobile && <ModalContentNews />}
    </>
  )
}

PageContainer.propTypes = {
  isNavbar: PropTypes.bool,
  isFooter: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default withPageMeta(PageContainer)
