import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  Stack,
  Typography,
  List,
  ListItemButton,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import useTranslation from "../../../../localization/useTranslation";
import { userGrantSelector } from "../../../../redux/selectors";
import { BuildYourListAI } from "../../Explore/Sidebar/BuildYourListAI";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { NavbarLookaLikeIcon } from "./icons";
import { checkDateIsLessThan24HoursBefore } from "../../../../utils";

export const Suggestions = (props) => {
  const {
    data,
    platform,
    onOpenModalLookalikeAi,
    onOpenSuggestions,
    onDeleteSuggestionList,
  } = props || {};
  const { labelStrings } = useTranslation();

  const { build_lookalikes } = useSelector(userGrantSelector, shallowEqual);

  return (
    <List sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <BuildYourListAI
          build_lookalikes={build_lookalikes}
          onOpenModalLookalikeAi={onOpenModalLookalikeAi}
          userIndex={platform}
        />
      </Box>
      {data?.map((el) => {
        const isNotUpdatedLookalike =
          el.reference === "lookalike" &&
          (el.updated === null || el?.updated === "null");
        const isLessThan24Hours = checkDateIsLessThan24HoursBefore(el.updated)
        return (
          <ListItemButton
            size='small'
            key={el.id}
            onClick={() => onOpenSuggestions(el)}
            sx={{
              borderRadius: "10px",
              px: 1,
              backgroundColor: isLessThan24Hours ? '#FFF5F5' : '#fff',
              "& hr": {
                border: `1px solid ${isLessThan24Hours ? '#fff' : '#FFF1F1'} !important`,
              },
              mb: '6px',
              "&:hover": {
                backgroundColor: "#FFF5F5",
                "& hr": {
                  border: "1px solid transparent !important",
                },
              },
              "&:last-child": { "& hr": { border: "unset !important" }, mb: 0 },
            }}
          >
            <Stack sx={{ width: "100%" }}>
              <Stack
                direction='row'
                alignItems='center'
                sx={{ width: "100%" }}
                spacing={1}
              >
                <Box>
                  <NavbarLookaLikeIcon />
                </Box>
                <Stack sx={{ width: "100%" }}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Typography
                      noWrap
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        color: "#C79C9C",
                        fontWeight: 400,
                      }}
                    >
                      {isNotUpdatedLookalike ? (
                        <>{labelStrings.buildLookalike.inProgress}</>
                      ) : (
                        <>
                          {el.reference === "lookalike" ? (
                            <>
                              {`${labelStrings.explorePage.updatedOn} ${
                                el?.updated
                                  ? format(parseISO(el?.updated), "MMM dd")
                                  : ""
                              }`}
                            </>
                          ) : (
                            <>
                              {el.type === "similar"
                                ? labelStrings.explorePage.similarAccounts
                                : `${labelStrings.explorePage.newlyAddedTo} INFLUDATA`}
                              -{" "}
                              {`${labelStrings.explorePage.updatedOn} ${
                                data?.updated
                                  ? format(parseISO(el?.updated), "MMM dd")
                                  : ""
                              }`}
                            </>
                          )}
                        </>
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Stack direction='row' spacing={0.5}>
                      <Typography
                        noWrap
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          color: "#442424",
                          fontWeight: 700,
                          textTransform: "capitalize",
                        }}
                      >
                        {`${el?.reference}:`}
                      </Typography>
                      <Typography
                        noWrap
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          color: "#442424",
                          fontWeight: 700,
                          maxWidth: "450px",
                        }}
                      >
                        {el.formattedString}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        color: isNotUpdatedLookalike ? "#8A9A5B" : "#228B22",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      {labelStrings.status}:{" "}
                      {isNotUpdatedLookalike
                        ? labelStrings.buildLookalike.inProgress
                        : labelStrings.buildLookalike.finished}
                    </Typography>
                  </Stack>
                </Stack>
                <IconButton onClick={(e) => onDeleteSuggestionList(e, el)}>
                  <DeleteRoundedIcon sx={{ color: "#442424" }} />
                </IconButton>
              </Stack>
              <Divider
                sx={{ width: "100%", border: "1px solid #FFF1F1", mt: "4px" }}
              />
            </Stack>
          </ListItemButton>
        );
      })}
    </List>
  );
};
