import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Box, Typography, Divider } from '@mui/material'
import useTranslation from '../../../localization/useTranslation'
import ProgressBar from '../../common/progressBar'
import { formatDateToDDMMYYY } from '../../../utils'

const StatSection = ({
  title,
  current,
  limit,
  labelStrings,
  currentRemaining,
  availableRemaining,
}) => (
  <Stack>
    <Typography sx={styles.title}>{title}</Typography>
    {limit === -1 ? (
      <Typography
        sx={{
          ...styles.subtitle,
          fontSize: '14px',
          fontWeight: 'bold',
          background:
            'linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Plan: {labelStrings.unlimited}
      </Typography>
    ) : (
      <Stack>
        <ProgressBar current={2000} limit={limit} />
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography
            sx={{
              ...styles.subtitle,
              fontWeight: 700,
              color: '#FF5500',
              fontSize: '13px',
              textTransform: 'capitalize',
            }}
          >
            {`${currentRemaining} ${labelStrings.remaining}`}
          </Typography>
          <Typography
            sx={{
              ...styles.subtitle,
              fontWeight: 400,
              color: '#A77D7D',
              fontSize: '12px',
              textTransform: 'lowercase',
            }}
          >
            {`(${labelStrings.of} ${availableRemaining})`}
          </Typography>
        </Stack>
      </Stack>
    )}
  </Stack>
)
const TokensRemainingStat = ({
  audienceTokensTotal = 20,
  searchProfileRequestTokensTotal = 0,
  profileAnalyticsTokensTotal = 0,

  audienceTokensAvailable = 0,
  searchResultsTokensAvailable = 0,
  profileAnalyticsTokensAvailable = 0,

  TrialComponent = null,
  analyticsNextResetDate = '',
  isUnlimitedAudienceReports = false,
}) => {
  const { labelStrings } = useTranslation()
  return (
    <Box sx={styles.container}>
      <Stack>
        <Stack>
          <Box sx={{ px: 2, py: 1 }}>
            <StatSection
              title={labelStrings.audienceReportTokens}
              current={audienceTokensAvailable}
              limit={isUnlimitedAudienceReports ? -1 : audienceTokensTotal}
              currentRemaining={audienceTokensAvailable}
              availableRemaining={audienceTokensTotal}
              labelStrings={labelStrings}
            />
          </Box>
          <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />
        </Stack>
        <Stack>
          <Box sx={{ px: 2, py: 1 }}>
            <StatSection
              title={labelStrings.searchResultsLimit}
              current={searchResultsTokensAvailable}
              limit={searchProfileRequestTokensTotal}
              currentRemaining={audienceTokensAvailable}
              availableRemaining={audienceTokensTotal}
              labelStrings={labelStrings}
            />
          </Box>
          <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />
        </Stack>
        <Stack>
          <Box sx={{ px: 2, py: 1 }}>
            <StatSection
              title={labelStrings.profileAnalyticsLimit}
              current={profileAnalyticsTokensAvailable}
              limit={profileAnalyticsTokensTotal}
              currentRemaining={audienceTokensAvailable}
              availableRemaining={audienceTokensTotal}
              labelStrings={labelStrings}
            />
          </Box>
          <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />
        </Stack>
      </Stack>

      {analyticsNextResetDate && (
        <Box sx={{ display: 'flex', justifyItems: 'alignItems', py: 1.5, px: 2 }}>
          <Typography sx={styles.resetDate}>
            {labelStrings.tokensWillBeAddedOnAnalytics}{' '}
            {formatDateToDDMMYYY({
              dateStr: new Date(analyticsNextResetDate).toString(),
            })}
          </Typography>
        </Box>
      )}

      {TrialComponent && <Box sx={styles.trialComponent}>{TrialComponent}</Box>}
    </Box>
  )
}

const styles = {
  container: {
    minHeight: '80px',
    pt: 1,
    color: '#442424',
  },
  title: {
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    letterSpacing: '0.5px',
    color: '#442424',
    lineHeight: 'initial',
  },
  subtitle: {
    fontSize: '13px',
    fontFamily: 'Inter',
    fontWeight: 600,
    textTransform: 'capitalize',
    letterSpacing: '0.5px',
    color: '#FF5500',
  },
  resetDate: {
    textTransform: 'initial',
    width: '100%',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
  },
  trialComponent: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Inter',
    textTransform: 'none',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: '10px 5px 0 5px',
    '& .click_trial_activate': {
      fontWeight: 600,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}

TokensRemainingStat.propTypes = {
  audienceTokensTotal: PropTypes.number,
  profileAnalyticsTokensTotal: PropTypes.number,
  searchProfileRequestTokensTotal: PropTypes.number,

  audienceTokensAvailable: PropTypes.number,
  profileAnalyticsTokensAvailable: PropTypes.number,
  searchResultsTokensAvailable: PropTypes.number,

  analyticsNextResetDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isUnlimitedAudienceReports: PropTypes.bool,
  TrialComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(null)]),
}

export default TokensRemainingStat
