import React, { useEffect, useMemo, useRef, useState } from "react"
import { shallowEqual, useSelector } from "react-redux"
import PropTypes from "prop-types"
import {
  Box,
  Stack,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  IconButton,
} from "@mui/material"
import {
  currentSearchQuerySelector,
  searchResultsSelector,
  userGrantSelector,
} from "../../redux/selectors"
import useTranslation from "../../localization/useTranslation"
import queryString from "query-string"

import { SORTING_DIRECTIONS } from "../../constants/sortings"
import WithUpgradeTip from "../common/popups/WithUpgradeTip"

const MAX_LIMIT_COUNT_DOWNLOAD_SEARCH_RESULTS = 500

import {
  SortContainer,
  SortInnerWrapper,
  SortByLabel,
  SortLabelSelectWrapper,
} from "../common/styledWrappers/campaignUsersSortingStyles"

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded"
import routes from "../../routes"
import { format } from "date-fns"
import { LoadingButton } from "@mui/lab"
import { CSVLink } from "react-csv"
import { SearchAscDescSvgIcon } from "../../new-ui/components/search-sidebar/icons"

const SortingCommonTool = ({
  sortToolId,
  sortingOptions,
  changeSortingHandler,
  currentSortingValue,
  changeSortingDirectionHandler,
  currentSortingDirection,
  socialPlatformIndex = "",
  isDisabled,
  notTip,
  isFullWidth,
  isContent,
  isDownloadSearchResults,
  // noDisabledTip
}) => {
  const { labelStrings } = useTranslation()

  const { download_results } = useSelector(userGrantSelector, shallowEqual)
  const { count: searchResultsCount } =
    useSelector(searchResultsSelector, shallowEqual) || {}
  const currentQuery = useSelector(currentSearchQuerySelector, shallowEqual)

  const [isLoadingCSV, setIsLoadingCSV] = useState(false)
  const [csvData, setCsvData] = useState({
    header: [],
    data: [],
  })

  const csvLinkRef = useRef()

  const currDate = new Date()
  const formattedDate = format(currDate, "dd-MM-yyyy HH:mm:ss")

  const TipWrapper = ({ children }) => {
    if (notTip) return children
    return isDisabled ? (
      <WithUpgradeTip
        userindex={socialPlatformIndex}
        tipText={labelStrings.useSorting}
      >
        {children}
      </WithUpgradeTip>
    ) : (
      <>{children}</>
    )
  }

  // const isActiveDesc = currentSortingDirection === SORTING_DIRECTIONS.descend
  // const isActiveAsc = currentSortingDirection === SORTING_DIRECTIONS.ascend

  const isPossibleDownloadSearchResults =
    searchResultsCount <= MAX_LIMIT_COUNT_DOWNLOAD_SEARCH_RESULTS

  const downloadSearchResultsAsCsvTipText = useMemo(() => {
    if (!isPossibleDownloadSearchResults && download_results) {
      return labelStrings.downloadResultsAsCSVNotPossible
    }
    if (download_results && isPossibleDownloadSearchResults) {
      return labelStrings.downloadResultsAsCSV
    }
    return labelStrings.featureIsNotAvailable
  }, [isPossibleDownloadSearchResults, download_results])

  const onDownloadCSVSearchRes = async () => {
    setIsLoadingCSV(true)
    setCsvData({ data: [], header: [] })
    try {
      const stringifiedQuery = queryString.stringify(currentQuery)

      const csvLink = `${routes.collab.api.getCSVDataFromSearch}?${stringifiedQuery}`
      const response = await fetch(csvLink)
      const { data, header } = await response.json()
      if (data?.length || header?.length) {
        setCsvData({ data, header })
      }
    } catch (error) {
      console.error("Error downloading CSV:", error)
    } finally {
      setIsLoadingCSV(false)
    }
  }

  const onChangeSortDirection = () => {
    changeSortingDirectionHandler(
      currentSortingDirection === SORTING_DIRECTIONS.descend
        ? SORTING_DIRECTIONS.ascend
        : SORTING_DIRECTIONS.descend,
    )
  }

  useEffect(() => {
    if (
      csvData.header?.length > 0 &&
      csvLinkRef.current &&
      csvLinkRef.current.link &&
      !isLoadingCSV
    ) {
      setTimeout(() => {
        csvLinkRef.current.link.dataset.interception = "off"
        csvLinkRef.current.link.click()
      })
    }
  }, [csvData.header, isLoadingCSV])

  return (
    <SortContainer
      className='sorting_main_container'
      isFullWidth={isFullWidth}
      isContent={isContent}
    >
      <TipWrapper>
        <SortInnerWrapper className='sorting_elements_container'>
          <SortLabelSelectWrapper>
            <SortByLabel
              isDisabled={isDisabled}
              className='sorting_label_container'
            >
              {labelStrings.sortingBy}
            </SortByLabel>
            <FormControl
              sx={{
                width: "164px",
                "& .MuiOutlinedInput-root": {
                  outline: "unset",
                  "& fieldset": {
                    borderColor: "#BC9797",
                  },
                  "&:hover fieldset": {
                    borderColor: "#784E4E",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#784E4E",
                  },
                },
              }}
              size='small'
            >
              <Select
                size='small'
                disabled={isDisabled}
                id={sortToolId}
                value={currentSortingValue?.value}
                onChange={changeSortingHandler}
                sx={{
                  fontSize: "14px",
                  fontFamily: "Inter",
                  color: "#784E4E",
                  maxHeight: "27px",
                  "& svg": {
                    fill: "#784E4E",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      fontSize: "14px",
                      fontFamily: "Inter",
                      color: "#784E4E",
                    },
                  },
                }}
              >
                {sortingOptions?.map((el) => (
                  <MenuItem key={el.value} value={el.value}>{el.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </SortLabelSelectWrapper>

          {!isContent && (
            <IconButton disabled={isDisabled} onClick={onChangeSortDirection}>
              <SearchAscDescSvgIcon />
            </IconButton>
          )}
          <Stack
            sx={{ height: "35px", ml: 1 }}
            direction='row'
            alignItems='center'
          >
            <Tooltip title={downloadSearchResultsAsCsvTipText}>
              <Box>
                <LoadingButton
                  loading={isLoadingCSV}
                  onClick={onDownloadCSVSearchRes}
                  disabled={
                    !download_results
                  }
                  sx={{ minWidth: "40px" }}
                >
                  {!isLoadingCSV && (
                    <DownloadRoundedIcon
                      sx={{
                        fill:
                          download_results
                            ? "#784E4E"
                            : "#637381",
                        opacity:
                          download_results
                            ? 1
                            : 0.5,
                      }}
                    />
                  )}
                </LoadingButton>

                {!!csvData?.header?.length && (
                  <CSVLink
                    asyncOnClick
                    ref={csvLinkRef}
                    data={csvData?.data}
                    headers={csvData?.header}
                    target='_blank'
                    filename={`search-results-${formattedDate}.csv`}
                  />
                )}
              </Box>
            </Tooltip>
          </Stack>
        </SortInnerWrapper>
      </TipWrapper>
    </SortContainer>
  )
}

SortingCommonTool.propTypes = {
  sortToolId: PropTypes.string.isRequired,
  sortingOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ).isRequired,
  changeSortingHandler: PropTypes.func,
  currentSortingValue: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  changeSortingDirectionHandler: PropTypes.func,
  currentSortingDirection: PropTypes.string.isRequired,
  socialPlatformIndex: PropTypes.string,
  isDisabled: PropTypes.bool,
  notTip: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isContent: PropTypes.bool,
  isDownloadSearchResults: PropTypes.bool,
}

export default SortingCommonTool
