import LocalizedStrings from 'react-localization'
import availableLangs from './availableLangs'
import localizedLanguages from './localizedLanguages'
import localizedGenders from './localizedGenders'
import localizedCategories from './localizedCategories'
import localizedAdditionalFieldTypes from './localizedAdditionalFieldTypes'
import localizedAudienceGenderOptions from './localizedAudienceGenderOptions'
import countriesFlags from './countriesFlags'
import localizedLabelStrings from './localizedLabelStrings'
import localizedErrorMessages from './localizedErrorMessages'
import localizedCountries from './localizedCountries'
import localizedPricingPlans from './localizedPricingPlans'
import localizedScoresStrings from './localizedScoreStrings'
import localizedMonths from './localizedMonths'
import localizedProfileSectionDescs from './localizedProfileSectionDescs'

export const localizations = new LocalizedStrings({
  [availableLangs.en]: {
    languages: localizedLanguages.en,
    gender: localizedGenders.en,
    categories: localizedCategories.en,
    additionalFieldTypes: localizedAdditionalFieldTypes.en,
    audienceGenderOptions: localizedAudienceGenderOptions.en,
    flags: countriesFlags.en,
    labelStrings: localizedLabelStrings.en,
    errors: localizedErrorMessages.en,
    countries: localizedCountries.en,
    pricingPlans: localizedPricingPlans.en,
    scoreStrings: localizedScoresStrings.en,
    months: localizedMonths.en,
    profileSectionDescs: localizedProfileSectionDescs.en,
  },
  [availableLangs.de]: {
    languages: localizedLanguages.de,
    gender: localizedGenders.de,
    categories: localizedCategories.de,
    additionalFieldTypes: localizedAdditionalFieldTypes.de,
    audienceGenderOptions: localizedAudienceGenderOptions.de,
    flags: countriesFlags.en,
    labelStrings: localizedLabelStrings.de,
    errors: localizedErrorMessages.de,
    countries: localizedCountries.de,
    pricingPlans: localizedPricingPlans.de,
    scoreStrings: localizedScoresStrings.de,
    months: localizedMonths.de,
    profileSectionDescs: localizedProfileSectionDescs.de,
  },
})

export const languageOptions = lang => [
  { value: '', label: localizedLanguages[lang].all },
  { value: 'de', label: localizedLanguages[lang].german },
  { value: 'en', label: localizedLanguages[lang].english },
  { value: 'es', label: localizedLanguages[lang].spanish },
  { value: 'fr', label: localizedLanguages[lang].french },
  { value: 'pt', label: localizedLanguages[lang].portuguese },
  { value: 'iw', label: localizedLanguages[lang].hebrew },
  { value: 'it', label: localizedLanguages[lang].italian },
  { value: 'da', label: localizedLanguages[lang].danish },
  { value: 'ru', label: localizedLanguages[lang].russian },
  { value: 'lv', label: localizedLanguages[lang].latvian },
  { value: 'cz', label: localizedLanguages[lang].czech },
  { value: 'pl', label: localizedLanguages[lang].polish },
  { value: 'sa', label: localizedLanguages[lang].arabic },
  { value: 'bg', label: localizedLanguages[lang].bulgarian },
  { value: 'nl', label: localizedLanguages[lang].dutch },
  { value: 'fi', label: localizedLanguages[lang].finnish },
  { value: 'ja', label: localizedLanguages[lang].japanese },
  { value: 'hu', label: localizedLanguages[lang].hungarian },
  { value: 'no', label: localizedLanguages[lang].norwegian },
  { value: 'el', label: localizedLanguages[lang].greek },
  { value: 'ro', label: localizedLanguages[lang].romanian },
  { value: 'tr', label: localizedLanguages[lang].turkish },
  { value: 'th', label: localizedLanguages[lang].thai },
  { value: 'zh', label: localizedLanguages[lang].chinese },
  { value: 'hi', label: localizedLanguages[lang].hindi },
  { value: 'ko', label: localizedLanguages[lang].korean },
  { value: 'vn', label: localizedLanguages[lang].vietnamese },
  { value: 'se', label: localizedLanguages[lang].swedish },
  { value: 'id', label: localizedLanguages[lang].indonesian },
  { value: 'ms', label: localizedLanguages[lang].malay },
  { value: 'fa', label: localizedLanguages[lang].persian },
  { value: 'sw', label: localizedLanguages[lang].swahili },
  { value: 'uk', label: localizedLanguages[lang].ukrainian },
  { value: 'rs', label: localizedLanguages[lang].serbian },
  { value: 'bn', label: localizedLanguages[lang].bengali },
  { value: 'pa', label: localizedLanguages[lang].punjabi },
  { value: 'te', label: localizedLanguages[lang].telugu },
  { value: 'mr', label: localizedLanguages[lang].marathi },
  { value: 'ta', label: localizedLanguages[lang].tamil },
  { value: 'ur', label: localizedLanguages[lang].urdu },
  { value: 'gu', label: localizedLanguages[lang].gujarati },
  { value: 'ml', label: localizedLanguages[lang].malayalam },
]

export const genderOptions = lang => [
  { value: '', label: localizedGenders[lang].all },
  { value: 'w', label: localizedGenders[lang].female },
  { value: 'm', label: localizedGenders[lang].male },
]

export const categoryOptions = lang => [
  { value: 'Fitness', label: localizedCategories[lang].fitness },
  { value: 'Vegan', label: localizedCategories[lang].vegan },
  { value: 'Mother', label: localizedCategories[lang].mother },
  { value: 'Father', label: localizedCategories[lang].father },
  { value: 'Family', label: localizedCategories[lang].family },
  { value: 'Healthy Food', label: localizedCategories[lang].healthyFood },
  { value: 'Diet', label: localizedCategories[lang].diet },
  { value: 'Vegetarian', label: localizedCategories[lang].vegetarian },
  { value: 'Photography', label: localizedCategories[lang].photography },
  { value: 'Travel', label: localizedCategories[lang].travel },
  { value: 'Dogs', label: localizedCategories[lang].dogs },
  { value: 'Cats', label: localizedCategories[lang].cats },
]

export const countryOptions = lang => [
  //{ value: '', label: localizedCountries[lang].all, isoCode: 'all' },
  { value: 'Germany', label: localizedCountries[lang].germany, isoCode: 'de' },
  { value: 'Austria', label: localizedCountries[lang].austria, isoCode: 'at' },
  { value: 'Switzerland', label: localizedCountries[lang].switzerland, isoCode: 'ch' },
  { value: 'Italy', label: localizedCountries[lang].italy, isoCode: 'it' },
  { value: 'Spain', label: localizedCountries[lang].spain, isoCode: 'es' },
  { value: 'United Kingdom', label: localizedCountries[lang].unitedKingdom, isoCode: 'gb' },
  { value: 'United States of America', label: localizedCountries[lang].usa, isoCode: 'us' },
  { value: 'Albania', label: localizedCountries[lang].albania, isoCode: 'al' },
  { value: 'Algeria', label: localizedCountries[lang].algeria, isoCode: 'dz' },
  { value: 'Puerto Rico', label: localizedCountries[lang].puertoRico, isoCode: 'pr'},
  //{ value: 'Andorra', label: localizedCountries[lang].Andorra },
  //{ value: 'Angola', label: localizedCountries[lang].Angola },
  //{ value: 'Antigua and Barbuda', label: localizedCountries[lang].Antigua and Barbuda },
  { value: 'Argentina', label: localizedCountries[lang].argentina, isoCode: 'ar' },
  { value: 'Armenia', label: localizedCountries[lang].armenia, isoCode: 'am' },
  //{ value: 'Aruba', label: localizedCountries[lang].Aruba },
  { value: 'Australia', label: localizedCountries[lang].australia, isoCode: 'au' },
  //{ value: 'Azerbaijan', label: localizedCountries[lang].Azerbaijan },
  //{ value: 'Bahamas', label: localizedCountries[lang].Bahamas },
  { value: 'Bahrain', label: localizedCountries[lang].bahrain, isoCode: 'bh' },
  //{ value: 'Bangladesh', label: localizedCountries[lang].Bangladesh },
  //{ value: 'Barbados', label: localizedCountries[lang].Barbados },
  { value: 'Belarus', label: localizedCountries[lang].belarus, isoCode: 'by' },
  { value: 'Belgium', label: localizedCountries[lang].belgium, isoCode: 'be' },
  //{ value: 'Belize', label: localizedCountries[lang].Belize },
  //{ value: 'Benin', label: localizedCountries[lang].Benin },
  //{ value: 'Bhutan', label: localizedCountries[lang].Bhutan },
  { value: 'Bolivia', label: localizedCountries[lang].bolivia, isoCode: 'bo' },
  {
    value: 'Bosnia and Herzegovina',
    label: localizedCountries[lang].bosniaHerzegovina,
    isoCode: 'ba',
  },
  //{ value: 'Botswana', label: localizedCountries[lang].Botswana },
  { value: 'Brazil', label: localizedCountries[lang].brazil, isoCode: 'br' },
  //{ value: 'Brunei', label: localizedCountries[lang].Brunei },
  { value: 'Bulgaria', label: localizedCountries[lang].bulgaria, isoCode: 'bg' },
  //{ value: 'Burkina Faso', label: localizedCountries[lang].Burkina Faso },
  //{ value: 'Burma', label: localizedCountries[lang].Burma },
  //{ value: 'Burundi', label: localizedCountries[lang].Burundi },
  //{ value: 'Cambodia', label: localizedCountries[lang].Cambodia },
  //{ value: 'Cameroon', label: localizedCountries[lang].Cameroon },
  { value: 'Canada', label: localizedCountries[lang].canada, isoCode: 'ca' },
  //{ value: 'Cabo Verde', label: localizedCountries[lang].Cabo Verde },
  //{ value: 'Central African Republic', label: localizedCountries[lang].Central African Republic },
  //{ value: 'Chad', label: localizedCountries[lang].Chad },
  { value: 'Chile', label: localizedCountries[lang].chile, isoCode: 'cl' },
  //{ value: 'China', label: localizedCountries[lang].China },
  { value: 'Colombia', label: localizedCountries[lang].colombia, isoCode: 'co' },
  //{ value: 'Comoros', label: localizedCountries[lang].Comoros },
  //{ value: 'Congo', label: localizedCountries[lang].Congo },
  { value: 'Costa Rica', label: localizedCountries[lang].costaRica, isoCode: 'cr' },
  //{ value: 'Cote d\' Ivoire', label: localizedCountries[lang].Cote d\' Ivoire },
  { value: 'Croatia', label: localizedCountries[lang].croatia, isoCode: 'hr' },
  { value: 'Cuba', label: localizedCountries[lang].cuba, isoCode: 'cu' },
  //{ value: 'Curacao', label: localizedCountries[lang].Curacao },
  { value: 'Cyprus', label: localizedCountries[lang].cyprus, isoCode: 'cy' },
  { value: 'Czech Republic', label: localizedCountries[lang].czechRepublic, isoCode: 'cz' },
  { value: 'Denmark', label: localizedCountries[lang].denmark, isoCode: 'dk' },
  //{ value: 'Djibouti', label: localizedCountries[lang].Djibouti },
  //{ value: 'Dominica', label: localizedCountries[lang].Dominica },
  { value: 'Dominican Republic', label: localizedCountries[lang].dominicanRepublic, isoCode: 'do' },
  //{ value: 'East Timor', label: localizedCountries[lang].East Timor },
  { value: 'Ecuador', label: localizedCountries[lang].ecuador, isoCode: 'ec' },
  { value: 'Egypt', label: localizedCountries[lang].egypt, isoCode: 'eg' },
  //{ value: 'El Salvador', label: localizedCountries[lang].El Salvador },
  //{ value: 'Equatorial Guinea', label: localizedCountries[lang].Equatorial Guinea },
  //{ value: 'Eritrea', label: localizedCountries[lang].Eritrea },
  { value: 'Estonia', label: localizedCountries[lang].estonia, isoCode: 'ee' },
  //{ value: 'Ethiopia', label: localizedCountries[lang].Ethiopia },
  //{ value: 'Fiji', label: localizedCountries[lang].Fiji },
  { value: 'Finland', label: localizedCountries[lang].finland, isoCode: 'fi' },
  { value: 'France', label: localizedCountries[lang].france, isoCode: 'fr' },
  //{ value: 'Gabon', label: localizedCountries[lang].Gabon },
  //{ value: 'Gambia', label: localizedCountries[lang].Gambia },
  //{ value: 'Georgia', label: localizedCountries[lang].Georgia },
  { value: 'Ghana', label: localizedCountries[lang].ghana, isoCode: 'gh'  },
  { value: 'Greece', label: localizedCountries[lang].greece, isoCode: 'ge' },
  //{ value: 'Grenada', label: localizedCountries[lang].Grenada },
  { value: 'Guatemala', label: localizedCountries[lang].guatemala, isoCode: 'gt' },
  //{ value: 'Guinea', label: localizedCountries[lang].Guinea },
  //{ value: 'Guinea-Bissau', label: localizedCountries[lang].Guinea-Bissau },
  //{ value: 'Guyana', label: localizedCountries[lang].Guyana },
  //{ value: 'Haiti', label: localizedCountries[lang].Haiti },
  { value: 'Honduras', label: localizedCountries[lang].honduras, isoCode: 'hn' },
  { value: 'Hong Kong', label: localizedCountries[lang].hongkong, isoCode: 'hk' },
  { value: 'Hungary', label: localizedCountries[lang].hungary, isoCode: 'hu' },
  { value: 'Iceland', label: localizedCountries[lang].iceland, isoCode: 'is' },
  { value: 'India', label: localizedCountries[lang].india, isoCode: 'in' },
  { value: 'Indonesia', label: localizedCountries[lang].indonesia, isoCode: 'id' },
  { value: 'Iran', label: localizedCountries[lang].iran, isoCode: 'ir' },
  { value: 'Iraq', label: localizedCountries[lang].iraq, isoCode: 'iq' },
  { value: 'Ireland', label: localizedCountries[lang].ireland, isoCode: 'ie' },
  { value: 'Israel', label: localizedCountries[lang].israel, isoCode: 'il' },
  { value: 'Jamaica', label: localizedCountries[lang].jamaica, isoCode: 'jm' },
  { value: 'Japan', label: localizedCountries[lang].japan, isoCode: 'jp' },
  //{ value: 'Jordan', label: localizedCountries[lang].Jordan },
  //{ value: 'Kazakhstan', label: localizedCountries[lang].Kazakhstan },
  { value: 'Kenya', label: localizedCountries[lang].kenya, isoCode: 'ke' },
  //{ value: 'Kiribati', label: localizedCountries[lang].Kiribati },
  //{ value: 'Kosovo', label: localizedCountries[lang].Kosovo },
  { value: 'Kuwait', label: localizedCountries[lang].kuwait, isoCode: 'kw' },
  //{ value: 'Kyrgyzstan', label: localizedCountries[lang].Kyrgyzstan },
  //{ value: 'Laos', label: localizedCountries[lang].Laos },
  { value: 'Latvia', label: localizedCountries[lang].latvia, isoCode: 'lv' },
  //{ value: 'Lebanon', label: localizedCountries[lang].Lebanon },
  { value: 'Lesotho', label: localizedCountries[lang].lesotho, isoCode: 'ls' },
  //{ value: 'Liberia', label: localizedCountries[lang].Liberia },
  { value: 'Libya', label: localizedCountries[lang].libya, isoCode: 'ly' },
  //{ value: 'Liechtenstein', label: localizedCountries[lang].Liechtenstein },
  { value: 'Lithuania', label: localizedCountries[lang].lithuania, isoCode: 'lt' },
  { value: 'Luxembourg', label: localizedCountries[lang].luxembourg, isoCode: 'lu' },
  //{ value: 'Macau', label: localizedCountries[lang].Macau },
  { value: 'Macedonia', label: localizedCountries[lang].macedonia, isoCode: 'mk' },
  //{ value: 'Madagascar', label: localizedCountries[lang].Madagascar },
  //{ value: 'Malawi', label: localizedCountries[lang].Malawi },
  { value: 'Malaysia', label: localizedCountries[lang].malaysia, isoCode: 'my' },
  //{ value: 'Maldives', label: localizedCountries[lang].Maldives },
  //{ value: 'Mali', label: localizedCountries[lang].Mali },
  //{ value: 'Malta', label: localizedCountries[lang].Malta },
  //{ value: 'Marshall Islands', label: localizedCountries[lang].Marshall Islands },
  //{ value: 'Mauritania', label: localizedCountries[lang].Mauritania },
  //{ value: 'Mauritius', label: localizedCountries[lang].Mauritius },
  { value: 'Mexico', label: localizedCountries[lang].mexico, isoCode: 'mx' },
  //{ value: 'Micronesia', label: localizedCountries[lang].Micronesia },
  //{ value: 'Moldova', label: localizedCountries[lang].Moldova },
  //{ value: 'Monaco', label: localizedCountries[lang].Monaco },
  //{ value: 'Mongolia', label: localizedCountries[lang].Mongolia },
  //{ value: 'Montenegro', label: localizedCountries[lang].Montenegro },
  { value: 'Morocco', label: localizedCountries[lang].morocco, isoCode: 'ma' },
  //{ value: 'Mozambique', label: localizedCountries[lang].Mozambique },
  { value: 'Namibia', label: localizedCountries[lang].namibia, isoCode: 'na' },
  //{ value: 'Nauru', label: localizedCountries[lang].Nauru },
  //{ value: 'Nepal', label: localizedCountries[lang].Nepal },
  { value: 'Netherlands', label: localizedCountries[lang].netherlands, isoCode: 'nl' },
  { value: 'New Zealand', label: localizedCountries[lang].newZealand, isoCode: 'nz' },
  { value: 'Nicaragua', label: localizedCountries[lang].nicaragua, isoCode: 'ni' },
  //{ value: 'Niger', label: localizedCountries[lang].Niger },
  { value: 'Nigeria', label: localizedCountries[lang].nigeria, isoCode: 'ng' },
  //{ value: 'North Korea', label: localizedCountries[lang].North Korea },
  { value: 'Norway', label: localizedCountries[lang].norway, isoCode: 'no' },
  { value: 'Oman', label: localizedCountries[lang].oman, isoCode: 'om' },
  //{ value: 'Pakistan', label: localizedCountries[lang].Pakistan },
  //{ value: 'Palau', label: localizedCountries[lang].Palau },
  //{ value: 'Palestinian Territories', label: localizedCountries[lang].Palestinian Territories },
  { value: 'Panama', label: localizedCountries[lang].panama, isoCode: 'pa' },
  //{ value: 'Papua New Guinea', label: localizedCountries[lang].Papua New Guinea },
  { value: 'Paraguay', label: localizedCountries[lang].paraguay, isoCode: 'py' },
  { value: 'Peru', label: localizedCountries[lang].peru, isoCode: 'pe' },
  { value: 'Philippines', label: localizedCountries[lang].philippines, isoCode: 'ph' },
  { value: 'Poland', label: localizedCountries[lang].poland, isoCode: 'pl' },
  { value: 'Portugal', label: localizedCountries[lang].portugal, isoCode: 'pt' },
  { value: 'Qatar', label: localizedCountries[lang].qatar, isoCode: 'qa' },
  { value: 'Romania', label: localizedCountries[lang].romania, isoCode: 'ro' },
  { value: 'Russia', label: localizedCountries[lang].russia, isoCode: 'ru' },
  //{ value: 'Rwanda', label: localizedCountries[lang].Rwanda },
  //{ value: 'Saint Kitts and Nevis', label: localizedCountries[lang].Saint Kitts and Nevis },
  //{ value: 'Saint Lucia', label: localizedCountries[lang].Saint Lucia },
  //{ value: 'Saint Vincent and the Grenadines', label: localizedCountries[lang].Saint Vincent and the Grenadines },
  //{ value: 'Samoa', label: localizedCountries[lang].Samoa },
  //{ value: 'San Marino', label: localizedCountries[lang].San Marino },
  //{ value: 'Sao Tome and Principe', label: localizedCountries[lang].Sao Tome and Principe },
  { value: 'Saudi Arabia', label: localizedCountries[lang].saudiArabia, isoCode: 'sa' },
  //{ value: 'Senegal', label: localizedCountries[lang].Senegal },
  { value: 'Serbia', label: localizedCountries[lang].serbia, isoCode: 'rs' },
  //{ value: 'Seychelles', label: localizedCountries[lang].Seychelles },
  //{ value: 'Sierra Leone', label: localizedCountries[lang].Sierra Leone },
  { value: 'Singapore', label: localizedCountries[lang].singapore, isoCode: 'sg' },
  //{ value: 'Sint Maarten', label: localizedCountries[lang].Sint Maarten },
  { value: 'Slovakia', label: localizedCountries[lang].slovakia, isoCode: 'sk' },
  { value: 'Slovenia', label: localizedCountries[lang].slovenia, isoCode: 'si' },
  //{ value: 'Solomon Islands', label: localizedCountries[lang].Solomon Islands },
  //{ value: 'Somalia', label: localizedCountries[lang].Somalia },
  { value: 'South Africa', label: localizedCountries[lang].southAfrica, isoCode: 'za' },
  { value: 'South Korea', label: localizedCountries[lang].southKorea, isoCode: 'kr' },
  //{ value: 'South Sudan', label: localizedCountries[lang].South Sudan },
  //{ value: 'Sri Lanka', label: localizedCountries[lang].Sri Lanka },
  //{ value: 'Sudan', label: localizedCountries[lang].Sudan },
  //{ value: 'Suriname', label: localizedCountries[lang].Suriname },
  { value: 'Swaziland', label: localizedCountries[lang].swaziland, isoCode: 'sz' },
  { value: 'Sweden', label: localizedCountries[lang].sweden, isoCode: 'se' },
  //{ value: 'Syria', label: localizedCountries[lang].Syria },
  { value: 'Taiwan', label: localizedCountries[lang].taiwan, isoCode: 'tw' },
  //{ value: 'Tajikistan', label: localizedCountries[lang].Tajikistan },
  //{ value: 'Tanzania', label: localizedCountries[lang].Tanzania },
  { value: 'Thailand', label: localizedCountries[lang].thailand, isoCode: 'th' },
  //{ value: 'Timor-Leste', label: localizedCountries[lang].Timor-Leste },
  //{ value: 'Togo', label: localizedCountries[lang].Togo },
  //{ value: 'Tonga', label: localizedCountries[lang].Tonga },
  //{ value: 'Trinidad and Tobago', label: localizedCountries[lang].Trinidad and Tobago },
  { value: 'Tunisia', label: localizedCountries[lang].tunisia, isoCode: 'tn' },
  { value: 'Turkey', label: localizedCountries[lang].turkey, isoCode: 'tr' },
  //{ value: 'Turkmenistan', label: localizedCountries[lang].Turkmenistan },
  //{ value: 'Tuvalu', label: localizedCountries[lang].Tuvalu },
  //{ value: 'Uganda', label: localizedCountries[lang].Uganda },
  { value: 'Ukraine', label: localizedCountries[lang].ukraine, isoCode: 'ua' },
  {
    value: 'United Arab Emirates',
    label: localizedCountries[lang].unitedArabEmirates,
    isoCode: 'ae',
  },
  { value: 'Uruguay', label: localizedCountries[lang].uruguay, isoCode: 'uy' },
  //{ value: 'Uzbekistan', label: localizedCountries[lang].Uzbekistan },
  //{ value: 'Vanuatu', label: localizedCountries[lang].Vanuatu },
  //{ value: 'Venezuela', label: localizedCountries[lang].Venezuela },
  { value: 'Vietnam', label: localizedCountries[lang].vietnam, isoCode: 'vn' },
  //{ value: 'Yemen', label: localizedCountries[lang].Yemen },
  //{ value: 'Zambia', label: localizedCountries[lang].Zambia },
  //{ value: 'Zimbabwe', label: 'Zimbabwe' }
]

export default {
  localizations,
  countryOptions,
  genderOptions,
  categoryOptions,
  languageOptions,
}
