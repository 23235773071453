import { format, getTime, formatDistanceToNow, addHours } from 'date-fns'
import { enUS } from 'date-fns/locale';

// Custom locale to modify strings
const customLocale = {
  ...enUS,
  formatDistance: (token, count, options) => {
    let result = enUS.formatDistance(token, count, options);
    // Remove the word "about" from the result
    return result.replace('about ', '');
  },
};

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy'

  return date ? format(new Date(date), fm) : ''
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy, HH:mm'

  return date ? format(new Date(date), fm) : ''
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : ''
}

export function fToNow(date, withoutAbout) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        ...(withoutAbout && { locale: customLocale })
      })
    : ''
}

export function getCurrentDatePlusOneHour(dateParam) {
  const date = new Date(dateParam)
  const datePlusOneHour = addHours(date, 1)
  return datePlusOneHour
}

export const isValidDate = d => {
  return d instanceof Date && !isNaN(d)
}
