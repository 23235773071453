import React, { useEffect, useState, useRef } from "react";
import { Stack, Button, Tooltip, Typography, Box, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { bgGradients } from "../../../components/nav-section/CreatorsSuggestions/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useTranslation from "../../../../localization/useTranslation";
import {
  _CREATOR_BASE_TYPE,
  _LOOKALIKE_TYPE,
} from "../../../../constants/appSettings";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import routes from "../../../../routes";

const StyledButton = styled(Button)(({ isSelected, ownIndex }) => ({
  color: isSelected ? "#FFFFFF" : "#442424",
  svg: {
    fontSize: "20px",
    path: {
      fill: isSelected ? "#FFFFFF" : "#442424",
    },
  },
  background: isSelected ? bgGradients[ownIndex?.toUpperCase()] : "#F6DBDB",
  "&:hover": {
    background: bgGradients[ownIndex?.toUpperCase()],
    color: "#FFFFFF",
    svg: {
      fill: "#FFFFFF",
      filter: "brightness(95%)",
      path: {
        fill: "#FFFFFF",
      },
    },
  },
  width: "fit-content",
  minWidth: "140px !important",
  height: "30px",
  "&:disabled": {
    opacity: 0.5,
    color: "#442424",
    backgroundColor: "#F6DBDB",
    background: "#F6DBDB",
    svg: {
      path: {
        fill: "#442424",
      },
    },
  },
}));

export const LookalikesButton = (props) => {
  const {
    reference,
    formattedString,
    currentPlatform,
    lookalikesData,
    lookalikeTypeData,
    creatorBase,
    onChangeLookalikesData,
    onChangeCreatorsData,
    lookalikeId,
  } = props || {};

  const { labelStrings } = useTranslation();
  const csvLinkRef = useRef();

  const currDate = new Date();
  const formattedDate = format(currDate, "dd-MM-yyyy HH:mm:ss");

  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const [csvData, setCsvData] = useState({
    header: [],
    data: [],
  });

  const onDownloadCsvData = async () => {
    setIsLoadingCSV(true);
    setCsvData({ data: [], header: [] });
    try {
      const queryParam =
        lookalikeTypeData === _LOOKALIKE_TYPE
          ? "lookalikeListId"
          : "creatorBaseId";

      const csvLink = `${routes.collab.api.getCSVData}?${queryParam}=${lookalikeId}`;
      const response = await fetch(csvLink);
      const { data, header } = await response.json();
      if (data?.length || header?.length) {
        setCsvData({ data, header });
      }
    } catch (error) {
      console.error("Error downloading CSV:", error);
    } finally {
      setIsLoadingCSV(false);
    }
  };

  useEffect(() => {
    if (
      csvData.header?.length > 0 &&
      csvLinkRef.current &&
      csvLinkRef.current.link &&
      !isLoadingCSV
    ) {
      setTimeout(() => {
        csvLinkRef.current.link.dataset.interception = "off";
        csvLinkRef.current.link.click();
      });
    }
  }, [csvData.header, isLoadingCSV]);

  return (
    <Stack sx={{ mb: 2 }} spacing={0.5}>
      <Stack direction='row' alignItems='center' spacing={0.5}>
        <Typography
          sx={{
            fontFamily: "Inter",
            color: "#442424",
            fontWeight: 700,
            textTransform: "capitalize",
          }}
        >{`Your ${reference} List: `}</Typography>
        <Typography
          sx={{ fontFamily: "Inter", color: "#442424", fontWeight: 700 }}
        >
          {formattedString}
        </Typography>
      </Stack>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={1}>
          <StyledButton
            size='small'
            ownIndex={currentPlatform}
            disabled={!lookalikesData?.length}
            isSelected={lookalikeTypeData === _LOOKALIKE_TYPE}
            endIcon={
              <Tooltip title={labelStrings.buildLookalike.lookalikeTipText}>
                <InfoOutlinedIcon />
              </Tooltip>
            }
            onClick={onChangeLookalikesData}
          >
            {labelStrings.buildLookalike.lookalikes}
          </StyledButton>
          <StyledButton
            isSelected={lookalikeTypeData === _CREATOR_BASE_TYPE}
            ownIndex={currentPlatform}
            disabled={!creatorBase?.userdata?.length}
            size='small'
            endIcon={
              <Tooltip title={labelStrings.buildLookalike.creatorBaseTipText}>
                <InfoOutlinedIcon />
              </Tooltip>
            }
            onClick={onChangeCreatorsData}
          >
            {labelStrings.buildLookalike.creatorBase}
          </StyledButton>
        </Stack>

        <Box>
          <LoadingButton loading={isLoadingCSV} onClick={onDownloadCsvData}>
            {!isLoadingCSV && (
              <DownloadRoundedIcon
                sx={{
                  fill: !isLoadingCSV ? "#6B4545" : "#637381",
                  opacity: !isLoadingCSV ? 1 : 0.5,
                }}
              />
            )}
          </LoadingButton>

          {(!!csvData?.header?.length || !!csvData?.data?.length) && (
            <CSVLink
              asyncOnClick
              ref={csvLinkRef}
              data={csvData?.data}
              headers={csvData?.header}
              target='_blank'
              filename={`search-results-${formattedDate}.csv`}
            />
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
