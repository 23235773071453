import React from "react";
import PropTypes from "prop-types";
import { Box, MenuItem, Stack, TextField, Typography } from "@mui/material";
import {
  InstagramSwitchIcon,
  TikTokSwitchIcon,
  YouTubeSwitchIcon,
} from "./icons";
import { SOCIAL_PLATFORMS_NAMES } from "../../../../../constants/appSettings";

export const SocialPlatformSwitcher = ({
  currentPlatform = "",
  changeCurrentPlatform,
  changeCreatorsData,
}) => {
  const onChangeSocialTabs = (e) => {
    const value = e.target.value;
    changeCurrentPlatform(value);
    changeCreatorsData([]);
  };

  return (
    <Box>
      <TextField
        size='small'
        fullWidth
        select
        label='Platform'
        value={currentPlatform}
        onChange={onChangeSocialTabs}
        InputProps={{ style: { height: "40px" } }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: 260,
              },
            },
          },
        }}
        sx={{
          "& .MuiOutlinedInput-input": {
            background: "#FDFCFF",
            borderRadius: "8px",
          },
          minWidth: "150px",
          maxWidth: { sm: 240 },
          textTransform: "capitalize",
          "& .MuiSvgIcon-root": {
            color: "#784E4E",
          },
          borderColor: "#BC9797",
          "& .MuiInputLabel-root": {
            color: "#C79C9C",
            "&:focused, &:active": {
              color: "#C79C9C",
            },
          },
          "& .MuiOutlinedInput-root": {
            outline: "unset",
            "& fieldset": {
              borderColor: "#BC9797",
            },
          },
        }}
      >
        <MenuItem
          value={SOCIAL_PLATFORMS_NAMES.instagram.name}
          sx={{
            mx: 1,
            borderRadius: 0.75,
            typography: "body2",
            textTransform: "capitalize",
          }}
        >
          <Stack direction='row' alignItems='center' spacing={1}>
            <InstagramSwitchIcon sx={{ fontSize: 24, fill: "#442424" }} />
            <Typography sx={{ fontFamily: "Inter", color: "#442424" }}>
              Instagram
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          disabled
          value={SOCIAL_PLATFORMS_NAMES.tiktok.name}
          sx={{
            mx: 1,
            borderRadius: 0.75,
            typography: "body2",
            textTransform: "capitalize",
          }}
        >
          <Stack direction='row' alignItems='center' spacing={1}>
            <TikTokSwitchIcon sx={{ fontSize: 24, fill: "#442424" }} />
            <Typography sx={{ fontFamily: "Inter", color: "#442424" }}>
              TikTok
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          disabled
          value={SOCIAL_PLATFORMS_NAMES.youtube.name}
          sx={{
            mx: 1,
            borderRadius: 0.75,
            typography: "body2",
            textTransform: "capitalize",
          }}
        >
          <Stack direction='row' alignItems='center' spacing={1}>
            <YouTubeSwitchIcon sx={{ fontSize: 24, fill: "#442424" }} />
            <Typography sx={{ fontFamily: "Inter", color: "#442424" }}>
              YouTube
            </Typography>
          </Stack>
        </MenuItem>
      </TextField>
    </Box>
  );
};

SocialPlatformSwitcher.propTypes = {
  currentPlatform: PropTypes.string,
  changeCurrentPlatform: PropTypes.func,
  changeCreatorsData: PropTypes.func,
};
