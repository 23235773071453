import React from "react";

export const LookalikeHistoryIcon = ({ width = 20, height = 18, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 18'
      fill='none'
      {...props}
    >
      <path
        fill='url(#a)'
        d='M2.93 3.43A9.977 9.977 0 0 1 10 .5c5.508 0 10 4.492 10 10 0 5.547-4.492 10-10 10-2.148 0-4.102-.664-5.742-1.758-.547-.43-.703-1.21-.313-1.758.39-.586 1.172-.703 1.758-.312C6.914 17.532 8.398 18 10 18c4.14 0 7.5-3.36 7.5-7.5C17.5 6.36 14.14 3 10 3c-2.07 0-3.945.86-5.313 2.227l1.211 1.171C6.484 7.023 6.055 8 5.234 8H.938C.39 8 0 7.61 0 7.062V2.766c0-.82.977-1.25 1.602-.664L2.93 3.43ZM10 5.5h-.04c.548 0 .938.43.938.938v3.71l2.54 2.54c.39.351.39.937 0 1.289a.856.856 0 0 1-1.29 0l-2.812-2.813c-.195-.156-.274-.39-.274-.664V6.437c0-.507.391-.937.938-.937Z'
      />
      <defs>
        <linearGradient
          id='a'
          x1={0}
          x2={20.612}
          y1={14.219}
          y2={13.184}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFB904' />
          <stop offset={0.294} stopColor='#FF6B00' />
          <stop offset={0.566} stopColor='#FF403B' />
          <stop offset={1} stopColor='#FF3B99' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const NavbarLookaLikeIcon = (props) => {
  return props.isActive ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={25}
      height={19}
      viewBox='0 0 25 19'
      fill='currentColor'
      {...props}
    >
      <path
        fill='#fff'
        d='M10 5.008c-.43.703-.82 1.562-1.172 2.539-.351-1.094-.742-2.266-1.25-3.32.078-.196.156-.352.274-.508C8.828 2.117 10.273.75 12.5.75c2.188 0 3.633 1.367 4.61 2.969.937 1.523 1.562 3.515 2.148 5.312l.039.117c.625 1.915 1.172 3.633 1.953 4.883.742 1.211 1.484 1.719 2.5 1.719.664 0 1.25.586 1.25 1.25 0 .703-.586 1.25-1.25 1.25-2.227 0-3.672-1.328-4.648-2.93-.938-1.523-1.563-3.515-2.149-5.312l-.039-.117c-.625-1.914-1.172-3.633-1.953-4.883-.703-1.211-1.484-1.758-2.461-1.758-1.016 0-1.758.547-2.5 1.758Zm-3.828 9.805c-.117.156-.195.351-.313.507-.976 1.602-2.421 2.93-4.609 2.93C.547 18.25 0 17.703 0 17c0-.664.547-1.25 1.25-1.25.977 0 1.758-.508 2.46-1.719.43-.703.821-1.562 1.173-2.539.351 1.094.742 2.266 1.289 3.32Zm8.789-.782c.43-.703.82-1.562 1.172-2.539.351 1.094.781 2.266 1.289 3.32-.117.157-.195.352-.313.508-.976 1.602-2.422 2.93-4.609 2.93-2.227 0-3.672-1.328-4.648-2.93-.938-1.523-1.563-3.515-2.149-5.312l-.039-.117C5.04 7.977 4.492 6.258 3.711 5.008 3.008 3.797 2.227 3.25 1.25 3.25.547 3.25 0 2.703 0 2 0 1.336.547.75 1.25.75c2.188 0 3.633 1.367 4.61 2.969.937 1.523 1.562 3.515 2.148 5.312l.039.117c.625 1.915 1.172 3.633 1.953 4.883.742 1.211 1.484 1.719 2.5 1.719.977 0 1.758-.508 2.46-1.719Zm6.289-9.023c-.43.703-.82 1.562-1.172 2.539-.351-1.094-.742-2.266-1.25-3.32.078-.196.156-.352.274-.508C20.078 2.117 21.523.75 23.75.75 24.414.75 25 1.336 25 2c0 .703-.586 1.25-1.25 1.25-1.016 0-1.758.547-2.5 1.758Z'
      />
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={18}
      viewBox='0 0 24 18'
      fill='currentColor'
      {...props}
    >
      <defs>
        <linearGradient id='hoverGradient' x1='0%' y1='0%' x2='100%' y2='0%'>
          <stop offset='1.15%' stop-color='#FFB904' />
          <stop offset='30.19%' stop-color='#FF6B00' />
          <stop offset='57.11%' stop-color='#FF403B' />
          <stop offset='100%' stop-color='#FF3B99' />
        </linearGradient>
      </defs>
      <path
        fill='url(#hoverGradient)'
        className='hoverGradient'
        d='M10 5.008c-.43.703-.82 1.562-1.172 2.539-.351-1.094-.742-2.266-1.25-3.32.078-.196.156-.352.274-.508C8.828 2.117 10.273.75 12.5.75c2.188 0 3.633 1.367 4.61 2.969.937 1.523 1.562 3.515 2.148 5.312l.039.117c.625 1.915 1.172 3.633 1.953 4.883.742 1.211 1.484 1.719 2.5 1.719.664 0 1.25.586 1.25 1.25 0 .703-.586 1.25-1.25 1.25-2.227 0-3.672-1.328-4.648-2.93-.938-1.523-1.563-3.515-2.149-5.312l-.039-.117c-.625-1.914-1.172-3.633-1.953-4.883-.703-1.211-1.484-1.758-2.461-1.758-1.016 0-1.758.547-2.5 1.758Zm-3.828 9.805c-.117.156-.195.351-.313.507-.976 1.602-2.421 2.93-4.609 2.93C.547 18.25 0 17.703 0 17c0-.664.547-1.25 1.25-1.25.977 0 1.758-.508 2.46-1.719.43-.703.821-1.562 1.173-2.539.351 1.094.742 2.266 1.289 3.32Zm8.789-.782c.43-.703.82-1.562 1.172-2.539.351 1.094.781 2.266 1.289 3.32-.117.157-.195.352-.313.508-.976 1.602-2.422 2.93-4.609 2.93-2.227 0-3.672-1.328-4.648-2.93-.938-1.523-1.563-3.515-2.149-5.312l-.039-.117C5.04 7.977 4.492 6.258 3.711 5.008 3.008 3.797 2.227 3.25 1.25 3.25.547 3.25 0 2.703 0 2 0 1.336.547.75 1.25.75c2.188 0 3.633 1.367 4.61 2.969.937 1.523 1.562 3.515 2.148 5.312l.039.117c.625 1.915 1.172 3.633 1.953 4.883.742 1.211 1.484 1.719 2.5 1.719.977 0 1.758-.508 2.46-1.719Zm6.289-9.023c-.43.703-.82 1.562-1.172 2.539-.351-1.094-.742-2.266-1.25-3.32.078-.196.156-.352.274-.508C20.078 2.117 21.523.75 23.75.75 24.414.75 25 1.336 25 2c0 .703-.586 1.25-1.25 1.25-1.016 0-1.758.547-2.5 1.758Z'
      />
    </svg>
  );
};

export const SuggestionContentIcon = ({
  width = 20,
  height = 18,
  ...props
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 18'
      fill='none'
      {...props}
    >
      <path
        fill='url(#a)'
        d='M0 3.25C0 1.883 1.094.75 2.5.75h15c1.367 0 2.5 1.133 2.5 2.5v12.5c0 1.406-1.133 2.5-2.5 2.5h-15a2.468 2.468 0 0 1-2.5-2.5V3.25Zm12.617 4.18a.885.885 0 0 0-.742-.43c-.313 0-.625.156-.781.43l-3.399 5-1.054-1.328a.95.95 0 0 0-.704-.352.967.967 0 0 0-.742.352l-2.5 3.125c-.234.312-.273.703-.117 1.015.156.313.469.508.86.508h13.124c.313 0 .665-.156.82-.469a.97.97 0 0 0-.077-.976L12.617 7.43ZM4.375 7c.664 0 1.25-.352 1.602-.938.351-.546.351-1.289 0-1.875-.352-.546-.938-.937-1.602-.937-.703 0-1.29.39-1.64.938-.352.585-.352 1.328 0 1.875A1.87 1.87 0 0 0 4.374 7Z'
      />
      <defs>
        <linearGradient
          id='a'
          x1={0}
          x2={20.612}
          y1={13.219}
          y2={12.184}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFB904' />
          <stop offset={0.294} stopColor='#FF6B00' />
          <stop offset={0.566} stopColor='#FF403B' />
          <stop offset={1} stopColor='#FF3B99' />
        </linearGradient>
      </defs>
    </svg>
  );
};
