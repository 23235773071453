import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ModalComponent } from "../../../components/modal/ModalComponent";
import useTranslation from "../../../../localization/useTranslation";
import { ImportFromCollection } from "./ImportFromCollection";
import { LookalikeModalIcon } from "../icons";
import { AddCreators } from "./AddCreators";
import { AddLabel } from "./AddLabel";

const _BRANDS_OR_CREATORS = {
  BRANDS: "brands",
  CREATORS: "creators",
};

export const LookalikeAiModal = (props) => {
  const {
    openModalLookalikeAi,
    onCloseModalLookalikeAi,
    onHandleConfirmLookalike,
    setDataFromLookalike,
    currentQuery,
    openLookalikeModalFromSuggestions,
    tempBrandMentionsData
  } = props || {};

  const { labelStrings } = useTranslation();

  const queryBrandMentionUsername =
    currentQuery &&
    currentQuery["brandMentions"]?.split(",")?.slice(0, 1)?.join();
  const dataFromBrandMentions = openLookalikeModalFromSuggestions || !tempBrandMentionsData?.length ? [] : tempBrandMentionsData?.filter(
    (el) => el.username === queryBrandMentionUsername,
  );

  const [currentStep, setCurrentStep] = useState(0);
  const [currentType, setCurrentType] = useState(_BRANDS_OR_CREATORS.BRANDS);
  const [creators, setCreators] = useState([]);

  const [isEnabledImportFromCollection, setIsEnabledImportFromCollection] =
    useState(false);

  const onConfirmFirstStep = ({ platform, userList }) => {
    setDataFromLookalike({ userList, platform });
    setCurrentStep(1);
  };

  const onConfirmLastStep = ({ label }) => {
    onHandleConfirmLookalike({ label });
    onClose();
  };

  const onClose = () => {
    onCloseModalLookalikeAi();
    setIsEnabledImportFromCollection(false);
    setCurrentStep(0);
    setDataFromLookalike({
      userList: [],
      platform: "",
    });
    setCreators([]);
    setCurrentType(_BRANDS_OR_CREATORS.BRANDS);
  };

  useEffect(() => {
    setCurrentStep(0);
    setDataFromLookalike({
      userList: [],
      platform: "",
    });
    setCreators([]);
    setIsEnabledImportFromCollection(false);
  }, [openModalLookalikeAi]);

  useEffect(() => {
    if (!creators?.length && dataFromBrandMentions?.length) {
      setCreators(dataFromBrandMentions);
    }
  }, [openModalLookalikeAi, dataFromBrandMentions?.length, creators?.length]);

  return (
    <>
      <ModalComponent
        isOpenModal={openModalLookalikeAi}
        closeModal={onClose}
        title={labelStrings?.buildLookalike?.titleModal}
        smallPadding
        contentWidth='550px'
        contentHeight='auto'
        isCenterTitle
        withoutScrollWrapper
        customIcon={
          <Stack direction='row' justifyContent='center' sx={{ pb: 1, pt: 2 }}>
            <LookalikeModalIcon height={41} />
          </Stack>
        }
        isGradientTitle
      >
        <Stack spacing={1}>
          {!isEnabledImportFromCollection && (
            <Stack sx={{ mt: "-14px !important" }}>
              {currentStep === 0 ? (
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    fontSize: "15px",
                    textAlign: "center",
                    fontFamily: "Inter",
                    color: "#442424",
                  }}
                >
                  {labelStrings?.buildLookalike?.subtitleModal}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    fontSize: "15px",
                    textAlign: "center",
                    fontFamily: "Inter",
                    color: "#442424",
                  }}
                >
                  {labelStrings?.buildLookalike?.pleaseAddALabel}
                </Typography>
              )}
            </Stack>
          )}

          <Stack
            direction='row'
            justifyContent='center'
            sx={{ pb: 0.5, pt: 3 }}
          >
            {isEnabledImportFromCollection && currentStep === 0 ? (
              <ImportFromCollection
                setCreators={setCreators}
                setIsEnabledImportFromCollection={
                  setIsEnabledImportFromCollection
                }
              />
            ) : (
              <>
                {currentStep === 0 ? (
                  <AddCreators
                    onConfirmFirstStep={onConfirmFirstStep}
                    openModalLookalikeAi={openModalLookalikeAi}
                    setIsEnabledImportFromCollection={
                      setIsEnabledImportFromCollection
                    }
                    setCurrentType={setCurrentType}
                    currentType={currentType}
                    creators={creators}
                    setCreators={setCreators}
                  />
                ) : (
                  <AddLabel onConfirmLastStep={onConfirmLastStep} />
                )}
              </>
            )}
          </Stack>
        </Stack>
      </ModalComponent>
    </>
  );
};
