import { createGlobalStyle } from 'styled-components'
import {
  SOCIAL_PLATFORMS_NAMES,
  USER_INDEXES,
  NAVBAR_FOOTER_SUM_HEIGHT,
} from '../constants/appSettings'

export const themeCommonStyles = {
  primaryColor: '#FF5500',
  primaryColorLighter: '#FF726F90',
  primaryColorMidLight: '#FFDBD6',
  primaryMidOpacity: '#FF726FCC',
  primaryColorVeryLight: '#fef2f2',
  textColor: '#272727',
  color: {
    grey: '#888888',
    black: '#000000',
    lightBlack: '#3E4860',
    white: '#FFFFFF',
    white50: 'rgba(255,255,255,0.5)',
    white70: 'rgba(255,255,255,0.7)',
    midGrey: '#e0e0e0',
    lightGrey: '#f5f5f5',
    darkGrey: '#403d3d',
    yellow: '#faca55',
    softYellow: '#ffc82e',
    lightPink: '#FFF1EF',
    milk: '#fef5f2',
    red: '#ff0000',
    pink: '#FFD2CC',
    provincialPink: '#FDEFED',
    fairPink: '#FFEFEB',
    mineShaft: '#393939',
    mineShaft50: '#39393980',
    mineShaft20: '#39393933',
    kabul: '#60403F',
    congoBrown: '#673C3B',
    serenade: '#FFF8EC',
    emperor: '#555555',
    clamShellText: '#CEADAC',
    sauvignon: '#FFF6F4',
    green: '#67B85A',
  },

  // new design colors, gradients
  colors: {
    yellow: '#FFBC00',
    salmon: '#FF5D5A',
    white: '#FFFFFF',
    offWhite: '#FFF9F9',
    ultraLightBrown: '#FCF3F3',
    lightBrown: '#F4E8E8',
    mediumBrown: '#CBB3B3',
    brown: '#442424',
    darkBrown: '#784E4E',
    lightMauve: '#EED9D9',
    mauve: '#BC9797',
    green: '#6BB65A',
    aqua: '#02BDC9',
    aquaLight: '#00F2EA',
    aquaUltraLight: '#C6F3F2',
    aquaDark: '#103C3A',
    red: '#EA3323',
    brownGrey: '#D0BDBD',
    lightRed: '#F9E0E0'
  },
  gradients: {
    main: 'linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)',
    mainDeg: (deg = 0) => `linear-gradient(${deg}deg, #FFBC00 0.35%, #FF5D5A 100%)`,
    darkOrange: 'linear-gradient(310.06deg, rgba(179, 77, 77, 0.95) 13.13%, #E3702F 85.43%)',
    mainWithOpacity: (opacity = 0.9) =>
      `linear-gradient(90deg, rgba(255, 188, 0, ${opacity}) 0.35%, rgba(255, 93, 90, ${opacity}) 100%)`,
    mainTiktok: 'linear-gradient(90deg, #00F2EA 0.35%, #02BDC9 100%)',
    mainTiktokDeg: (deg = 0) => `linear-gradient(${deg}deg, #00F2EA 0.35%, #02BDC9 100%)`,
    blueSalmon: 'linear-gradient(90deg, #00D6CF 0%, #FF5D5A 100%)',
    mainYoutube: 'linear-gradient(to right, #EA3323, #bd1a2e)',
    mainYoutubeOpacity: 'linear-gradient(to right, rgba(234, 51, 35, 0.8), rgba(147, 41, 30, 0.8) 90%)'
  },
  getTextGradientStyles: ({
    gradient = 'linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)',
    platform = null,
  } = {}) => {
    let textGradient = gradient

    switch (platform) {
      case USER_INDEXES.instagram:
        textGradient = 'linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)'
        break
      case USER_INDEXES.tiktok:
        textGradient = 'linear-gradient(90deg, #03D2D8 0.35%, #0094B2 100%)'
        break
      case USER_INDEXES.youtube:
        textGradient = 'linear-gradient(90deg, #FF2714 0.35%, #CB0F00 100%)'
        break
      default:
        break
    }

    return `
      display: block;
      background: ${textGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `
  },
  getGradientsForPlatform: platform => {
    switch (platform) {
      case USER_INDEXES.instagram:
        return {
          main: 'linear-gradient(90deg, #FFBC00 0.35%, #FF5D5A 100%)',
          mainHover: 'linear-gradient(310.06deg, rgba(179, 77, 77, 0.95) 13.13%, #E3702F 85.43%)',
          mainWithOpacity: (opacity = 0.9) =>
            `linear-gradient(90deg, rgba(255, 188, 0, ${opacity}) 0.35%, rgba(255, 93, 90, ${opacity}) 100%)`,
        }
      case USER_INDEXES.tiktok:
        return {
          main: 'linear-gradient(90deg, #00F2EA 0.35%, #02BDC9 100%)',
          mainHover: 'linear-gradient(90deg, #00D6CF 0%, #FF5D5A 100%)',
          mainWithOpacity: (opacity = 0.9) =>
            `linear-gradient(90deg, rgba(0, 242, 234, ${opacity}) 0.35%, rgba(2, 189, 201, ${opacity}) 100%)`,
        }
      case USER_INDEXES.youtube:
        return {
          main: 'linear-gradient(to right, #EA3323, #bd1a2e)',
          mainHover: 'linear-gradient(to right, rgba(234, 51, 35, 0.8), rgba(147, 41, 30, 0.8) 90%)',
          mainWithOpacity: (opacity = 0.9) => `rgba(234, 51, 35, ${opacity})`
        }
      default:
        return {}
    }
  },
  getColorPaletteForPlatform: platform => {
    switch (platform) {
      case USER_INDEXES.instagram:
        return {
          main: '#FF5C00',
          secondary: '#FFBC00',
          disabled: '#CBB3B3',
          dark: '#784E4E',
        }
      case USER_INDEXES.tiktok:
        return {
          main: '#15D9E8',
          secondary: '#02BDC9',
          disabled: '#C6F3F2',
          dark: '#103C3A',
        }
      case USER_INDEXES.youtube:
        return {
          main: '#EA3323',
          secondary: '#FFBC00',
          disabled: '#CBB3B3',
          dark: '#784E4E',
        }
      default:
        return {}
    }
  },
  // gradient colors are passed separataly for icons
  getMainGradientColorsForPlatform: platform => {
    switch (platform) {
      case USER_INDEXES.instagram:
        return ['#FFBC00', '#FF5D5A']
      case USER_INDEXES.tiktok:
        return ['#00F2EA', '#02BDC9']
      case USER_INDEXES.youtube:
        return ['#FFBC00', '#FF5D5A']
      default:
        return ['#000000', '#000000']
    }
  },
  scrollbarStyles: `
    &::-webkit-scrollbar {
      width: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background-color: #EED9D9;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      border: 4px solid #BC9797;
      background-clip: padding-box;
    }
  `,

  scoreRankColors: [
    '#FF5D5A',
    '#FF5D5A',
    '#FF8733',
    '#FFA417',
    '#FFA417',
    '#FCBD03',
    '#CEBB1E',
    '#CEBB1E',
    '#9DBA3B',
    '#6BB65A',
    '#6BB65A',
  ],

  fontFamily: "'Roboto', sans-serif",
  fontFamilyPoppins: "'Poppins', sans-serif",
  fontFamilyInter: "'Inter', sans-serif",

  flex: {
    centerAll: `
        display: flex;
        justify-content: center;
        align-items: center;
      `,
  },
  disabled: `
    pointer-events: none;
    opacity: 0.4;
  `,
  disableBlock: `
    position: relative;
    opacity: 0.5;
    cursor: pointer;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  `,

  buttonGradientBakground:
    'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
  themeGradientBackground:
    'transparent radial-gradient(circle at 55% 191%, #ff726f, #ff726f 30%, #ffca55 100%) 0% 0% no-repeat padding-box',
  popupGradientBackground: `transparent radial-gradient(circle at 55% 191%, #ffca55, #ffca55 30%, #ff726f 100%) 0% 0% no-repeat padding-box`,
  yellowGradient: 'linear-gradient(33deg, #FFCD40 0%, #FFBC00 100%) 0% 0%',
  navbarGradientBackground:
    'transparent linear-gradient(271deg,#FF726F 41%,#FFCA55 106%) 0% 0% no-repeat padding-box',
    newNavbarGradientBackground: `radial-gradient(70.97% 8446.28% at 70.97% 50%, #FF9800 0%, #FF5F5D 100%)`,
  scrollStyle: `
    &::-webkit-scrollbar {
      width: 3px;
      background-color: #e0e0e0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888888;
    }
  `,

  multiDirectionScrollStyle: `
    &::-webkit-scrollbar {
      height: 8px;
      width: 4px;
      background-color: #e0e0e0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888888;
    }
  `,

  withScroll: `
    overflow-x: hidden;
    overflow-y: auto;
    height: -webkit-calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px);
    height: -moz-calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px);
    height: calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px);
    &::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #784E4E;
      border-radius:16px;
      border: 2px solid #fff;
    }
    &::-webkit-scrollbar-thumb:hover {
      border: 0px solid #f4f4f4;
    }
    // THIS is a very dirty temp solution until mobile designs don't exist
    @media (max-width: 1100px) {
      min-height: 95vh;
    }
    @media (max-width: 900px) {
      min-height: 118vh;
    }
    @media (max-width: 800px) {
      min-height: 130vh;
    }
    @media (max-width: 500px) {
      min-height: 255vh;
    }
    @media (max-width: 400px) {
      min-height: 290vh;
    }
    @media (max-width: 350px) {
      min-height: 330vh;
    }
  `,

  pickColorDependOnPlatform: function(userPlatform) {
    const colors = {
      [USER_INDEXES.instagram]: {
        primary: '#FF5C00',
        secondary: '#FFF2F2',
        light: '#FF726F90',
        lightMidOpacity: '#FF726F80',
        veryLight: '#fef2f2',
        profileScoreBG: '#faca55',
        tipBG: '#FFD2CC',
        mediaItemHoverBG: '#ff726fb3',
        comparedBG: '#FFDED6',
        comparedHoveredBG: '#e0bdb5',
        searchSidebarBG: '#FFE6E3',
        notActivePlatformSwitchBtn: '#D09E9D',
        searchbarSubHeaderBG:
          'transparent linear-gradient(90deg, #FF726F 0%, #FF9166 100%) 0% 0% no-repeat padding-box;',
      },
      [USER_INDEXES.tiktok]: {
        primary: '#00C8CE',
        secondary: '#4ad1db40',
        light: 'rgba(74, 209, 219, 0.8)',
        lightMidOpacity: 'rgba(74, 209, 219, 0.5)',
        veryLight: '#D5FCFF',
        profileScoreBG: '#03EDFF',
        tipBG: '#03EDFF',
        mediaItemHoverBG: 'rgba(74, 209, 219, 0.5)',
        comparedBG: '#4ad1db75',
        comparedHoveredBG: '#3daab275',
        searchSidebarBG: '#DDF7F8',
        notActivePlatformSwitchBtn: '#97C6C9',
        searchbarSubHeaderBG:
          'transparent linear-gradient(90deg, #69C9D0 0%, #7CD1D7 39%, #99DDE2 100%) 0% 0% no-repeat padding-box;',
      },
      [USER_INDEXES.youtube]: {
        primary: '#FF2714',
        secondary: '#FFF2F2',
        light: '#FF726F90',
        lightMidOpacity: '#FF726F80',
        veryLight: '#fef2f2',
        profileScoreBG: '#faca55',
        tipBG: '#FFD2CC',
        mediaItemHoverBG: '#ff726fb3',
        comparedBG: '#FFDED6',
        comparedHoveredBG: '#e0bdb5',
        searchSidebarBG: '#FFE6E3',
        notActivePlatformSwitchBtn: '#D09E9D',
        searchbarSubHeaderBG: '#EA3323',
      },
    }
    return colors[userPlatform] || colors.instagram
  },

  rankColors: [
    '#FE4F60',
    '#FE765A',
    '#FE8B57',
    '#FEA454',
    '#FEBB51',
    '#EACC59',
    '#D3D565',
    '#B5E273',
    '#99ED81',
    '#6FFF96',
  ],

  withBubbledCouter: (props, extraStyles = '') => `
    &::before {
      ${props.theme.flex.centerAll}
      content: '${props.count}';
      font-weight: 600;
      position: absolute;
      height: 18px;
      width: 18px;
      background: ${props.theme.pickColorDependOnPlatform(props.userindex).primary};
      border-radius: 50%;
      right: 0;
      color: ${props.theme.color.white};
      font-size: 10px;
      top: -6px;
      text-align: center;
      ${extraStyles}
    }
  `,
}

// Add diff styles depending on social platform
const instaTheme = {
  ...themeCommonStyles,
  platformColor: '#FF5500',
  platformColorLight: '#F3DBDB',
  secondaryColor: '#FFF2F2',
  platformSoftColor: '#FFD2CC',
}

const tiktokTheme = {
  ...themeCommonStyles,
  platformColor: '#00C8CE',
  platformColorLight: 'rgba(74, 209, 219, 0.8)',
  secondaryColor: '#4ad1db40',
  platformSoftColor: '#03EDFF',
}

const youtubeTheme = {
  ...themeCommonStyles,
  platformColor: '#FF2714',
  platformColorLight: '#F3DBDB',
  secondaryColor: '#FFF2F2',
  platformSoftColor: 'FFD2CC',
}

export const infludataThemes = {
  [SOCIAL_PLATFORMS_NAMES.instagram.name]: instaTheme,
  [SOCIAL_PLATFORMS_NAMES.tiktok.name]: tiktokTheme,
  [SOCIAL_PLATFORMS_NAMES.youtube.name]: youtubeTheme,
}

export const GlobalDraggingStyles = createGlobalStyle`
  * {
    user-select: none;
    cursor: grabbing !important;
    cursor: -moz-grabbing !important;
    cursor: -webkit-grabbing !important;
    tr {
      pointer-events: none;
    }
  }
`

export const GlobalInfludataStyles = createGlobalStyle`
  html {
    min-width: 100vw;
    min-height: 100vh;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 1px;
      height:1px;
      background-color: #e0e0e0; 
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888888;
    }
  }

  body {
    font-family: ${themeCommonStyles.fontFamilyInter} !important;
    min-width: 1135px;
    overflow: hidden;
  }

  a {
    color: inherit;
  }

  @keyframes modalTransition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


  .collection-sidebar-menu {
    z-index: 1000;
  }
  .collection-sidebar-menu-content,
  .email-popup-content {
    background: #ffa093 !important;
    box-shadow: 0px 0px 6px #ffa093 !important;
    border: none !important;
    border-radius: 12px;
    padding: 12px !important;
  
    .collection-sidebar-menu-arrow,
    .email-popup-arrow {
      background: #ffa093 !important;
      box-shadow: 0px 0px 6px #ffa093 !important;
      border: none !important;
    }
  }
  
  .Toastify__toast.Toastify__toast--default {
    background: #ffa093 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #ffd2cc;
    border-radius: 12px;
    color: #272727;
    padding: 26px;
  
    .Toastify__close-button--default {
      height: 60px;
      padding-left: 12px;
      color: #272727;
    }
  
    .Toastify__toast-body {
      font-size: 15px;
      line-height: 20px;
      font-family: 'Roboto', sans-serif;
    }
  }

  #fc_frame {
    bottom: 70px !important;
  }
`
