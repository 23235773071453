import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useTranslation from '../../../localization/useTranslation'
import { USER_INDEXES } from '../../../constants/appSettings'
import { notifyWhenAudienceAnalysisAvailable } from '../../../redux/ducks/profilesCommonActions'
import { isUserAuthenticatedSelector } from '../../../redux/selectors'
import { withReduxActions } from '../../../hocs/withRedux'

import { themeCommonStyles } from '../../../themes/infludataThemes'
import { GradientButton } from '../../common/buttons/styledButtons'
import { UnlockedIcon } from '../../common/icons/icons'
import {
  AudienceReportMessageContainer,
  LockIconWrapper,
  AudienceReportMessageTitle,
  AudienceReportMessageText,
  AudienceReportNotifyText,
} from './styles'

const AudienceReportUnavailable = ({
  userName,
  userId,
  platform,
  notifyWhenAudienceAnalysisAvailable,
  isReportImpossible,
}) => {
  const { labelStrings } = useTranslation()

  const [isNotified, setIsNotified] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)

  const onNotifyButtonClick = async () => {
    setIsLoading(true)

    try {
      await notifyWhenAudienceAnalysisAvailable({
        influencerName: userName,
        influencerId: userId,
        platform: platform,
        notify: true
      })
      setIsNotified(true)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const [color1, color2] = themeCommonStyles.getMainGradientColorsForPlatform(platform)

  return (
    <AudienceReportMessageContainer>
      {!isReportImpossible && (
        <LockIconWrapper>
          <UnlockedIcon gradientColor1={color1} gradientColor2={color2} isGradient />
        </LockIconWrapper>
      )}

      <AudienceReportMessageTitle>
        {isReportImpossible
          ? labelStrings.reportImpossibleTitle
          : labelStrings.analysisIsAlreadyUnlocked}
      </AudienceReportMessageTitle>

      <AudienceReportMessageText>
        {isReportImpossible ? (
          <>
            {labelStrings.reportImpossibleContentFirst}
            <br />
            {labelStrings.reportImpossibleContentLast}
          </>
        ) : (
          labelStrings.audienceReportWillBeAvailableIn30Min
        )}
      </AudienceReportMessageText>

      {isUserAuthenticated &&
        !isReportImpossible &&
        (isNotified ? (
          <AudienceReportNotifyText>{labelStrings.weWillNotifyYou}.</AudienceReportNotifyText>
        ) : (
          <GradientButton
            platform={platform}
            onClick={onNotifyButtonClick}
            isDisabled={isLoading || !isUserAuthenticated}
          >
            {labelStrings.notifyMe}
          </GradientButton>
        ))}
    </AudienceReportMessageContainer>
  )
}

AudienceReportUnavailable.propTypes = {
  userName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  isReportImpossible: PropTypes.bool.isRequired,
  platform: PropTypes.oneOf([USER_INDEXES.tiktok, USER_INDEXES.instagram, USER_INDEXES.youtube])
    .isRequired,
  notifyWhenAudienceAnalysisAvailable: PropTypes.func,
}

export default withReduxActions({ notifyWhenAudienceAnalysisAvailable })(AudienceReportUnavailable)
