import React, { useEffect, useMemo, useState } from "react"
import useTranslation from "../../../../../localization/useTranslation"
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  IconButton,
  Slider,
  Typography,
} from "@mui/material"
import {
  COMMA_SPLITTER,
  UNDERSCORE_SPLITTER,
} from "../../../../../constants/search"
import AddRoundedIcon from "@mui/icons-material/AddRounded"
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded"
import { getUniqueId } from "../utils"
import { infludataThemes } from "../../../../../themes/infludataThemes"

const formControlStyles = {
  m: 1,
  width: 300,
  "& .MuiOutlinedInput-root": {
    outline: "unset",
    "& fieldset": {
      borderColor: "#BC9797",
    },
    "&:hover fieldset": {
      borderColor: "#784E4E",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#784E4E",
    },
  },
}
const inputLabelStyles = {
  color: "#784E4E",
  fontWeight: "bold",
  "&.Mui-focused": {
    color: "#784E4E",
  },
}
const selectLabelStyles = {
  color: "#784E4E",
  fontWeight: 500,
  fontSize: "11px",
  textTransform: "uppercase",
  fontFamily: "Inter",
}

export const AudienceLanguageFilter = ({
  selectedPlatform,
  query,
  handlersMap,
  isModalOpen
}) => {
  const { labelStrings, languageOptions } = useTranslation()

  const audienceLanguages = query.audienceLanguages || ""

  const [open, setOpen] = useState(false);

  const languages = useMemo(() => {
    if (!audienceLanguages) return []
    const _cArr = audienceLanguages.split(COMMA_SPLITTER)
    return _cArr.reduce((_arr, c) => {
      if (c.includes(UNDERSCORE_SPLITTER)) {
        const [language, range] = c.split(UNDERSCORE_SPLITTER)
        _arr.push({ language, range: parseInt(range) })
      }
      return _arr
    }, [])
  }, [audienceLanguages])

  const [selectCount, setSelectCount] = useState([])
  const [localSliderValue, setLocalSliderValue] = useState({})

  const handleChange = (newValue, id) => {
    setLocalSliderValue((prev) => ({
      // @ts-ignore
      ...prev,
      [id]: newValue,
    }))
  }

  const updateLanguage = (languagesForUpdate) => {
    if (!audienceLanguages && !languagesForUpdate.length) return

    const getAudienceLanguages = languagesForUpdate.reduce(
      (str, item) =>
        `${str ? `${str},` : ""}${item.language}${UNDERSCORE_SPLITTER}${item.range}`,
      "",
    )

    handlersMap.audience({
      ...query,
      audienceLanguages: getAudienceLanguages,
    })
  }

  const onChangeQueryValues = (value, language, id) => {
    if (!language) return

    const updated = value
      ? languages.map((lang) =>
          lang.language === language ? { ...lang, range: value } : lang,
        )
      : languages.filter((lang) => lang.language !== language)
    updateLanguage(updated)
  }

  const onChangeAudienceCountries =
    (prevLanguage) => (newLanguageObj, rangeValue) => {
      const newLanguageValue = newLanguageObj?.value || newLanguageObj

      if (languages?.some((lang) => lang.language === newLanguageValue)) return

      if (!newLanguageObj || newLanguageValue === "clear") {
        updateLanguage(
          languages.filter((lang) => lang.language !== prevLanguage),
        )
        return
      }

      if (prevLanguage === newLanguageValue) return

      const updatedLanguages = languages.some(
        (lang) => lang.language === prevLanguage,
      )
        ? languages.map((lang) =>
            lang.language === prevLanguage
              ? { ...lang, language: newLanguageValue }
              : lang,
          )
        : [...languages, { language: newLanguageValue, range: 10 }]
      updateLanguage(updatedLanguages)
    }

  const handleAddComponent = () => {
    if (selectCount.length < 3) {
      const id = getUniqueId()
      setSelectCount((prev) => [...prev, id])
      setLocalSliderValue({ [id]: 0 })
    }
  }

  const handleRemoveComponent = (index) => {
    setSelectCount((prev) => prev.filter((_, idx) => idx !== index))
    const languagesToRemove = languages[index]?.language
    if (languagesToRemove) {
      onChangeAudienceCountries(languagesToRemove)(null)
    }
  }

  useEffect(() => {
    if (!selectCount.length && audienceLanguages) {
      const initialVal = audienceLanguages
        .split(COMMA_SPLITTER)
        .map(() => getUniqueId())
      setSelectCount(initialVal)
      const initialSliderValues = initialVal?.reduce((obj, key) => {
        obj[key] = 0
        return obj
      }, {})
      setLocalSliderValue(initialSliderValues)
    } else if (!selectCount?.length && !audienceLanguages) {
      const initialId = getUniqueId()
      setSelectCount([initialId])
      setLocalSliderValue({ [initialId]: 0 })
    }
  }, [audienceLanguages, selectCount.length])

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isModalOpen) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, 200);

    return () => clearTimeout(timer);
  }, [isModalOpen]);

  return (
    <Stack spacing={3} sx={{ minHeight: "115px" }}>
      {selectCount.map((id, idx) => {
        const isLastOne = idx === selectCount.length - 1
        const selected = languages[idx] || {}
        const currentLanguage =
          languageOptions.find((el) => el.value === selected.language)?.value ||
          ""
        const currentSliderValue =
          localSliderValue[id] === 0 || !localSliderValue[id]
            ? selected?.range
            : localSliderValue[id]
        return (
          <Stack key={`${id}-${currentLanguage}-${selected.range}`} spacing={0}>
            <Stack direction='row' alignItems='center' sx={{ width: '280px' }}>
              <FormControl sx={formControlStyles}>
                <InputLabel sx={inputLabelStyles} size='small'>
                  {labelStrings.selectLanguage}
                </InputLabel>
                <Select
                  open={open}
                  onClose={handleClose}
                  onOpen={() => setOpen(true)}
                  size='small'
                  value={currentLanguage}
                  onChange={(e) =>
                    onChangeAudienceCountries(selected.value)(e.target.value)
                  }
                  input={<OutlinedInput label={labelStrings.selectLanguage} />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                >
                  {languageOptions.map((lang) => (
                    <MenuItem key={lang.value} value={lang.value}>
                      {lang.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                onClick={
                  isLastOne
                    ? handleAddComponent
                    : () => handleRemoveComponent(idx)
                }
                disabled={isLastOne && selectCount.length >= 3}
              >
                {isLastOne ? (
                  <AddRoundedIcon
                    sx={{
                      fill: selectCount.length >= 3 ? "#637381" : "#784E4E",
                    }}
                  />
                ) : (
                  <RemoveRoundedIcon sx={{ fill: "#784E4E" }} />
                )}
              </IconButton>
            </Stack>
            <Stack sx={{ px: 2 }}>
              <Typography sx={selectLabelStyles}>
                {labelStrings.minAudienceUsingLanguage}
              </Typography>
              <Slider
                disabled={!currentLanguage}
                sx={{
                  mt: "-8px",
                  width: "100%",
                  height: "5px",
                  borderRadius: "8px",
                  color: infludataThemes[selectedPlatform]?.platformColor,
                  "& .MuiSlider-valueLabelOpen": {
                    fontWeight: "bold",
                    color: infludataThemes[selectedPlatform]?.platformColor,
                  },
                  "& .MuiSlider-valueLabel": {
                    background: "unset",
                    transformOrigin: "bottom left",
                    transform: "translate(50%, 100%) rotate(45deg) scale(0)",
                    "&::before": { display: "none" },
                    "&.MuiSlider-valueLabelOpen": {
                      transform: "translate(50%, -40%) rotate(-225deg) scale(1)",
                    },
                    "& > *": {
                      transform: "rotate(-135deg)",
                    },
                  }
                }}
                value={currentSliderValue}
                onChange={(e, value) => handleChange(value, id)}
                onChangeCommitted={(e, value) =>
                  onChangeQueryValues(value, currentLanguage, id)
                }
                valueLabelDisplay='on'
                valueLabelFormat={(val) =>
                  val === 0 ? "Off" : `${Math.abs(val)}%`
                }
                min={0}
                max={90}
              />
            </Stack>
          </Stack>
        )
      })}
    </Stack>
  )
}
