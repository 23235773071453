import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createCampaignSchema } from './Form/FormSchema.js'
import { Fields } from './Form/Fields.jsx'
import { Box, Card, Typography } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { currentOpenedCampaignSelector } from '../../../redux/selectors/index.js'
import { editCampaignSettings } from '../../../redux/ducks/campaignsDucks.js'
import useTranslation from '../../../localization/useTranslation.js'
import { ModalDeleteCampaign } from './modals/ModalDeleteCampaign.jsx'
import { ModalArchiveCampaign } from './modals/ModalArchiveCampaign.jsx'
import { useLocalStorage } from '../../../hooks/useLocalStorage.js'
import { ModalComponent } from '../../components/modal/ModalComponent.jsx'
import { AudienceAnalyticsCpmSettings } from '../../../components/influencerProfile/audienceAnalysis/AudienceAnalyticsCpmSettings.jsx'
import { adjustCPMValuesForCampaign } from '../../../redux/ducks/profileSelectedDucks.js'

export const SettingsContainer = () => {
  const dispatch = useDispatch()
  const { labelStrings } = useTranslation()
  const history = useHistory()
  const [currencyFromStorage] = useLocalStorage('currency')

  const { campaignName, campaignId, settings } = useSelector(currentOpenedCampaignSelector, shallowEqual)
  const [openEmvModal, setOpenEmvModal] = useState(false)

  const [showDeleteCampaignModal, setShowDeleteCampaignModal] = useState(false)
  const [showArchiveCampaignModal, setShowArchiveCampaignModal] = useState(false)

  const onShowDeleteCampaignModal = () => setShowDeleteCampaignModal(true)
  const onCloseDeleteCampaignModal = () => setShowDeleteCampaignModal(false)
  const onShowArchiveCampaignModal = () => setShowArchiveCampaignModal(true)
  const onCloseArchiveCampaignModal = () => setShowArchiveCampaignModal(false)

  const onDeleteCampaign = () => {
    dispatch(
      // @ts-ignore
      editCampaignSettings({
        campaignId,
        history,
        setAsDeleted: true,
        withoutFetchSingleCampaign: true,
      })
    )
  }
  const onArchiveCampaign = () => {
    dispatch(
      // @ts-ignore
      editCampaignSettings({
        campaignId,
        history,
        setAsInactive: true,
        withoutFetchSingleCampaign: true,
      })
    )
  }

  const methods = useForm({
    resolver: yupResolver(createCampaignSchema),
    defaultValues: {
      campaignName: '',
      trackReachOnlyManually: '',
      trackSponsoredContent: '',
      trackPricing: '',
      campaignCurrency: '',
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
  } = methods

  useEffect(() => {
    reset({
      ...settings,
      campaignName,
    })
  }, [settings, campaignName])

  const handleClose = () => {
    reset({
      ...settings,
      campaignName,
    })
  }

  const onSubmit = async data => {
    try {
      const { instagram, tiktok, youtube } = data
      // @ts-ignore
      await dispatch(editCampaignSettings(data))
      handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  const handleOpenEmvModal = () => {
    setOpenEmvModal(true)
  }

  const handleCloseEmvModal = () => {
    setOpenEmvModal(false)
  }

  const submitAdjustCPMValues = payload => {
    // @ts-ignore
    dispatch(adjustCPMValuesForCampaign({
      ...payload,
      campaignId
    }))
    handleCloseEmvModal()
  }

  return (
    <>
      <Card sx={{ m: 3, p: 3 }}>
        <Box sx={{ paddingBottom: 2 }}>
          <Typography sx={{ fontSize: '18px', lineHeight: '28px', fontWeight: 700 }}>
            {labelStrings.campaignSettings}
          </Typography>
        </Box>
        <Fields
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          methods={methods}
          isSubmitting={isSubmitting}
          handleClose={handleClose}
          fieldValues={watch}
          onShowDeleteCampaignModal={onShowDeleteCampaignModal}
          onShowArchiveCampaignModal={onShowArchiveCampaignModal}
          handleOpenEmvModal={handleOpenEmvModal}
        />
      </Card>
      <ModalArchiveCampaign
        isOpen={showArchiveCampaignModal}
        onClose={onCloseArchiveCampaignModal}
        campaignName={campaignName}
        onArchiveCampaign={onArchiveCampaign}
      />
      <ModalDeleteCampaign
        isOpen={showDeleteCampaignModal}
        onClose={onCloseDeleteCampaignModal}
        campaignName={campaignName}
        onDeleteCampaign={onDeleteCampaign}
      />
      <ModalComponent
        isOpenModal={openEmvModal}
        closeModal={handleCloseEmvModal}
        title={labelStrings.cpmSettings.titleModal}
        contentWidth="700px"
        contentHeight="600px"
        isCenterTitle
      >
        <AudienceAnalyticsCpmSettings
          onSubmit={submitAdjustCPMValues}
          initialCpmAdjustments={settings?.emvValues?.cpmValues}
          currentCurrency={currencyFromStorage}
        />
      </ModalComponent>
    </>
  )
}
