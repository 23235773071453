import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import { useDispatch, useSelector } from 'react-redux'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { isUserLoggedInSelector } from './redux/selectors'
import AppWithRouting from './AppWithRouting'
import WithScrollToTop from './hocs/WithScrollToTop'
import UserActivityWatcher from './components/UserActivityWatcher'
import GoogleAnalytics from './GoogleAnalytics'
import { ToastContainer } from 'react-toastify'
import ThemeProvider from './new-ui/theme'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'

// scroll bar
import 'simplebar-react/dist/simplebar.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-multi-carousel/lib/styles.css'
import { pullNotifications } from './redux/ducks/startupActions'

const _REFRESH_TIME = 60000 // 1 minutes

const App = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(isUserLoggedInSelector)

  useEffect(() => {
    if(!isLoggedIn) return

    let intervalId = null
    dispatch(pullNotifications())

    intervalId = setInterval(() => {
      dispatch(pullNotifications())
    }, _REFRESH_TIME)

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [isLoggedIn, dispatch])

  return (
    <DndProvider backend={HTML5Backend}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider>
          <Router>
            <LastLocationProvider>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                // eslint-disable-next-line
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
              />
              {isLoggedIn && <UserActivityWatcher />}
              <GoogleAnalytics />
              <WithScrollToTop>
                <Route path={process.env.REACT_APP_PREFIX || '/'} component={AppWithRouting} />
              </WithScrollToTop>
            </LastLocationProvider>
          </Router>
        </ThemeProvider>
      </LocalizationProvider>
    </DndProvider>
  )
}

export default App
