import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import {
  userAudienceTokensSelector,
  userCredsSelector,
  userGrantSelector,
} from "../../../redux/selectors";
import useTranslation from "../../../localization/useTranslation";
import TokensRemainingStat from "./TokensRemainingStat";

const AudienceReportsTokensStat = ({ isTrial = false }) => {
  const { labelStrings } = useTranslation();

  const { audienceTokensUsed, tokensResetDate, audienceTokensTotal } =
    useSelector(userAudienceTokensSelector);
  const {
    search_profile_request_limit_monthly,
    profile_analytics_limit_monthly,
  } = useSelector(userGrantSelector, shallowEqual) || {};
  const { searchResultsAndProfilesUsed } = useSelector(userCredsSelector) || {};
  const { profileAnalyticsRemaining, searchResultsRemaining, nextResetDate } =
    searchResultsAndProfilesUsed || {};

  const TrialComponent = useMemo(
    () => (
      <>
        <div>{labelStrings.audienceTokensTrialLimit}</div>
        <div>{labelStrings.pleaseContactSupport}</div>
      </>
    ),
    [isTrial],
  );

  const audienceTokensAvailable = audienceTokensTotal - audienceTokensUsed;

  const showTrialTip = audienceTokensAvailable <= 0 && isTrial;

  const isUnlimitedAudienceReports =
    search_profile_request_limit_monthly > 0 &&
    profile_analytics_limit_monthly > 0;

  return (
    <TokensRemainingStat
      audienceTokensTotal={audienceTokensTotal}
      searchProfileRequestTokensTotal={search_profile_request_limit_monthly}
      profileAnalyticsTokensTotal={profile_analytics_limit_monthly}

      audienceTokensAvailable={audienceTokensAvailable}
      searchResultsTokensAvailable={searchResultsRemaining}
      profileAnalyticsTokensAvailable={profileAnalyticsRemaining}

      analyticsNextResetDate={nextResetDate || tokensResetDate}
      isUnlimitedAudienceReports={isUnlimitedAudienceReports}
      {...(showTrialTip && { TrialComponent })}
    />
  );
};

AudienceReportsTokensStat.propTypes = {
  isTrial: PropTypes.bool,
};

export default AudienceReportsTokensStat;
