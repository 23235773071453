import React from "react";
import { Avatar, Box, Button, Chip, Stack, Typography } from "@mui/material";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import useTranslation from "../../../../localization/useTranslation";
import AddCreatorsTo from "./add-creator";

const _BRANDS_OR_CREATORS = {
  BRANDS: "brands",
  CREATORS: "creators",
};

const _MAX_LIMIT_ADD_USERS = {
  BRANDS: 2,
  CREATORS: 19,
};

export const activeBtnStyle = {
  borderRadius: "10px",
  fontFamily: "Inter",
  background:
    "linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)",
  fontSize: "16px",
  color: "#fff",
  fontWeight: 600,
};
export const inactiveBtnStyle = {
  borderRadius: "10px",
  fontFamily: "Inter",
  borderWidth: "1.5px",
  fontSize: "16px",
  fontWeight: 600,
  background:
    "linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)",
  webkitBackgroundClip: `text`,
  webkitTextFillColor: `transparent`,
  backgroundClip: "text",
  color: "transparent",
};

export const AddCreators = (props) => {
  const {
    onConfirmFirstStep,
    openModalLookalikeAi,
    setIsEnabledImportFromCollection,
    currentType,
    setCurrentType,
    creators,
    setCreators,
  } = props || {};

  const { labelStrings, currentLang } = useTranslation();

  const creatorIds = creators?.map((creator) => creator._id);

  const isImportCreatorFromCollection =
    currentType === _BRANDS_OR_CREATORS.CREATORS && !creators?.length;

  const isAllowedToAddCreators =
    (currentType === _BRANDS_OR_CREATORS.BRANDS &&
      creators?.length <= _MAX_LIMIT_ADD_USERS.BRANDS) ||
    (currentType === _BRANDS_OR_CREATORS.CREATORS &&
      creators?.length <= _MAX_LIMIT_ADD_USERS.CREATORS);

  const addCreator = (creator) => {
    const updatedValue = [...creators, creator];
    setCreators(updatedValue);
  };

  const removeCreator = (creatorId) => {
    const updatedValue = creators?.filter(
      (creator) => creator._id !== creatorId,
    );
    setCreators(updatedValue);
  };

  const isDisabledContinue = currentType === _BRANDS_OR_CREATORS.CREATORS
  ? !(
      creators?.length <= _MAX_LIMIT_ADD_USERS.CREATORS + 1 &&
      creators?.length >= _MAX_LIMIT_ADD_USERS.BRANDS + 1
    )
  : !creators?.length
  const styledForContinueBtn = !isDisabledContinue ? activeBtnStyle : {}

  return (
    <Stack spacing={1.5} alignItems='center'>
      <Stack sx={{ px: 3, pb: 1 }}>
        <Stack
          spacing={2}
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          <Button
            sx={
              currentType === _BRANDS_OR_CREATORS.CREATORS ? activeBtnStyle : inactiveBtnStyle
            }
            variant={
              currentType === _BRANDS_OR_CREATORS.CREATORS
                ? "contained"
                : "outlined"
            }
            size='medium'
            onClick={() => {
              setCurrentType(_BRANDS_OR_CREATORS.CREATORS);
              setCreators([]);
            }}
          >
            {labelStrings?.brandsTimelineText?.creators}
          </Button>
          <Button
            variant={
              currentType === _BRANDS_OR_CREATORS.BRANDS
                ? "contained"
                : "outlined"
            }
            sx={currentType === _BRANDS_OR_CREATORS.BRANDS ? activeBtnStyle : inactiveBtnStyle}
            size='medium'
            onClick={() => {
              setCurrentType(_BRANDS_OR_CREATORS.BRANDS);
              setCreators([]);
            }}
          >
            Brand Collaborations
          </Button>
        </Stack>
        <Typography
          textAlign='center'
          sx={{
            maxWidth: "391px",
            color: "#C79C9C",
            fontSize: `13px`,
            fontFamily: `Inter`,
            fontWeight: 400,
            lineHeight: "14.3px",
            mt: 1,
          }}
        >
          {currentType === _BRANDS_OR_CREATORS.BRANDS
            ? labelStrings?.buildLookalike?.brandsHelperText
            : labelStrings?.buildLookalike?.creatorsHelperText}
        </Typography>
      </Stack>

      <Box sx={{ width: "464px", mt: "24px !important" }}>
        <AddCreatorsTo
          isAllowedToAddCreators={true}
          creatorIdFromState={creatorIds}
          setCreatorIdFromState={addCreator}
          isSetCreatorId={false}
          isDisabledInput={!isAllowedToAddCreators}
          creatorType={currentType}
          changeCreatorsData={setCreators}
          openModalLookalikeAi={openModalLookalikeAi}
        />
      </Box>

      <Scrollbar
        sx={{
          maxHeight: "240px",
          width: "525px",
        }}
      >
        <Stack
          direction='row'
          sx={{ width: "100%", flexWrap: "wrap", gap: 1, px: 3.5 }}
          justifyContent='start'
        >
          {creators?.map((creator) => (
            <Chip
              sx={{
                borderColor: "transparent",
                backgroundColor: "#FFF5F5",
                fontFamily: 'Inter',
                color: '#6B4545',
                py: 3.5,
                px: 1,
                width: "fit-content",
                fontWeight: "bold",
                ".MuiChip-avatar": { width: "36px", height: "36px", mr: 0.5 },
                ".MuiChip-deleteIcon": {
                  fill: '#442424'
                }
              }}
              key={creator._id}
              avatar={
                <Avatar
                  alt={creator?.displayName}
                  src={creator?.profilePicURL}
                />
              }
              label={`@${creator?.username}`}
              variant='outlined'
              onDelete={() => removeCreator(creator._id)}
            />
          ))}
        </Stack>
      </Scrollbar>

      {!isAllowedToAddCreators && (
        <Typography
          sx={{
            color: "#637381",
            lineHeight: 1.5,
            fontSize: `0.75rem`,
            fontFamily: `Public Sans,sans-serif`,
            fontWeight: 400,
            textAlign: `center`,
            marginTop: 2,
            marginRight: `14px`,
            marginBottom: 0,
            marginLeft: `14px`,
            px: currentLang === "de" ? 8 : 2,
          }}
        >
          {labelStrings?.buildLookalike?.addToCreatorsLimitText}
        </Typography>
      )}

      {isImportCreatorFromCollection && (
        <Box>
          <Button
            sx={{ textTransform: "unset", ...inactiveBtnStyle }}
            variant='outlined'
            onClick={() => setIsEnabledImportFromCollection(true)}
          >
            + {labelStrings.importCreatorsFromCollection}
          </Button>
        </Box>
      )}

      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "end", px: 4 }}
      >
        <Button
          sx={{ mt: 1, mb: 2, ...styledForContinueBtn }}
          variant='contained'
          onClick={() =>
            onConfirmFirstStep({
              platform: creators[0]?._index,
              userList: creatorIds,
            })
          }
          disabled={isDisabledContinue}
        >
          {labelStrings?.signupLocalization?.continue}
        </Button>
      </Box>
    </Stack>
  );
};
